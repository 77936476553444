import React from "react";
import { useContext } from "react";
import * as Page from "../../components/Page";
import { Translater } from "../../config";
import * as Contexts from "../../contexts";
import { Wrapper } from "./styled";
import { ProductList, PopularProductList } from "./components";
import {
  updatePopularOrders,
  usePopularProducts,
  useProducts,
} from "./helpers";
import { notification } from "antd";

const LIMIT = 20;

const PopularProducts = () => {
  const {
    popularProducts,
    setPopularProducts,
    oldPopularList,
    setOldPopularList,
  } = usePopularProducts({
    perPage: 50,
    sortBy: "popularOrder",
    sortOrder: "asc",
  });

  const { language } = useContext(Contexts.LanguageContext);

  const { token } = useContext(Contexts.AuthContext);

  const { products, params, setParams, setProducts } = useProducts(token, {
    search: "",
    page: 1,
    perPage: LIMIT,
    sortBy: "order",
    sortOrder: "asc",
  });

  const onSaveClick = async () => {
    const oldProducts = oldPopularList.map((item) => ({
      product: item._id || "",
      popularOrder: 0,
    }));
    const positions = popularProducts.map((item, i) => ({
      product: item._id || "",
      popularOrder: i + 1,
    }));
    try {
      await updatePopularOrders({ positions: oldProducts }, token || "");
      await updatePopularOrders({ positions }, token || "");
      setOldPopularList(popularProducts);
      notification.success({
        message: Translater.SEO.updated[language.slug],
      });
    } catch (error) {
      notification.error({
        message: Translater.SEO.updatedError[language.slug],
      });
    }
  };

  return (
    <Page.Wrapper>
      <Page.Header
        saveButton
        saveButtonTitle={Translater.Buttons.save[language.slug]}
        title={Translater.Sidebar.popularProducts[language.slug]}
        saveButtonHandler={onSaveClick}
      />
      <Wrapper>
        <PopularProductList
          popularProducts={popularProducts}
          setPopularProducts={setPopularProducts}
        />
        <ProductList
          popularProducts={popularProducts}
          setPopularProducts={setPopularProducts}
          params={params}
          setParams={setParams}
          products={products}
          setProducts={setProducts}
        />
      </Wrapper>
    </Page.Wrapper>
  );
};
export default PopularProducts;
