import React, { Fragment, useEffect, useContext } from "react";

import * as Contexts from "../../../contexts";
import * as Form from "../../../components/Form";

import { config, Translater } from "../../../config";
import { useHttp } from "../../../hooks";

import { IRole } from "../../../types/items";
import { IConfigBlockProps } from "./types";
import { InputWithTags } from "../../../components/InputWithTags";
import { getStringFromTranslationsArr } from "../../../helpers";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isClicked,
  photo,
  getOptions,
  setForm,
  inputHandler,
  metaKeywords,
  setMetaKeywords,
  metaDescription,
  setMetaDescription,
  valueLang,
  setPhoto,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request } = useHttp();

  const RolesEvents = {
    loadRolesOptions: async () => {
      try {
        const response: IRole[] = await request(
          `${config.API}/roles?title=`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        const formatted: IRole[] = [];
        getOptions && getOptions!(response);
        return formatted;
      } catch (e) {}
    },
    optionRoleHandler: (option: IRole) => {
      if (option && option._id) {
        setForm && setForm({ ...form, role: option.title });
      }
    },
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setPhoto(e.target.files[0]);
    }
  };

  useEffect(() => {
    RolesEvents.loadRolesOptions();
  }, []);

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          key={JSON.stringify(valueLang)}
          name="title"
          value={getStringFromTranslationsArr(form.title, valueLang)}
          isValid={isClicked ? form.title.length >= 3 : true}
          placeholder={Translater.Placeholders.minThree[language.slug]}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      <Form.LabelField
        fontSize="small"
        label={Translater.PaymentType.minimalOrder[language.slug]}
      >
        <Form.Input
          key={JSON.stringify(valueLang)}
          name="minimalOrder"
          value={form.minimalOrder.toString()}
          isValid={
            isClicked
              ? typeof form.minimalOrder === "number" && +form.minimalOrder >= 0
              : true
          }
          placeholder={Translater.Placeholders.minDeliveryCount[language.slug]}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.onlineMenu[language.slug]}
      >
        <Form.Input type="file" name="pdf" inputHandler={inputHandler} />
        {data?.offlineMenu && (
          <a
            href={`${config.serverURL}public/restaurants/offlineMenu/${data?.offlineMenu}`}
          >
            {data.title.find((item) => item.lang == language.slug)?.value +
              (language.slug === "uk" ? " меню" : " menu")}
          </a>
        )}
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <img
          alt="Restaurant"
          src={`${config.imageProxy}public/restaurants/mainImage/${data?.mainImage}`}
          style={{ width: 350, height: 250, objectFit: "cover" }}
        />
      </Form.LabelField>

      <InputWithTags
        tags={metaKeywords}
        setTags={setMetaKeywords}
        descriptionValue={metaDescription}
        setDescriptionValue={setMetaDescription}
      />
    </Fragment>
  );
};

export default ConfigBlock;
