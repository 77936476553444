import React, { useContext } from "react"

import * as Contexts from "../../contexts"
import * as Buttons from "../Buttons"

import { Languages } from "../../config"
import { ILanguage } from "../../types/contexts/language"

const LanguageToggler: React.FC = () => {
  const { actions: langActions, language } = useContext(
    Contexts.LanguageContext
  )

  const Events = {
    toggleHandler: (language: ILanguage) => {
      langActions.SET_LANGUAGE(language)
    },
  }

  return (
    <div className="lang-wrapper">
      <Buttons.LangButton
        title="UA"
        language={Languages.uk}
        activeLanguage={language as ILanguage}
        buttonHandler={Events.toggleHandler}
      />

      <Buttons.LangButton
        title="EN"
        language={Languages.en}
        activeLanguage={language as ILanguage}
        buttonHandler={Events.toggleHandler}
      />
    </div>
  )
}

export default LanguageToggler
