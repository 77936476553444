import React, { useState, useContext, useEffect, Dispatch } from "react"
import { IRestaurant } from "../../types/items"

import { useHttp } from "../../hooks/http.hook"
import * as Contexts from "../../contexts"
import * as Form from "../../components/Form"

import { config } from "../../config"
import "./delivery.scss"

const ListRestaurants: React.FC<{
  restaurantsList: string | undefined
  selectHandler: (e: any) => void
  setRests: Dispatch<IRestaurant[]>
}> = ({ selectHandler, restaurantsList, setRests }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()

  const [primary, setPrimary] = useState<IRestaurant[]>([])

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        if (response) {
          setPrimary(response)
        }
      } catch (e) {}
    },
  }

  useEffect(() => {
    primary.length && setRests(primary)
  }, [primary])

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  return (
    <Form.DefaultSelect
      options={primary}
      field="title"
      selectHandler={selectHandler}
      name="restId"
      value={restaurantsList}
    />
  )
}

export default ListRestaurants
