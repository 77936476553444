import React, { Fragment, useState, useEffect, useContext } from "react"

import * as Contexts from "../../../contexts"
import * as Buttons from "../../../components/Buttons"
import * as Form from "../../../components/Form"

import { config, Translater } from "../../../config"
import { useHttp } from "../../../hooks"

import { IStaff, IRole } from "../../../types/items"
import { IConfigBlockProps } from "./types"

import AsyncSelect from "react-select/async"

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isValid,
  isCreate = false,
  setRole,
  inputHandler,
  isClicked,
}) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [inputValue, setInputValue] = useState<string>("")
  const [options, setOptions] = useState<any[]>([])
  const [categorySelectValue, setCategorySelectValue] = useState<any>({
    label: "",
    value: "",
  })

  const Events = {
    inputHandler: (value: string) => {
      setInputValue(value)
    },
    loadOptions: async (inputValue: string) => {
      try {
        const response: IRole[] = await request(
          `${config.API}/roles/?perPage=100`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        const formatted: IRole[] = []
        if (response.length) {
          for (const item of response) {
            const option = {
              value: item._id as string,
              label: item.title as string,
            }
            formatted.push(option as any)
          }
        }
        setOptions(formatted)
        return formatted
      } catch (e) {
        console.log(e)
      }
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setRole(option.value)
    },
  }

  const Styles = {
    control: () => ({
      display: "flex",
      border: "1px solid #e6e6e6",
      borderRadius: 5,
      outline: 0,
    }),
    input: () => ({
      fontSize: 16,
    }),
  }

  useEffect(() => {
    if (data) setInputValue((data.role as IRole)?.title)
  }, [(data?.role as IRole)?.title])

  useEffect(() => {
    Events.loadOptions("")
  }, [])

  console.log("(form as IStaff).login", form as IStaff)

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.fullname[language.slug]}
      >
        <Form.Input
          name="fullname"
          value={form.fullname}
          isValid={isClicked ? form.fullname.toString().length >= 4 : true}
          placeholder={Translater.Placeholders.minFour[language.slug]}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.login[language.slug]}
      >
        <Form.Input
          name="login"
          autocomplete="new-password"
          value={(form as IStaff).login}
          isValid={isClicked ? !!(form as IStaff).login : true}
          placeholder={Translater.Placeholders.minFour[language.slug]}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.role[language.slug]}
      >
        <Fragment>
          <>
            <AsyncSelect
              cacheOptions
              loadingMessage={() =>
                Translater.Placeholders.loadingMessage[language.slug]
              }
              noOptionsMessage={() =>
                Translater.Placeholders.noOptionsMessage[language.slug]
              }
              inputValue={inputValue}
              styles={Styles}
              value={categorySelectValue}
              defaultOptions={options}
              onInputChange={Events.inputHandler}
              onChange={Events.optionHandler}
              loadOptions={Events.loadOptions}
            />
          </>
        </Fragment>
      </Form.LabelField>

      {isCreate ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.password[language.slug]}
        >
          <Form.Input
            type="password"
            name="password"
            autocomplete="new-password"
            value={(form as IStaff).password}
            isValid={isClicked ? isValid.password : true}
            placeholder={Translater.Placeholders.minSix[language.slug]}
            inputHandler={inputHandler}
          />
        </Form.LabelField>
      ) : null}
    </Fragment>
  )
}

export default ConfigBlock
