import { ITableTitle } from "../../../../types/table"

export const STitles: ITableTitle[] = [
  { type: "header", slug: "increment" },
  { type: "header", slug: "sitePhoto" },
  { type: "header", slug: "appPhoto" },
  { type: "header", slug: "name" },
  { type: "header", slug: "percent", sort: true },
  { type: "header", slug: "timeEnd", sort: true },
  { type: "header", slug: "" },
]
