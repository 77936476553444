import React, { Fragment, useContext } from "react";

import * as Contexts from "../../../../contexts";
import * as Table from "../../../../components/Table";

import { useHistory } from "react-router-dom";
import { useHttp } from "../../../../hooks/http.hook";
import { config, Translater } from "../../../../config";
import { ITrackProps } from "./types";

const Track: React.FC<ITrackProps> = ({ data, reloadHandler }) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request } = useHttp();
  const history = useHistory();

  const Events = {
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },
    editHandler: () => {
      history.push(`/archive/${data._id}`);
    },
  };

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/orders/${data._id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        reloadHandler();
      } catch (e) {
        console.log(e);
      }
    },
  };

  return (
    <Table.Track type="archive">
      <Table.Cell text={`${data.id}`} />

      <Table.Cell
        text={data.customer?.name}
        modalContent={
          <Table.ModalContent
            label={Translater.TableTitles?.phone[language.slug]}
            text={data.customer?.phone}
          />
        }
      />

      <Table.Cell
        text={
          data?.delivery?.mode === "courier"
            ? Translater.TableTitles.courier[language.slug]
            : Translater.TableTitles.pickup[language.slug]
        }
        modalContent={
          <Fragment>
            <Table.ModalContent
              label={Translater.TableTitles.phone[language.slug]}
              text={data?.delivery?.phone}
            />
            <Table.ModalContent
              label={Translater.TableTitles.type[language.slug]}
              text={
                data?.delivery?.mode === "courier"
                  ? Translater.TableTitles.courier[language.slug]
                  : Translater.TableTitles.pickup[language.slug]
              }
            />

            {data?.delivery?.address ? (
              <Table.ModalContent
                label={Translater.TableTitles.address[language.slug]}
                text={data?.delivery?.address.formatted_address}
              />
            ) : null}
            <Table.ModalContent
              label={Translater.TableTitles.deliveryPrice[language.slug]}
              text={`${data.deliveringPrice}`}
            />
          </Fragment>
        }
      />

      <Table.Cell
        text={
          data.comment
            ? data.comment
            : Translater.TableTitles.withoutComment[language.slug]
        }
        modalContent={
          <Table.ModalContent
            text={
              data.comment
                ? data.comment
                : Translater.TableTitles.withoutComment[language.slug]
            }
          />
        }
      />

      <Table.Cell
        text={
          //@ts-ignore
          Translater.TableTitles.statusTypes?.[data?.orderStatus]?.[
            language.slug
          ]
        }
      />

      <Table.Cell text={`${data.itemsPrice} грн`} />

      <Table.Cell
        text={new Date(data.archived as string).toLocaleDateString()}
      />

      <Table.Cell
        text={new Date(data.createdAt as string).toLocaleDateString()}
      />

      <Table.Cell
        type="control"
        editButton={access.ordersHistory.view}
        editHandler={Events.editHandler}
      />
    </Table.Track>
  );
};

export default Track;
