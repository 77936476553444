import React, { useContext, useState, KeyboardEvent, useEffect } from "react";
import { InpustWrap, KeyWordsList, KeyWordsWrap, Wrapper } from "./styled";
import { PhotoContainer } from "./components/PhotoContainer/PhotoContainer";
import { Input } from "components/Input";
import { CustomSelect } from "components/CustomSelect";
import { TCreateFormTypes } from "pages/Products/types";
import { Controller, useFormContext } from "react-hook-form";
import { Translater } from "config";
import { IoCloseCircleOutline } from "react-icons/io5";

import * as Contexts from "contexts";
import { notification } from "antd";
export const PhotoInfoWrap = () => {
  const [word, setWord] = useState("");
  const { language } = useContext(Contexts.LanguageContext);
  const {
    register,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useFormContext<TCreateFormTypes>();

  const required = Translater.Placeholders.requiredFields[language.slug];
  const options = [
    {
      label: Translater.Select.gramms[language.slug],
      value: "g",
    },
    {
      label: Translater.Select.ml[language.slug],
      value: "ml",
    },
  ];

  useEffect(() => {
    if (!watch("measurement")?.label) {
      setValue("measurement", options[0]);
    }
  }, [options, setValue, watch]);

  const addKeyWords = () => {
    const metakeywords = getValues("metaKeywords");
    if (word?.length && !metakeywords?.includes(word)) {
      setValue("metaKeywords", [...(metakeywords || []), word]);
      setWord("");
    } else {
      notification.info({
        message: Translater.TableTitles.notLongText[language.slug],
      });
    }
  };

  const keyDownEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addKeyWords();
    }
  };

  const onDeleteKeyWords = (item: string) => {
    setValue(
      "metaKeywords",
      getValues("metaKeywords")?.filter((word) => word !== item)
    );
  };

  return (
    <Wrapper>
      <PhotoContainer />

      <Input
        placeholder="100"
        width="100%"
        type="number"
        min={1}
        $error={errors.price?.message}
        label={`${Translater.TableTitles.price[language.slug]} ₴`}
        {...register("price", { required })}
      />
      <InpustWrap>
        <Input
          $error={errors.weight?.message}
          placeholder="50"
          min={5}
          type="number"
          width="190px"
          {...register("weight", { required })}
          label={Translater.TableTitles.weight[language.slug]}
        />
        <Controller
          control={control}
          rules={{ required }}
          name="measurement"
          render={({ field: { value, onChange } }) => (
            <CustomSelect
              options={options}
              placeholder="г"
              error={errors.measurement?.message}
              width="170px"
              onChange={onChange}
              value={value}
              label={Translater.measurement[language.slug]}
            />
          )}
        />
      </InpustWrap>
      <KeyWordsWrap>
        <p>Meta keywords</p>
        {watch("metaKeywords")?.length ? (
          <KeyWordsList>
            {watch("metaKeywords")?.map((item) => (
              <span key={item}>
                {item}{" "}
                <IoCloseCircleOutline onClick={() => onDeleteKeyWords(item)} />
              </span>
            ))}
          </KeyWordsList>
        ) : null}
        <Input
          onKeyDown={keyDownEnter}
          placeholder={Translater.Select.enterTag[language.slug]}
          width="100%"
          value={word}
          onChange={(e) => setWord(e.target.value)}
        />
      </KeyWordsWrap>
      <Input
        placeholder={Translater.TableTitles.description[language.slug]}
        label="Meta descripttion"
        width="100%"
        {...register("metaDescription")}
      />
    </Wrapper>
  );
};
