import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Buttons from "../../components/Buttons";
import * as Components from "./components";

import { useHttp } from "../../hooks/http.hook";
import { config, Translater } from "../../config";

import { TBanner } from "../../types/items";

import { useHistory } from "react-router-dom";
import { useValidation } from "../../hooks";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { TabLanguage } from "../../components/TabLanguage";

export const BannersCreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { multiLang } = useContext(Contexts.ConfigContext);

  const { loading, request } = useHttp();
  const history = useHistory();

  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    percent: 0,
    description: false,
    restaurantsList: true,
  });

  const [isClicked, setClick] = useState(false);

  const [form, setForm] = useState<TBanner>({
    name: [{ value: "", lang: language.slug }],
    description: [{ value: "", lang: language.slug }],
    appPhoto: "",
    sitePhoto: "",
    restaurant: "",
  });

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);
  const [valueLang, setValueLang] = useState<string>(language.slug);
  const [restaurantsList, setRestaurantsList] = useState<string[]>([]);

  const schema = useMemo<any>(
    () => ({
      name: {
        condition: form.name.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },
      photo: {
        condition: !!form.sitePhoto && !!form.appPhoto,
        error: `
        ${Translater.ErrorPhoto[language.slug]}`,
      },
      description: {
        condition: form.description.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.description[language.slug]}`,
      },
      restaurant: {
        condition: form.restaurant.length >= 2,
        error: `
        ${Translater.ErrorRestoran[language.slug]}`,
      },
    }),
    [form, Translater, language]
  );

  const { errors, validationSchema, validation } = useValidation(schema);

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "title") {
        const isTitleInArr = form.name.find((item) => item.lang == valueLang)
          ? true
          : false;

        if (isTitleInArr) {
          const title = form.name
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            name: title as { value: string; lang: string }[],
          });

          return;
        }

        const title = form.name
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          name: title as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.name === "description") {
        const isTitleInArr = form.description.find(
          (item) => item.lang == valueLang
        )
          ? true
          : false;

        if (isTitleInArr) {
          const description = form.description
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            description: description as { value: string; lang: string }[],
          });

          return;
        }

        const description = form.description
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          description: description as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.type === "file" && e.target.name == "appPhoto") {
        //@ts-ignore
        setForm({ ...form, appPhoto: e.target.files[0] });
      }
      if (e.target.type === "file" && e.target.name == "sitePhoto") {
        //@ts-ignore
        setForm({ ...form, sitePhoto: e.target.files[0] });
      }
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.name == "restraunt") {
        setRestaurantsList(restaurantsList.concat(e.target.value));
      }
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    createHandler: async () => {
      try {
        setClick(true);
        await validation();
        Callbacks.Create();
      } catch (e) {
        toogleIsAlertOpen(true);
      }
    },
  };

  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData();
        data.append("appPhoto", form.appPhoto as Blob);
        data.append("sitePhoto", form.sitePhoto as Blob);
        data.append("name", JSON.stringify(form.name));
        data.append("description", JSON.stringify(form.description));
        data.append("infinite", "true");
        form?.restaurant &&
          data.append("restaurant", form?.restaurant as string);

        const response = await request(`${config.API}/stocks`, "POST", data, {
          Authorization: `Bearer ${token as string}`,
        });
        if (response) {
          history.goBack();
          setForm({
            ...form,
            name: [{ value: "", lang: language.slug }],
            description: [{ value: "", lang: language.slug }],
            appPhoto: "",
            sitePhoto: "",
          });
        }
      } catch (e) {}
    },
  };

  useEffect(() => {
    if (form.appPhoto) toggleValid({ ...isValid, photo: true });
    else toggleValid({ ...isValid, photo: false });
  }, [form.appPhoto]);

  useEffect(() => {
    if (form.sitePhoto) toggleValid({ ...isValid, photo: true });
    else toggleValid({ ...isValid, photo: false });
  }, [form.appPhoto]);

  return (
    <Page.Wrapper title={Translater.BannersCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.BannersCreatePage.title[language.slug]}
        backButtonLink="/banners"
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Components.ConfigBlock
        form={form}
        from={"create"}
        inputHandler={Events.inputHandler}
        setForm={setForm}
        isClicked={isClicked}
        valueLang={valueLang}
        selectHandler={Events.selectHandler}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};
