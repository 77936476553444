import { CSSProperties } from "react";
import { ControlProps, OptionTypeBase } from "react-select";
import styled from "styled-components";
type TProps = {
  width: string;
  height: string;
  error: boolean;
};

export const styledSelect = ({ width, height, error }: TProps) => ({
  control: (style: CSSProperties, state: ControlProps<OptionTypeBase>) => {
    const defaultBorderColor = "#E1E1E1";
    const errorBorderColor = "#CA1F1F";
    const focusedBorderColor = "#03CB83";

    const borderColor = state.isFocused
      ? error
        ? errorBorderColor
        : focusedBorderColor
      : error
      ? errorBorderColor
      : defaultBorderColor;

    return {
      ...style,
      fontSize: "16px",
      borderRadius: "8px",
      boxShadow: "none",
      width: width,
      height: height,
      borderColor: borderColor,
      "&:hover": {
        borderColor: borderColor,
      },
    };
  },
  container: (base: CSSProperties) => ({
    ...base,
    width,
  }),
  indicatorSeparator: (base: CSSProperties) => ({ ...base, display: "none" }),
  menu: (base: CSSProperties) => ({
    ...base,
    fontSize: "16px",
    width: "100%",
  }),
  input: (base: CSSProperties) => ({
    ...base,
  }),
  option: (base: CSSProperties, state: ControlProps<OptionTypeBase>) => ({
    ...base,
  }),
});

export const ErrorMessage = styled.div`
  position: absolute;
  font-size: 12px;
  bottom: -16px;
  color: #ca1f1f;
`;

export const Label = styled.span`
  color: #333333;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
`;

export const CustomSelectWrap = styled.div`
  width: auto;
  position: relative;
`;
