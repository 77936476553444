import React from 'react'

import { IModalContentProps } from './type'


const ModalContent: React.FC<IModalContentProps> = ({
  label,
  text
}) => {

  return (
    <div className='table-cell-modal-content'>
      {label ? (
        <h6 className='table-cell-modal-label'>
          {label}
        </h6>
      ) : null}

      <p className='table-cell-modal-text'>
        {text}
      </p>
    </div>
  )
}

export default ModalContent