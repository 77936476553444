import styled from "styled-components";

export const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(237, 237, 237, 1);
  padding: 12px;
  p {
    display: flex;
    font-weight: 600;
    max-width: 100%;
    &:nth-child(1) {
      min-width: 250px;
    }
    &:nth-child(2) {
      min-width: 200px;
    }
    &:nth-child(3) {
      min-width: 200px;
    }
    &:nth-child(4) {
      min-width: 44px;
    }
  }
`;
