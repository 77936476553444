import React, {
  useState,
  createContext,
} from 'react'

import {
  IData,
  IModalContext,
  IModalActions,
  TPossibleModalTypes,
} from '../types/contexts/modal'


const ModalContext = createContext<IModalContext>({
  isActive: false,
  type: null,
  data: null,
  actions: {
    SHOW: (data, type) => { },
    HIDE: () => { }
  }
})

export default ModalContext


export const ModalContextProvider: React.FC = ({ children }) => {

  const [type, setType] = useState<TPossibleModalTypes | null>(null)
  const [isActive, toggleActive] = useState<boolean>(false)
  const [data, setData] = useState<IData | null>(null)

  const actions: IModalActions = {
    SHOW: (data, type) => {
      toggleActive(true)
      setData(data)
      setType(type)

      const body = document.querySelector('body')
      if (body) body.style.overflow = 'hidden'
    },
    HIDE: () => {
      toggleActive(false)
      setData(null)
      setType(null)
      window.location.reload()

      const body = document.querySelector('body')
      if (body) body.style.overflow = 'auto'
    }
  }

  return (
    <ModalContext.Provider value={{
      isActive, data, type, actions
    }}>
      {children}
    </ModalContext.Provider>
  )
}
