import React from "react";
import { ButtonRound, Wrapper } from "./styled";
import { BsCheck } from "react-icons/bs";

type TProps = {
  onClick?: () => void;
  value?: boolean;
  disabled?: boolean;
  title?: string;
};

export const RoundCheckbox = ({ disabled, onClick, value, title }: TProps) => {
  return (
    <Wrapper onClick={() => !disabled && onClick && onClick()}>
      <ButtonRound type="button" $active={!!value}>
        {value && <BsCheck />}
      </ButtonRound>{" "}
      {title}
    </Wrapper>
  );
};
