import { useSortable } from "@dnd-kit/sortable";
import { useContext } from "react";
import { IProduct } from "../../../../types/items";
import * as Contexts from "../../../../contexts";
import { CSS } from "@dnd-kit/utilities";
import { config, Translater } from "../../../../config";
import { ProductItemStyled } from "./styled";
import React from "react";
import { AddButton, ProductInfo } from "../ProductList/styled";

type SortableProductProps = {
  product: IProduct;
  onProductClick: (id?: string) => void;
  formatPrice: (price: number) => string;
  onDeleteProducts: (product: IProduct) => void;
};

const SortableProduct = ({
  product,
  onProductClick,
  formatPrice,
  onDeleteProducts,
}: SortableProductProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: product._id || "" });
  const { language } = useContext(Contexts.LanguageContext);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const src = `${config.imageProxy}/public/${product.photo}`;
  const title = product.name?.find((el) => el.lang === language.slug)?.value;
  const price = +product.price;
  const discountPrice =
    (1 - (product?.restaurantDiscount?.[0]?.discountPercent || 0)) * price;

  return (
    <ProductItemStyled
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <img
        alt="photo_product"
        src={src}
        onClick={() => onProductClick(product._id)}
      />
      <ProductInfo>
        <p onClick={() => onProductClick(product._id)}>{title}</p>
        <span>
          {discountPrice !== price && (
            <p className="old_price">{formatPrice(price)}</p>
          )}
          {formatPrice(discountPrice || price)} ₴
        </span>
      </ProductInfo>
      <AddButton onClick={() => onDeleteProducts(product)}>
        {Translater.Buttons.delete[language.slug]}
      </AddButton>
    </ProductItemStyled>
  );
};

export default SortableProduct;
