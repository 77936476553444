import React, { useContext } from "react";
import Select, { Styles } from "react-select";
import { CustomSelectWrap, ErrorMessage, Label, styledSelect } from "./styled";
import { Translater } from "config";
import * as Contexts from "contexts";

type TProps = {
  value?: { label: string; value: string };
  options?: { label: string; value: string }[];
  onChange?: (v: unknown) => void;
  isClearable?: boolean;
  styles?: Partial<Styles>;
  placeholder?: string;
  label?: string;
  error?: string;
  width?: string;
  height?: string;
};

export const CustomSelect = ({
  options,
  value,
  onChange,
  isClearable,
  styles,
  placeholder,
  label,
  error,
  width = "250px",
  height = "44px",
}: TProps) => {
  const { language } = useContext(Contexts.LanguageContext);
  return (
    <CustomSelectWrap>
      {label && <Label>{label}</Label>}
      <Select
        noOptionsMessage={() => Translater.TableTitles.noInfo[language.slug]}
        placeholder={placeholder}
        styles={{
          ...styledSelect({ width, height, error: !!error }),
          ...styles,
        }}
        options={options}
        value={value}
        onChange={onChange}
        isClearable={isClearable}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </CustomSelectWrap>
  );
};
