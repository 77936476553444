import React, { Fragment, useState, useContext, useEffect } from 'react'

import * as Contexts from '../../../contexts'
import * as Buttons from '../../../components/Buttons'
import * as Form from '../../../components/Form'
import * as Page from '../../../components/Page'

import { config, Translater } from '../../../config'
import { useHttp } from '../../../hooks'

import { SHiddenOptions } from '../../../components/Form/static'
import { ICategory, IRestaurant, TTranslations } from '../../../types/items'
import { IConfigBlockProps } from './types'
import { InputWithTags } from '../../../components/InputWithTags'
import { getStringFromTranslationsArr } from '../../../helpers'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isValid,
  setParentID,
  inputHandler,
  selectHandler,
  setForm,
  isClicked,
  metaKeywords,
  setMetaKeywords,
  metaDescription,
  setMetaDescription,
  valueLang,
}) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { request } = useHttp()

  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [restaurantOptions, setRestaurantOptions] = useState<IRestaurant[]>([])
  const [, setCategorySelectValue] = useState<any>({
    label: '',
    value: '',
  })

  const Events = {
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          setForm({
            ...form,
            restaurant: data?.restaurant
              ? (data.restaurant as any)[0]
              : response[0],
          })
        }
        setRestaurantOptions(response)
      } catch (e) {
        console.log(e)
      }
    },
    loadCategoriesOptions: async () => {
      try {
        function createUrl() {
          const url = `${config.API}/categories?perPage=1000`
          const arr = restaurantOptions.map((item) => {
            return item._id
          })

          const string =
            arr && replaceAll(arr?.toString(), ',', '&restaurant[]=')
          function replaceAll(
            string: string,
            search: string,
            replace: string
          ): string {
            return string.split(search).join(replace)
          }

          return url
        }

        if (restaurantOptions && restaurantOptions.length > 0) {
          const response: ICategory[] = await request(
            createUrl(),
            'GET',
            null,
            {
              Authorization: `Bearer ${token as string}`,
            }
          )
          setCategoryOptions(response)
        }
      } catch (e) {}
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option)
      setParentID(option.value)
    },
  }

  useEffect(() => {
    Events.loadRestaurantOptions()
  }, [data?.restaurant])
  useEffect(() => {
    Events.loadCategoriesOptions()
  }, [restaurantOptions])

  return (
    <Fragment>
      <Form.LabelField
        key={JSON.stringify(valueLang)}
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          name='title'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.title.length > 0 : true}
          value={getStringFromTranslationsArr(form.title, valueLang)}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.restaurants[language.slug]}
      >
        <Fragment>
          <Page.SearchSelect
            key={JSON.stringify(valueLang)}
            placeholder={Translater.Placeholders.searchByRests[language.slug]}
            searchField='title'
            data={restaurantOptions.map((item) => ({
              label: getStringFromTranslationsArr(item?.title, language.slug),
              value: item?._id,
            }))}
            setSwitch={(restaurant) => {
              setForm({
                ...form,
                restaurant: restaurantOptions
                  .map((rest) => {
                    if (rest._id == restaurant.value) return rest
                  })
                  .filter((rest) => rest?._id)[0] as IRestaurant,
              })
            }}
            value={{
              label: getStringFromTranslationsArr(
                form.restaurant?.title as TTranslations,
                language.slug
              ),
              title: form.restaurant?._id,
            }}
          />
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.hidden[language.slug]}
      >
        <Form.Select
          name='hidden'
          options={SHiddenOptions}
          value={form.hidden as string}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.photo[language.slug]}
      >
        {data?.photo ? (
          <img
            alt='Restaurant'
            src={`${config.imageProxy}public/${(data as any).photo}`}
            style={{ width: 350, height: 250, objectFit: 'cover' }}
          />
        ) : null}
        <Form.Input
          type='file'
          name='photo'
          isValid={isClicked ? isValid.photo : true}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <InputWithTags
        tags={metaKeywords}
        setTags={setMetaKeywords}
        descriptionValue={metaDescription}
        setDescriptionValue={setMetaDescription}
      />
    </Fragment>
  )
}

export default ConfigBlock
