import { ITableTitle } from "../../../../types/table"

export const STitles: ITableTitle[] = [
  { type: "header", slug: "increment" },
  { type: "header", slug: "name", sort: true },
  { type: "header", slug: "phone" },
  { type: "header", slug: "updatedAt", sort: true },
  { type: "header", slug: "createdAt", sort: true },
  { type: "header", slug: "dateOfBirth", sort: true },
  { type: "header", slug: "" },
]
