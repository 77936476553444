import React from "react";

import "./mainTable.scss";
import { TMainTable } from "./types";

export const MainTable = ({ el, columns }: TMainTable) => {
  return (
    <div className="container-main">
      {columns.map((column: any, index: any) => (
        <div key={index} className="item-block" style={{ width: `${column.width}` }}>
          {column.Cell ? column.Cell(el) : el}
        </div>
      ))}
    </div>
  );
};