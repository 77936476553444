import React, { Fragment, useContext } from "react"

import * as Contexts from "../../../../contexts"
import * as Form from "../../../../components/Form"

import { Translater } from "../../../../config"
import { IDetailBlockProps } from "./types"

const DetailBlock: React.FC<IDetailBlockProps> = ({ data }) => {
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      ></Form.LabelField>

      <Form.DateBlock
        updated={data.updatedAt as string}
        created={data.createdAt as string}
      />
    </Fragment>
  )
}
export default DetailBlock
