import React, { useContext, useState } from "react";
import * as Page from "../../components/Page";
import { Translater } from "../../config";
import * as Contexts from "../../contexts";
import { useGetVariations } from "./helpers";
import { FilterBar } from "./components/FilterBar/FilterBar";
import { TParams } from "./types";
import { Wrapper } from "./styled";
import { useHistory } from "react-router-dom";
import { TitleBar } from "./components/TitleBar/Titlebar";
import { VariationItem } from "./components/Variationitem/Variationitem";
import { IPagination } from "../../types/items";

const Variations = () => {
  const history = useHistory();
  const { language } = useContext(Contexts.LanguageContext);
  const [params, setParams] = useState<TParams>({
    category: undefined,
    restaurant: undefined,
  });

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 20,
    totalCount: 0,
  });

  const { variations } = useGetVariations({
    page: pagination.page,
    perPage: pagination.perPage,
    category_id: params?.category?.value || undefined,
  });

  const paginateHandler = (index: number) => {
    setPagination((prev) => ({ ...prev, page: index }));
  };

  return (
    <Page.Wrapper>
      <Page.Header
        saveButton
        saveButtonTitle={Translater.Buttons.createVariation[language.slug]}
        title={Translater.Buttons.variations[language.slug]}
        saveButtonHandler={() => history.push("/variations/create")}
      />
      <Wrapper>
        <FilterBar params={params} setParams={setParams} />
        <TitleBar />
        <ul>
          {variations?.map((item) => (
            <VariationItem item={item} key={item._id} />
          ))}
        </ul>
      </Wrapper>
      <Page.Pagination
        linkTo="/variations"
        pagination={pagination}
        paginateHandler={paginateHandler}
        setPagination={setPagination}
      />
    </Page.Wrapper>
  );
};

export default Variations;
