import React from "react"
import * as Page from "../../../../components/Table"
import { Options } from "./static"
import { TableProps } from "./types"
import Track from "./Track"

const Table: React.FC<TableProps> = ({ data, Events, page, sortHandler }) => {
  return (
    <Page.Table type="options" titles={Options} sortHandler={sortHandler}>
      {data?.map((item, index) => (
        <Track
          key={item._id}
          data={item}
          Events={Events}
          order={index + 1 + (page == 1 ? 0 : (page - 1) * 10)}
        />
      ))}
    </Page.Table>
  )
}

export default Table
