import React, { Fragment, useContext } from "react";

import * as Contexts from "../../../contexts";
import * as Form from "../../../components/Form";

import { config, Translater } from "../../../config";

import { IConfigBlockProps } from "./types";
import { getStringFromTranslationsArr } from "../../../helpers";
import ListRestaurants from "../ListRestaurants";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  from,
  inputHandler,
  selectHandler,
  isClicked,
  valueLang,
  appPhoto,
  sitePhoto,
}) => {
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  console.log(valueLang);

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          key={JSON.stringify(valueLang)}
          name="title"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={
            isClicked
              ? getStringFromTranslationsArr(form.name, valueLang).length >= 2
              : true
          }
          value={getStringFromTranslationsArr(form.name, valueLang)}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.description[language.slug]}
      >
        <Form.Input
          key={JSON.stringify(valueLang)}
          name="description"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.description.length >= 2 : true}
          value={getStringFromTranslationsArr(form.description, valueLang)}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.restaurantsList[language.slug]}
      >
        <ListRestaurants
          selectHandler={selectHandler}
          restaurant={form?.restaurant as string}
          isClicked={isClicked}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.appPhoto[language.slug]}
      >
        <Form.Input
          type="file"
          name="appPhoto"
          isValid={isClicked ? !!form.appPhoto : true}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      {from !== "create" && !appPhoto && (
        <img
          alt="appPhoto"
          src={`${config.imageProxy}public/${form.appPhoto}`}
          style={{ maxWidth: "350px", maxHeight: "350px" }}
        />
      )}

      {appPhoto && (
        <img
          alt="appPhoto"
          src={URL.createObjectURL(appPhoto)}
          style={{ maxWidth: "350px", maxHeight: "350px" }}
        />
      )}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.sitePhoto[language.slug]}
      >
        <Form.Input
          type="file"
          name="sitePhoto"
          isValid={isClicked ? !!form.sitePhoto : true}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      {from !== "create" && !sitePhoto && (
        <img
          alt="sitePhoto"
          src={`${config.imageProxy}public/${form.sitePhoto}`}
          style={{ maxWidth: "350px", maxHeight: "350px" }}
        />
      )}

      {sitePhoto && (
        <img
          alt="appPhoto"
          src={URL.createObjectURL(sitePhoto)}
          style={{ maxWidth: "350px", maxHeight: "350px" }}
        />
      )}
    </Fragment>
  );
};

export default ConfigBlock;
