import React from "react";
import { BtnProps } from "./types";

const ClearButton: React.FC<BtnProps> = ({ children, onHandler }) => {
    return (
        <button 
            onClick={onHandler} 
            className="btn--clear-btn"
        >
            {children}
        </button>
    );
};

export default ClearButton;