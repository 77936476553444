import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Components from "./components";
import * as Buttons from "../../components/Buttons";

import { useHttp } from "../../hooks/http.hook";
import { ICategory, IRestaurant } from "../../types/items";
import { config, Translater } from "../../config";
import { useParams, useHistory } from "react-router-dom";
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from "../../types/multiLang";
import { TDetailValidationSchema, TFormsCategory } from "./types";
import { useValidation } from "../../hooks";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { TabLanguage } from "../../components/TabLanguage";
// import { useGetProductByCategory } from "./components/Detail/helpers";

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { id } = useParams() as any;
  const history = useHistory();
  const { loading, request } = useHttp();

  const [primary, setPrimary] = useState<ICategory | null>(null);
  const [isValid, toggleValid] = useState({
    title: false,
    photo: true,
    position: true,
    restaurants: true,
  });
  const [form, setForm] = useState<TFormsCategory>({
    title: [{ value: "", lang: "" }],
    hidden: "",
    promotionalOffer: "",
    parent_id: "",
    restaurant: null,
    photo: "",
    position: "",
    products: [],
    metaDescription: "",
    metaKeywords: [],
  });
  const [valueLang, setValueLang] = useState<string>(language.slug);

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);
  const [metaKeywords, setMetaKeywords] = useState<string[]>([]);
  const [metaDescription, setMetaDescription] = useState<string>("");
  // const { products } = useGetProductByCategory(id);
  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      title: {
        condition:
          form.title
            .map((item) => {
              if (!item.value) {
                return false;
              }
              return true;
            })
            .filter((item) => item).length == 2,
        error: `
          ${Translater.ErrorLanguage[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
    }),
    [form, Translater, language]
  );
  const { errors, validation } = useValidation(schema);

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "title") {
        const isTitleInArr = form.title.find((item) => item.lang == valueLang)
          ? true
          : false;

        if (isTitleInArr) {
          const title = form.title
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            title: title as { value: string; lang: string }[],
          });

          return;
        }

        const title = form.title
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          title: title as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.type === "file" && e?.target?.files) {
        setForm({ ...form, photo: e?.target?.files[0] });
      } else setForm({ ...form, [e.target.name]: e.target.value });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    setParentID: (parent_id: string) => {
      setForm({ ...form, parent_id });
    },
    saveHandler: async () => {
      try {
        await validation();

        Callbacks.Save();
      } catch (e) {
        toogleIsAlertOpen(true);
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },
  };

  const Callbacks = {
    SaveProducts: async () => {
      form.products?.forEach(async (product) => {
        try {
          const data = new FormData();
          data.append("name", JSON.stringify(product?.name));

          data.append("description", JSON.stringify(product?.description));
          data.append("isVariated", product.isVariated ? "true" : "false");
          data.append("novelty", product.novelty ? "true" : "false");

          product.order && data.append("order", product.order.toString());

          await request(
            `${config.API}/products/${product?._id}`,
            "POST",
            data,
            {
              Authorization: `Bearer ${token as string}`,
            }
          );
          window.location.reload();
        } catch (e) {}
      });
    },
    Fetch: async () => {
      try {
        const response: ICategory = await request(
          `${config.API}/categories/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        // if (response) {
        setForm({
          ...response,
          products: [],
          title: response.title,
          hidden: response.hidden,
          promotionalOffer: response.promotionalOffer,
          parent_id: response.parent_id,
          position: response.position,
          restaurant: (response.restaurant as any)[0],
        });
        setMetaDescription(response?.metaDescription);
        setMetaKeywords(response?.metaKeywords);
        setPrimary(response);
        // }
      } catch (e) {}
    },
    FetchCategory: async () => {
      try {
        const response: ICategory = await request(
          `${config.API}/categories/${primary?.parent_id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        if (primary) setPrimary({ ...primary, parentCategory: response });
      } catch (e) {
        console.log(e);
      }
    },
    Save: async () => {
      try {
        const data = new FormData();

        data.append("title", JSON.stringify(form.title));

        data.append("hidden", form.hidden as string);
        data.append("promotionalOffer", form.promotionalOffer as string);
        data.append("restaurant[]", form?.restaurant?._id as string);
        data.append("photo", form.photo);

        data.append("metaDescription", metaDescription);
        if (metaKeywords.length) {
          metaKeywords.map((el) => data.append("metaKeywords[]", el));
        }

        if (form.parent_id) {
          data.append("parent_id", form.parent_id);
        }
        const response = await request(
          `${config.API}/categories/${primary?._id}`,
          "PUT",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        response && (await Callbacks.SaveProducts());
        response && (await Callbacks.Fetch());
        // if (response) history.push("/categories")
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/categories/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        history.push("/categories");
      } catch (e) {}
    },
  };

  useEffect(() => {
    Callbacks.Fetch();
  }, [id]);

  useEffect(() => {
    if (primary?.parent_id) Callbacks.FetchCategory();
  }, [primary?.parent_id]);

  if (loading || !primary) return <Page.Preloader />;

  return (
    <Page.Wrapper
      title={
        primary.title?.map((item) => {
          if (item.lang === language.slug) {
            return item.value;
          }
        })[0] as string
      }
    >
      <Page.Header
        backButtonTitle={Translater.CategoriesDetailPage.title[language.slug]}
        backButtonLink="/categories"
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Components.ConfigBlock
        data={primary}
        form={form}
        isValid={isValid}
        metaKeywords={metaKeywords}
        setMetaKeywords={setMetaKeywords}
        metaDescription={metaDescription}
        setMetaDescription={setMetaDescription}
        setForm={setForm}
        setParentID={Events.setParentID}
        inputHandler={Events.inputHandler}
        isDetailPage
        selectHandler={Events.selectHandler}
        valueLang={valueLang}
      />

      <Components.DetailBlock data={primary} form={form} setForm={setForm} />
      <Buttons.Container
        disabled={loading}
        position={"relative"}
        saveHandler={access.categories?.change ? Events.saveHandler : false}
        deleteHandler={access.categories?.delete ? Events.deleteHandler : false}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default DetailPage;
