import React, { SyntheticEvent, useContext } from "react"

import { PhotoPicker } from "../../../PhotoPicker"
import { TContent } from "./types"
import { PhotoPickerArray } from "../PhotoPickerArray"
import * as Contexts from "../../../../contexts"

export const Content = ({
  descriptionData,
  setFormData,
  formData,
  photos,
  setPhotos,
  valueLang,
  errors,
  setDescriptionData,
  type,
}: TContent) => {
  const { language } = useContext(Contexts.LanguageContext)

  const handleChange = (headerTitle: string, file: File) => {
    if (headerTitle === (language.slug == "uk" ? "Фото 1" : "Photo 1")) {
      setFormData((prevState) => ({
        ...prevState,
        headerPhoto: file,
      }))
    } else if (headerTitle === (language.slug == "uk" ? "Фото 2" : "Photo 2")) {
      setFormData((prevState) => ({
        ...prevState,
        mainImage: file,
      }))
    }
  }

  const textareaHanlder = (e: SyntheticEvent) => {
    const { value, name } = e.target as HTMLTextAreaElement
    setDescriptionData((prevState) => ({ ...prevState, [name]: value }))
  }

  return (
    <div className="content">
      <div className="photo-wrapper">
        <div className="photo-block">
          <PhotoPicker
            headerTitle={language.slug == "uk" ? "Фото 1" : "Photo 1"}
            file={formData.headerPhoto}
            onChange={(file) =>
              handleChange(language.slug == "uk" ? "Фото 1" : "Photo 1", file)
            }
            isHeader
            width="342px"
            height="465px"
          />
        </div>
        <div className="photo-block">
          <PhotoPicker
            headerTitle={language.slug == "uk" ? "Фото 2" : "Photo 2"}
            file={formData.mainImage}
            onChange={(file) =>
              handleChange(language.slug == "uk" ? "Фото 2" : "Photo 2", file)
            }
            isHeader
            width="342px"
            height="207px"
          />
        </div>
      </div>
      {type !== "libraria" && (
        <>
          <h2 className="title-h2">
            {language.slug == "uk" ? "Галерея" : "Gallery"}
          </h2>
          <PhotoPickerArray photos={photos} setPhotos={setPhotos} />
        </>
      )}
      <div>
        {valueLang === "uk" && (
          <div className="form">
            <label className="label">
              {language.slug == "uk" ? "Текст" : "Text"}
            </label>
            <textarea
              onChange={(e) => textareaHanlder(e)}
              className="textarea"
              name="descriptionUk"
              value={descriptionData.descriptionUk}
            ></textarea>
          </div>
        )}
        {valueLang === "en" && (
          <div className="form">
            <label className="label">
              {language.slug == "uk" ? "Текст" : "Text"}
            </label>
            <textarea
              onChange={(e) => textareaHanlder(e)}
              className="textarea"
              name="descriptionEn"
              value={descriptionData.descriptionEn}
            ></textarea>
          </div>
        )}
        {(errors.descriptionEn || errors.descriptionUk) && (
          <p className="error-message-description">
            {errors.descriptionEn || errors.descriptionUk}
          </p>
        )}
      </div>
    </div>
  )
}
