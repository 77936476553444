import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  color: #333333;
  font-size: 14px;
`;

export const ButtonRound = styled.button<{ $active: boolean }>`
  outline: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ $active }) => (!$active ? "#e1e1e1" : "#03CB83")};
  color: #ffffff;
  cursor: pointer;
  background-color: ${({ $active }) => (!$active ? "transparent" : "#03CB83")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  svg {
    width: 18px;
    height: 18px;
  }
`;
