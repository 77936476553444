import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
} from "react";

import * as Contexts from "../../../../contexts";
import * as Form from "../../../../components/Form";
import * as Buttons from "../../../../components/Buttons";

import { config, Translater } from "../../../../config";

import { useHttp } from "../../../../hooks/http.hook";

import { SOptions } from "../static";
import { useHistory } from "react-router-dom";
import { IDetailBlockProps } from "./types";
import moment from "moment";

const DetailBlock: React.FC<IDetailBlockProps> = ({ data, reloadHandler }) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { isActive } = useContext(Contexts.ModalContext);

  const { loading, request } = useHttp();
  const history = useHistory();

  const [orderStatus, setStatus] = useState<string>("");

  const Events = {
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setStatus(e.target.value);
    },
    saveHandler: () => {
      const answer = window.confirm(Translater.Alert.save[language.slug]);
      if (answer) Callbacks.Save();
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },
  };

  const Callbacks = {
    Save: async () => {
      try {
        await request(
          `${config.API}/orders/${data._id}`,
          "PATCH",
          { orderStatus },
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        await reloadHandler();
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/orders/${data._id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        if (!isActive) history.goBack();
        else reloadHandler();
      } catch (e) {}
    },
  };

  useEffect(() => {
    setStatus(data?.orderStatus);
  }, []);

  return (
    <Fragment>
      <Buttons.Container
        disabled={loading}
        saveHandler={access.orders?.change ? Events.saveHandler : false}
        deleteHandler={access.orders?.delete ? Events.deleteHandler : false}
      />

      <div className="block-container">
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.orderNumber[language.slug]}
          text={`${data.id}`}
        />
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.orderStatus[language.slug]}
          // text={Translater.TableTitles.statusTypes[data?.status][language.slug]}
        >
          {data.status !== "payment-processing" ? (
            <Form.Select
              name="status"
              options={SOptions}
              value={orderStatus}
              selectHandler={Events.selectHandler}
            />
          ) : null}
        </Form.LabelField>
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.comment[language.slug]}
          text={
            data.comment
              ? data.comment
              : Translater.TableTitles.withoutComment[language.slug]
          }
        />
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.user[language.slug]}
        >
          {data.customer && (
            <Buttons.Link
              hideModal
              title={data.customer.name}
              linkTo={`/users/${data.customer._id}`}
            />
          )}

          <p className="label-text">{data.customer && data.customer.phone}</p>
        </Form.LabelField>
        <Form.LabelField
          label={Translater.TableTitles.type[language.slug]}
          text={
            data?.delivery?.mode === "courier"
              ? `${Translater.TableTitles.courier[language.slug]} (${
                  data?.delivery?.address
                })`
              : `${Translater.TableTitles.pickup[language.slug]} ${
                  data?.delivery?.address
                }`
          }
        />

        {data?.paidByBonuses === 0 ? (
          <Form.LabelField
            fontSize="small"
            label={Translater.TableTitles.price[language.slug]}
            text={`${data?.itemsPrice.toFixed()} грн(${
              //@ts-ignore
              Translater.PaymentType[data.paymentType][language.slug]
            }) ${
              data?.paymentStatus
                ? `(${
                    //@ts-ignore
                    Translater.TableTitles.statusTypes?.[data?.paymentStatus]?.[
                      language.slug
                    ]
                  })`
                : ""
            } `}
          />
        ) : (
          <Form.LabelField
            fontSize="small"
            label={Translater.TableTitles.price[language.slug]}
            text={`${(data?.itemsPrice - data?.paidByBonuses).toFixed()} грн (${
              Translater.TableTitles.paymentBonuses[language.slug]
            })(${data.paymentType === "cash" ? "Наличные" : "Онлайн"})(${
              //@ts-ignore
              Translater.TableTitles.statusTypes?.[data?.paymentStatus]?.[
                language.slug
              ]
            })`}
          />
        )}
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.deliveryPrice[language.slug]}
          text={`${data.deliveryPrice} грн`}
        />

        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.commonPrice[language.slug]}
          text={`${data?.totalPrice.toFixed()} грн`}
        />

        {/* {data.delivery.mode !== "pickup" && ( */}
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.deliveryTime[language.slug]}
          text={`${
            data?.delivery?.timeType === "theFastest"
              ? Translater.Sidebar.theFaster[language.slug]
              : `${moment(data?.delivery?.deliveryTime).format(
                  "DD-MM-YY HH:mm"
                )}`
          }`}
        />
        {/* )} */}
        {/* <Form.LabelField
          fontSize="small"
          label={Translater.Call[language.slug]}
          text={
            data?.call
              ? Translater.Select.yes[language.slug]
              : Translater.Select.no[language.slug]
          }
        /> */}

        <Form.LabelField
          fontSize="small"
          label={Translater.Technique[language.slug]}
          text={data?.quantityPersons?.toString() ?? "0"}
        />

        <Form.LabelField
          fontSize="small"
          label={Translater.MoneyUser[language.slug]}
          text={(data?.restOf ?? 0).toString()}
        />

        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.updatedAt[language.slug]}
          text={new Date(data.updatedAt as string).toLocaleDateString()}
        />
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.createdAt[language.slug]}
          text={new Date(data.createdAt as string).toLocaleDateString()}
        />
      </div>
    </Fragment>
  );
};

export default DetailBlock;
