export interface ISidebarContext {
  isActive: boolean
  actions: TSidebarActions
}

type TPossibleSidebarActions =
  | 'SHOW'
  | 'HIDE'
  | 'SYNC'
  | 'TOGGLE'

export type TSidebarActions = {
  [action in TPossibleSidebarActions]: () => void
}

export enum EVars {
  SIDEBAR = 'SIDEBAR'
}
