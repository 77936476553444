import React from 'react'
import { ILangButtonProps } from './types'

const LangButton: React.FC<ILangButtonProps> = ({
  title,
  language,
  activeLanguage,
  buttonHandler
}) => {

  return (
    <button
      className={`btn btn--lang 
        ${language.slug === activeLanguage.slug && 'active'}
      `}
      onClick={() => buttonHandler(language)}
    >
      {title}
    </button>
  )
}
export default LangButton