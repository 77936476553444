import React, { ChangeEvent, useContext } from "react"
import { TimePicker } from "antd"
import moment, { Moment } from "moment"

import deleteIcon from "./../../../../assets/icons/delete.png"
import { Button } from "../../../NewButton/NewButton"

import "antd/dist/antd.css"
import "./work-shedule.scss"

import { TWorkSchedule } from "./types"
import { Translater } from "../../../../config"
import * as Contexts from "../../../../contexts"

export const WorkSchedule = ({
  workingTime,
  valueLang,
  setWorkingTime,
}: TWorkSchedule) => {
  const { language } = useContext(Contexts.LanguageContext)

  const addDate = () => {
    setWorkingTime([
      ...workingTime,
      {
        day: [
          { value: "", lang: "uk" },
          { value: "", lang: "en" },
        ],
        start: "",
        end: "",
      },
    ])
  }

  const onDateTextChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    lang: string
  ) => {
    const newDataDate = [...workingTime]
    const dayIndex = lang === "en" ? 1 : 0
    newDataDate[index].day[dayIndex] = { value: e.target.value, lang }
    setWorkingTime(newDataDate)
  }

  const removeDate = (index: number) => {
    setWorkingTime(workingTime.filter((_, idx) => idx !== index))
  }

  const onTimeFromChange = (
    time: Moment | null,
    timeString: string,
    index: number
  ) => {
    const newDataDate = [...workingTime]
    newDataDate[index].start = timeString
    setWorkingTime(newDataDate)
  }

  const onTimeToChange = (
    time: Moment | null,
    timeString: string,
    index: number
  ) => {
    const newDataDate = [...workingTime]

    newDataDate[index].end = timeString
    console.log("data date ", newDataDate)
    setWorkingTime(newDataDate)
  }

  return (
    <div className="work-shedule-wrapper">
      {workingTime.map((dateItem, index) => (
        <div className="form-container" key={index}>
          {valueLang === "uk" && (
            <div className="form-block">
              <label className="label">
                {Translater.Landings.workSchedule.days[language.slug]}
              </label>
              <input
                className="input"
                placeholder={Translater.Placeholders.example[language.slug]}
                value={dateItem?.day[0]?.value}
                onChange={(e) => onDateTextChange(e, index, "uk")}
              />
            </div>
          )}
          {valueLang === "en" && (
            <div className="form-block">
              <label className="label">
                {Translater.Landings.workSchedule.days[language.slug]}
              </label>
              <input
                className="input"
                placeholder={Translater.Placeholders.example[language.slug]}
                value={dateItem?.day[1]?.value}
                onChange={(e) => onDateTextChange(e, index, "en")}
              />
            </div>
          )}
          <div className="form-block">
            <label className="label">
              {language.slug == "uk" ? "З" : "From"}
            </label>
            <TimePicker
              style={{
                width: "164px",
              }}
              format="HH:mm"
              size="large"
              value={
                dateItem.start ? moment(dateItem.start, "HH:mm") : undefined
              }
              onChange={(time, timeString) =>
                onTimeFromChange(time, timeString, index)
              }
            />
          </div>
          <div className="form-block">
            <label className="label">
              {language.slug == "uk" ? "До" : "To"}
            </label>
            <TimePicker
              style={{
                width: "164px",
              }}
              format="HH:mm"
              size="large"
              value={dateItem.end ? moment(dateItem.end, "HH:mm") : undefined}
              onChange={(time, timeString) =>
                onTimeToChange(time, timeString, index)
              }
            />
          </div>

          {index > 0 && (
            <img
              src={deleteIcon}
              onClick={() => removeDate(index)}
              className="delete-img"
              alt="deleteIcon"
            />
          )}
        </div>
      ))}
      <Button
        buttonTitle={Translater.Buttons.add[language.slug]}
        onClick={addDate}
      />
    </div>
  )
}
