import React, { Fragment, useState, useContext } from "react"

import * as Contexts from "../../../contexts"
import * as Form from "../../../components/Form"

import { Translater } from "../../../config"
import { IConfigBlockProps } from "./types"

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  isValid,
  inputHandler,
}) => {
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.phone[language.slug]}
        text={form.title}
      >
        <Form.Input
          name="phone"
          type={"number"}
          value={form.phone}
          isValid={isValid.phone}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.guests[language.slug]}
      >
        <Form.Input
          name="guests"
          type={"number"}
          value={form.guests}
          isValid={form.guests}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.date[language.slug]}
      >
        {
          <Form.Input
            name="date"
            type={"date"}
            value={form.date}
            inputHandler={inputHandler}
          />
        }
      </Form.LabelField>
    </Fragment>
  )
}

export default ConfigBlock
