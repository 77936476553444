import styled from "styled-components";

export const ProductListWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid #d3d3d3;
  padding: 0 14px 14px 14px;
  overflow: auto;
  max-height: 90%;
`;
export const SearchProduct = styled.label`
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  width: 320px;
  input {
    font-weight: 400;
    outline: none;
    border: 1px solid grey;
    border-radius: 12px;
    height: 44px;
    padding: 4px 8px;
  }
`;
export const LoadButton = styled.button`
  border-radius: 12px;
  min-height: 44px;
  outline: none;
  margin: 20px auto 0;
  width: 220px;
  cursor: pointer;
  border: none;
  background-color: #35b537;
  color: #ffffff;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #2d9b2f;

    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
  &:disabled {
    background-color: #d3d3d3;
  }
`;

export const ProductStyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  li {
    width: 100%;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      padding-bottom: 10px;
      border-bottom: 1px solid #d3d3d3;
    }
    gap: 12px;
    img {
      width: 90px;
      height: 90px;
      border-radius: 12px;
      object-fit: cover;
      cursor: pointer;
    }
  }
`;
export const ProductInfo = styled.div`
  p {
    font-weight: 600;
    cursor: pointer;
  }
  span {
    font-weight: 400;
    display: flex;
    gap: 10px;
    p {
      font-weight: 400;
    }
    .old_price {
      text-decoration: line-through;
    }
  }
`;
export const AddButton = styled.button`
  border-radius: 12px;
  min-height: 44px;
  outline: none;

  padding: 12px;
  cursor: pointer;
  border: none;
  background-color: #35b537;
  color: #ffffff;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-left: auto;
  &:hover {
    background-color: #2d9b2f;

    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
  &:disabled {
    background-color: #d3d3d3;
    cursor: default;
  }
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 16px;
`;
