import React, { useState, useEffect, useContext, ChangeEvent } from "react"

import * as Page from "../../components/Page"
import * as Contexts from "../../contexts"
import { sortByOptions } from "./components/static"
import { Data, IProduct } from "../../types/items"
import { ConfigBlock } from "./components"

import { useHttp } from "../../hooks"

import { config, Translater } from "../../config"

import { IOrder, IPagination, IFilter, TSortBy } from "../../types/items"

export type TGenerateURL = <T = {}>(url: string, query: T) => string

export const generateURL: TGenerateURL = (url, query) =>
  `${url}?${new URLSearchParams(
    // @ts-ignore
    Object.entries(query).map(([key, value]) => [key, value.toString()])
  ).toString()}`

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { totalCount, request } = useHttp()
  const [defaultState, setDefaultState] = useState<IOrder[]>([])
  const [isLoading, toogleLoading] = useState<boolean>(true)
  const [GETLoading, toogleGetLoading] = useState<boolean>(false)
  const [reGET, setReGET] = useState<boolean>(false)
  const [localSearch, setLocalSearch] = useState<Data>({
    field: "user",
    value: "",
  })
  const [search, setSearch] = useState<Data>({
    field: "user",
    value: "",
  })
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  })
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "createdAt",
    sortOrder: "desc",
    dateFrom: "",
    dateTo: "",
  })

  const [primary, setPrimary] = useState<IOrder[]>([])
  const [xslsData, setXSLSData] = useState<any>()

  const Events = {
    OnClear: () => {
      setPrimary(defaultState)
      setSearch({ field: "user", value: "" })
      setLocalSearch({ field: "user", value: "" })
      setFilter({ ...filter, dateFrom: "", dateTo: "", sortBy: "createdAt" })
      setPagination({ ...pagination, page: 1, perPage: 10 })
    },
    inputHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilter({ ...filter, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      toogleGetLoading(true)
      setPagination({ ...pagination, page: 1, perPage: +e.target.value })
    },
    sortHandler: (sortBy: TSortBy) => {
      console.log(sortBy)
      let sortOrder: "asc" | "desc"
      if (filter.sortOrder === "asc") sortOrder = "desc"
      else sortOrder = "asc"

      setFilter({ ...filter, sortBy, sortOrder })
      setPagination({ ...pagination, page: 1 })
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index })
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    },
    sortByHandler: (e: any) => {
      toogleGetLoading(true)
      setFilter({ ...filter, [e.target.name]: e.target.value })
    },
  }

  const getSearchKey = (): string => {
    const { field, value } = search
    const key = `${value.length ? `&field=${field}&query=${value}` : ""}`
    return key
  }

  const GetSXLS = (responseSXLS: IOrder[]): IOrder[] => {
    const Arr: any[] = []
    let sum: number = 0
    // let products: string

    responseSXLS.forEach((resp: IOrder) => {
      let products = ""
      resp.items.forEach((rsp: any) => {
        products += `${
          rsp?.product?.name?.find((item: any) => item.lang === language.slug)
            ?.value
        }`
      })

      Arr.push({
        username: resp.customer?.name,
        date: new Date(resp.createdAt as string).toLocaleDateString(),
        items: products,
        price:
          resp?.itemsPrice +
          "грн" +
          "(" +
          (resp?.paymentType === "cash" ? "Наличные" : "Онлайн") +
          ")",
      })
      sum += resp.itemsPrice
    })
    Arr.push({
      sumOfAllOrders: sum,
      countOrders: Arr.length,
    })
    return Arr
  }

  const Callbacks = {
    Search: async () => {
      try {
        const { page, perPage } = pagination
        const url =
          generateURL(`${config.API}/orders/search`, {
            page,
            perPage,
            sortOrder: filter.sortOrder,
            sortBy: filter.sortBy,
          }) +
          `&createdAt[]=${filter.dateFrom ? filter.dateFrom : ""}&createdAt[]=${
            filter.dateTo ? filter.dateTo : ""
          }${getSearchKey()}`

        const response: IOrder[] = await request(
          url,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )
        const Arr: any[] = GetSXLS(response)

        if (response) {
          setXSLSData(Arr)
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      } finally {
        toogleGetLoading(false)
      }
    },

    GetReload: async () => {
      try {
        const { page, perPage } = pagination
        const url =
          generateURL(`${config.API}/orders`, {
            page,
            perPage,
            sortOrder: filter.sortOrder,
            sortBy: filter.sortBy,
          }) +
          `&createdAt[]=${filter.dateFrom ? filter.dateFrom : ""}&createdAt[]=${
            filter.dateTo ? filter.dateTo : ""
          }`
        const response: IOrder[] = await request(
          url,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )
        if (response) {
          setDefaultState(response)
        }
      } catch (e) {
        console.log(e)
      }
    },

    Fetch: async () => {
      try {
        const { page, perPage } = pagination
        const url =
          generateURL(`${config.API}/orders`, {
            page,
            perPage,
            sortOrder: filter.sortOrder,
            sortBy: filter.sortBy,
          }) +
          `&dateFrom=${filter.dateFrom ? filter.dateFrom : ""}&dateTo=${
            filter.dateTo ? filter.dateTo : ""
          }`

        const response: IOrder[] = await request(
          url,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )

        const responseSXLS: IOrder[] = await request(
          `${url}&createdAt[]=${filter.dateFrom}&createdAt[]=${filter.dateTo}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        const Arr: any[] = GetSXLS(responseSXLS)

        if (response) {
          console.log(response, "response")
          setXSLSData(Arr)
          setPrimary(response)
        }
      } catch (e) {
        console.log(e)
      } finally {
        toogleLoading(false)
        toogleGetLoading(false)
      }
    },
  }

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  useEffect(() => {
    if (!(search.field.length && search.value.length)) {
      Callbacks.Fetch()
    }
    Callbacks.GetReload()
  }, [filter, pagination.page, pagination.perPage])

  useEffect(() => {
    if (search.field.length && search.value.length) {
      Callbacks.Search()
      toogleGetLoading(true)
    }
  }, [search, filter, pagination])

  useEffect(() => {
    if (reGET) {
      setReGET(false)
      setXSLSData(GetSXLS(defaultState))
      setPrimary(defaultState)
    }
  }, [reGET])

  if (isLoading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper footer title={Translater.OrdersPage.title[language.slug]}>
      <Page.Header title={Translater.OrdersPage.title[language.slug]} />

      <Page.Search
        setData={setSearch}
        setReGET={setReGET}
        setLocalSearch={setLocalSearch}
        localSearch={localSearch}
      />

      <div className="flex">
        <Page.Filter
          agePicker
          form={filter}
          events={Events}
          label={Translater.Items.filterByDate[language.slug]}
        />

        <Page.SortBar
          perPage={pagination.perPage}
          filter={filter}
          selectHandler={Events.selectHandler}
          sortByHandler={Events.sortByHandler}
          sortByOptions={sortByOptions}
        />
      </div>

      <ConfigBlock
        primary={primary}
        pagination={pagination}
        Events={Events}
        xslsData={xslsData}
      />

      <Page.Footer>
        <Page.Pagination
          linkTo="/orders"
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          setPagination={setPagination}
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default IndexPage
