import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import * as Contexts from "../../contexts";
import { useHttp } from "../../hooks";
import { Translater, config } from "../../config";
import { generateFormData } from "../../helpers/generateFormData";

import { TFilteredData } from "./components/Events/types";

import { Button } from "../NewButton/NewButton";
import { Preloader } from "../Page";
import { InputWithTags } from "../InputWithTags";

import { Content, Events, ModalLending, WorkSchedule } from "./components";
import { TabLanguage } from "../TabLanguage/TabLanguage";

import {
  DateItem,
  TConcertsList,
  TDataLanding,
  TDescriptionData,
  TFormData,
  TLending,
} from "./types";
import "./lending.scss";

export const Lending = ({ type }: TLending) => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { isActive } = useContext(Contexts.SidebarContext);

  const { request, totalCount } = useHttp();

  const [formData, setFormData] = useState<TFormData>({
    headerPhoto: "",
    mainImage: "",
  });
  const [photos, setPhotos] = useState<File[]>([]);
  const [typeModal, setTypeModal] = useState<string>("");
  const [dataLanding, setDataLending] = useState<TDataLanding>();
  const [tabId, setTabId] = useState<string>("1");
  const [workingTime, setWorkingTime] = useState<DateItem[]>([
    {
      day: [
        { value: "", lang: "uk" },
        { value: "", lang: "en" },
      ],
      start: "",
      end: "",
    },
  ]);
  const [response, setResponse] = useState("");
  const [concertsList, setConcertsList] = useState<TConcertsList[]>([]);
  const [currentConcert, setCurrentConcert] = useState<TConcertsList | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [filePdfData, setFilePdf] = useState<File | string>();
  const [filterData, setFilterData] = useState<TFilteredData>({
    page: 0,
    byPage: 10,
    title: "",
    dateFrom: new Date("2022-01-01"),
    dateTo: "",
  });
  const [descriptionData, setDescriptionData] = useState<TDescriptionData>({
    descriptionUk: "",
    descriptionEn: "",
  });
  const [valueLang, setValueLang] = useState<string>(language.slug);

  const [errors, setErrors] = useState({
    descriptionUk: "",
    descriptionEn: "",
  });

  const [metaKeywords, setMetaKeywords] = useState<string[]>([]);
  const [metaDescription, setMetaDescription] = useState<string>("");

  const { id, concertId } = useParams<{ id: string; concertId: string }>();
  const filePdf = useRef<HTMLInputElement>(null);

  const validateFields = () => {
    let isValid = true;
    const newErrors = {
      descriptionUk: "",
      descriptionEn: "",
    };

    if (descriptionData.descriptionUk.trim() === "") {
      newErrors.descriptionUk = "Це поле є обов’язковим для Ua та En.";
      isValid = false;
    }

    if (descriptionData.descriptionEn.trim() === "") {
      newErrors.descriptionEn = "Це поле є обов’язковим для Ua та En.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const getLanding = async (idLanding: string) => {
    try {
      const response = await request(
        `${config.serverURL}api/landings/${idLanding}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token as string}`,
        }
      );
      return setDataLending(response);
    } catch (e) {}
  };

  const changeFilePdf = (e: any) => {
    const input = e.target as HTMLInputElement;

    const newFile = input.files ? input.files[0] : null;

    if (newFile) {
      setFilePdf(newFile);
    }
  };

  const getConcerts = async (
    page = 0,
    limit = 10,
    title = "",
    dateFrom = new Date()
  ) => {
    const dateFromResult =
      filterData?.dateTo === "archive"
        ? new Date("2022-01-01").toISOString()
        : filterData?.dateTo === "soon"
        ? new Date().toISOString()
        : new Date(
            moment(dateFrom ? dateFrom : new Date("2022-01-01")).format(
              "YYYY-MM-DD"
            )
          ).toISOString();
    const dateToResult =
      filterData?.dateTo === "archive" ? new Date().toISOString() : "";
    try {
      const response = await request(
        `${config.API}/concerts/?page=${
          page + 1
        }&perPage=${limit}&title=${title}&dateFrom=${dateFromResult}&dateTo=${dateToResult}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token as string}`,
        },
        true
      );
      setConcertsList(response);
    } catch (e) {
    } finally {
      setResponse("getConcert");
    }
  };

  const editLending = async () => {
    if (!validateFields()) return;
    setLoading(true);
    const dataObject = {
      description: [
        {
          value: descriptionData.descriptionUk,
          lang: "uk",
        },
        {
          value: descriptionData.descriptionEn,
          lang: "en",
        },
      ],
      ...(type !== "libraria" ? { workingTime, mainSlider: photos } : {}),
      headerPhoto: formData.headerPhoto,
      ...(filePdfData && typeof filePdfData !== "string"
        ? { offlineMenu: filePdfData }
        : {}),
      mainImage: formData.mainImage,
      metaKeywords,
      metaDescription,
    };

    const imageProps = [
      "mainSlider",
      "headerPhoto",
      "mainImage",
      "offlineMenu",
    ];

    try {
      const formData = await generateFormData(dataObject, imageProps);

      await request(
        `${config.API}/landings/${dataLanding?._id}`,
        "PUT",
        formData,
        {
          Authorization: `Bearer ${token as string}`,
        }
      );
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const getConcert = async (concertId: string) => {
    try {
      const response = await request(
        `${config.API}/concerts/${concertId}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token as string}`,
        }
      );
      return setCurrentConcert(response);
    } catch (e) {}
  };

  const deleteConcert = async (id?: string) => {
    try {
      await request(`${config.API}/concerts/${id}`, "DELETE", null, {
        Authorization: `Bearer ${token as string}`,
      });
    } catch (e) {
    } finally {
      setTypeModal("");
      setResponse("deleteConcert");
    }
  };

  useEffect(() => {
    if (id) {
      getLanding(id);
    }
  }, [id]);

  useEffect(() => {
    if (dataLanding) {
      setWorkingTime(dataLanding?.workingTime);
      setFormData({
        headerPhoto: `${config.serverURL}${dataLanding?.headerPhoto}`,
        mainImage: `${config.serverURL}${dataLanding?.mainImage}`,
      });
      setMetaKeywords(dataLanding?.metaKeywords);
      setMetaDescription(dataLanding?.metaDescription);
      setFilePdf(dataLanding.offlineMenu);
      setDescriptionData({
        descriptionUk: dataLanding?.description[0]?.value,
        descriptionEn: dataLanding?.description[1]?.value,
      });
      setPhotos(
        dataLanding?.mainSlider.map(
          (el: string) => `${config.serverURL}${el}`
        ) as any
      );
    }
  }, [dataLanding]);

  useEffect(() => {
    if (
      response === "createConcert" ||
      response === "editConcert" ||
      response === "deleteConcert"
    ) {
      setCurrentConcert(null);
      getConcerts(
        filterData.page,
        filterData.byPage,
        filterData.title,
        filterData.dateFrom as Date
      );
    }
  }, [response]);

  useEffect(() => {
    if (concertId && concertId !== "all") {
      setTabId("2");
      setTypeModal("update");
      getConcert(concertId);
    }
  }, [concertId]);

  return (
    <div className={`wrapper ${isActive && "shorter"}`}>
      {!loading ? (
        <>
          <div className="lending-header">
            <h1 className="title">
              {language.slug == "uk"
                ? dataLanding?.title[1]?.value
                : dataLanding?.title[0]?.value}
            </h1>
            {tabId === "1" ? (
              <Button
                onClick={editLending}
                buttonTitle={Translater.Buttons.update[language.slug]}
              />
            ) : (
              <Button
                onClick={() => setTypeModal("create")}
                buttonTitle={Translater.Buttons.create[language.slug]}
              />
            )}
          </div>
          {type === "libraria" && (
            <div className="tabs-wrapper">
              <div
                onClick={() => setTabId("1")}
                className={`tab-block ${tabId === "1" ? "active" : ""}`}
              >
                <span className="tab-text">
                  {Translater.Landings.landing.content[language.slug]}
                </span>
              </div>
              <div
                onClick={() => setTabId("2")}
                className={`tab-block ${tabId === "2" ? "active" : ""}`}
              >
                <span className="tab-text">
                  {Translater.Landings.landing.actions[language.slug]}
                </span>
              </div>
            </div>
          )}
          {tabId === "1" ? (
            <>
              <div className="lending-main">
                <div className="main-header-block">
                  <h2 className="title-h2">
                    {Translater.Landings.landing.aboutUs[language.slug]}
                  </h2>
                  <TabLanguage
                    valueLang={valueLang}
                    setValueLang={setValueLang}
                  />
                </div>
                <Content
                  errors={errors}
                  valueLang={valueLang}
                  descriptionData={descriptionData}
                  setFormData={setFormData}
                  formData={formData}
                  photos={photos}
                  type={type}
                  setPhotos={setPhotos}
                  setDescriptionData={setDescriptionData}
                />
                {type !== "libraria" && (
                  <>
                    <h2 className="title-h2">
                      {Translater.Landings.landing.schedule[language.slug]}
                    </h2>
                    <WorkSchedule
                      valueLang={valueLang}
                      workingTime={workingTime}
                      setWorkingTime={setWorkingTime}
                    />
                    <div className="ofline-menu">
                      <h2 className="title-h2">
                        {Translater.Landings.landing.offlineMenu[language.slug]}
                      </h2>
                      <div className="file-block">
                        <span className="file-text">
                          {
                            Translater.Landings.landing.offlineMenu[
                              language.slug
                            ]
                          }
                          {typeof filePdfData === "string" ? (
                            <a
                              className="file-bold"
                              href={`${config.serverURL}${filePdfData}`}
                              target="_blank"
                            >
                              ({`${filePdfData.split("/").pop()}`})
                            </a>
                          ) : (
                            <span className="file-bold">
                              ({`${filePdfData?.name ?? "pdf"}`})
                            </span>
                          )}
                        </span>
                        <input
                          className="file-input"
                          type="file"
                          accept=".pdf"
                          placeholder={
                            Translater.Placeholders.pickFile[language.slug]
                          }
                          onChange={changeFilePdf}
                          ref={filePdf}
                        />
                      </div>
                    </div>
                  </>
                )}
                <InputWithTags
                  tags={metaKeywords}
                  setTags={setMetaKeywords}
                  descriptionValue={metaDescription}
                  setDescriptionValue={setMetaDescription}
                />
              </div>
            </>
          ) : (
            <Events
              totalCount={totalCount}
              deleteConcert={deleteConcert}
              setCurrentConcert={setCurrentConcert}
              setTypeModal={setTypeModal}
              concertsList={concertsList}
              getConcerts={getConcerts}
              filterData={filterData}
              setFilterData={setFilterData}
            />
          )}
          {typeModal && (
            <ModalLending
              setCurrentConcert={setCurrentConcert}
              deleteConcert={deleteConcert}
              setResponse={setResponse}
              currentConcert={currentConcert}
              setTypeModal={setTypeModal}
              typeModal={typeModal}
            />
          )}
          <div className="line"></div>
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
};
