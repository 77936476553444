import React from 'react'
import { IImageButtonProps } from './types'


const ImageButton: React.FC<IImageButtonProps> = ({
  image,
  buttonHandler
}) => {

  return (
    <button
      className='btn btn--image'
      onClick={buttonHandler}
    >
      {image}
    </button>
  )
}

export default ImageButton