import { Translater } from "config";
import * as Page from "components/Page";
import * as Contexts from "../../contexts";
import React, { useState, useContext, ChangeEvent, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CreatePageTopWrap, MainWrapper, TitleLink } from "./styled";
import { ButtonsWrap, CreateButton } from "pages/Variations/styled";
import { IoIosArrowBack } from "react-icons/io";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TCreateFormTypes } from "./types";
import { ChangeLang } from "./components/ChangLang";
import { MainInfo } from "./components/MainInfo";
import { PhotoInfoWrap } from "./components/PhotoInfoWrap/PhotoInfoWrap";
import { notification } from "antd";
import {
  createProduct,
  createProductData,
  setDefaultValue,
  updateProduct,
  useGetCurrentProduct,
} from "./helpers";

const CreatePage = () => {
  const { id } = useParams<{ id?: string }>();
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);
  const history = useHistory();
  const methods = useForm<TCreateFormTypes>({
    mode: "all",
    defaultValues: {
      currentlang: "uk",
      variations: [],
      novelty: {
        label: Translater.TableTitles.hidden.false[language.slug],
        value: "no",
      },
      hidden: {
        label: Translater.TableTitles.hidden.false[language.slug],
        value: "no",
      },
    },
  });

  const { product } = useGetCurrentProduct(id);

  useEffect(() => {
    if (product) {
      setDefaultValue(product, methods.reset, language.slug);
    }
  }, [language.slug, methods.reset, product]);

  const onSubmit: SubmitHandler<TCreateFormTypes> = async (data) => {
    const hasEmptyFields = data.variations.some((variation) =>
      variation.items.some((item) => !item.price || !item.weight)
    );
    if (hasEmptyFields) {
      return notification.error({
        message:
          Translater.TableTitles.enterAllFieldInVariations[language.slug],
      });
    }
    if (
      !data.nameEN ||
      !data.nameUK ||
      (data.descriptionEN && !data.descriptionUK) ||
      (!data.descriptionEN && data.descriptionUK)
    ) {
      return notification.error({
        message: Translater.Buttons.fillTheDataAllLanguages[language.slug],
      });
    }

    try {
      const { formData } = createProductData(data);
      if (id) {
        await updateProduct({ id, token: token || "", data: formData });
        notification.success({
          message: Translater.Buttons.successfullyUpdated[language.slug],
        });
      } else {
        await createProduct(formData, token || "");
        notification.success({
          message: Translater.Buttons.successfullyCreated[language.slug],
        });
      }

      history.push("/products");
    } catch (error) {
      notification.error({
        message: Translater.Buttons.errorFormCreated[language.slug],
      });
    }
  };

  return (
    <Page.Wrapper
      title={
        !id
          ? Translater.ProductsCreatePage.title[language.slug]
          : Translater.ReferalsDetailPage.title[language.slug]
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CreatePageTopWrap>
            <TitleLink to="/products">
              <IoIosArrowBack />
              {!id
                ? Translater.ProductsCreatePage.title[language.slug]
                : Translater.ReferalsDetailPage.title[language.slug]}
            </TitleLink>
            <ButtonsWrap>
              <CreateButton type="submit">
                {!id
                  ? Translater.Buttons.create[language.slug]
                  : Translater.Buttons.update[language.slug]}
              </CreateButton>
            </ButtonsWrap>
          </CreatePageTopWrap>
          <ChangeLang
            currentLang={methods.watch("currentlang")}
            onLangClick={(l) => methods.setValue("currentlang", l)}
          />
          <MainWrapper>
            <MainInfo />
            <PhotoInfoWrap />
          </MainWrapper>
        </form>
      </FormProvider>
    </Page.Wrapper>
  );
};

export default CreatePage;
