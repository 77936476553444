import { Translater } from "config";
import * as Contexts from "contexts";
import { TVariation, TVariationItemTypes } from "pages/Variations/types";
import React, { useContext, useState } from "react";

import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {
  BottomWrap,
  ButtonAddBlock,
  ParamsWrap,
  SingleBlock,
  SubVariationWrap,
  SubItemsWrap,
  SubParamsWrap,
  SubTitle,
  VariationItemStyled,
  TitleWrap,
} from "./styled";
import { useFormContext } from "react-hook-form";
import { TCreateFormTypes } from "pages/Products/types";
import { useFieldArray } from "react-hook-form";
import { Input } from "components/Input";
import { RoundCheckbox } from "../RoundCheckbox/RoundCheckBox";
import { notification } from "antd";
type TProps = { variation: TVariation };

export const VariationItem = ({ variation }: TProps) => {
  const [open, setIsopen] = useState(false);
  const toggleOpen = () => setIsopen((prev) => !prev);

  const {
    register,
    watch,
    setValue,
    trigger,
    control,
    getValues,
    formState: { errors },
  } = useFormContext<TCreateFormTypes>();
  const { append, remove } = useFieldArray({
    control,
    name: "variations",
  });

  const { language } = useContext(Contexts.LanguageContext);

  const indexInForm = watch("variations")?.findIndex(
    (el) => el.id === variation._id
  );

  const { append: appendSubItem, remove: removeSubItem } = useFieldArray({
    control,
    name: indexInForm !== -1 ? `variations.${indexInForm}.items` : "variations",
  });

  const required = Translater.Placeholders.requiredFields[language.slug];

  const isElementInForm = indexInForm !== -1;

  const buttonTitle = isElementInForm
    ? Translater.Buttons.delete[language.slug]
    : Translater.Buttons.addToPopular[language.slug];

  const mainTitle = variation.title?.find(
    (el) => el.lang === language.slug
  )?.value;

  const addToForm = () => {
    !open && toggleOpen();
    append({
      id: variation._id,
      title: variation.title,
      items: variation?.items?.map((el, index) => ({
        default: index === 0,
        id: el._id,
        guid: el.guid,
        weight: index === 0 ? "0" : "",
        price: index === 0 ? "0" : "",
        name: el.name,
      })),
    });
  };

  const deleteFromForm = () => {
    remove(indexInForm);
  };

  const onAddedClick = (
    isInCart: boolean,
    index: number,
    item: TVariationItemTypes
  ) => {
    if (isInCart) {
      if (watch(`variations.${indexInForm}.items.${index}.default`)) {
        notification.info({
          message:
            Translater.TableTitles.canNotDeleteDefaultValue[language.slug],
        });
      } else {
        removeSubItem(index);
      }
    } else {
      appendSubItem({
        default: false,
        id: item._id,
        guid: item.guid,
        weight: "",
        price: "",
        name: item.name,
      });
    }
  };

  return (
    <VariationItemStyled $open={open}>
      <SingleBlock>
        <p>{mainTitle}:</p>
        <ButtonAddBlock $inForm={isElementInForm} $open={open}>
          <button
            onClick={() => (isElementInForm ? deleteFromForm() : addToForm())}
            type="button"
          >
            {buttonTitle}
          </button>
          <MdOutlineKeyboardArrowDown onClick={toggleOpen} />
        </ButtonAddBlock>
      </SingleBlock>
      {open && (
        <SubItemsWrap>
          {variation.items?.map((el) => {
            const subIndex =
              indexInForm !== -1
                ? watch("variations")?.[indexInForm]?.items?.findIndex(
                    (item) => item.guid === el.guid
                  )
                : -1;

            const title = el.name?.find(
              (item) => item.lang === language.slug
            )?.value;

            const invalidIndex = subIndex === undefined || subIndex === -1;
            const isDefault = watch(
              `variations.${indexInForm}.items.${subIndex}.default`
            );
            const onClickDefault = async (subIndex: number) => {
              if (subIndex !== -1) {
                const items = getValues(`variations.${indexInForm}.items`);
                items.forEach((item, index) => {
                  setValue(
                    `variations.${indexInForm}.items.${index}.default`,
                    index === subIndex
                  );
                  setValue(
                    `variations.${indexInForm}.items.${index}.weight`,
                    index === subIndex ? "0" : item.weight
                  );
                  setValue(
                    `variations.${indexInForm}.items.${index}.price`,
                    index === subIndex ? "0" : item.price
                  );
                });
                await trigger(`variations.${indexInForm}.items`);
              }
            };

            return (
              <SubVariationWrap key={el._id} $inForm={isElementInForm}>
                <ParamsWrap>
                  <TitleWrap>
                    <SubTitle>{title}</SubTitle>
                    {isElementInForm && (
                      <BottomWrap>
                        <RoundCheckbox
                          onClick={() =>
                            onAddedClick(!invalidIndex, subIndex, el)
                          }
                          value={!invalidIndex}
                          title={
                            !invalidIndex
                              ? Translater.TableTitles.added[language.slug]
                              : Translater.TableTitles.add[language.slug]
                          }
                        />
                        <RoundCheckbox
                          onClick={() => onClickDefault(subIndex)}
                          value={isDefault}
                          title={"По замовчуванню"}
                        />
                      </BottomWrap>
                    )}
                  </TitleWrap>
                  {subIndex !== -1 && (
                    <SubParamsWrap>
                      <Input
                        type="number"
                        $error={
                          errors.variations?.[indexInForm]?.items?.[subIndex]
                            ?.weight?.message
                        }
                        {...register(
                          `variations.${indexInForm}.items.${subIndex}.weight`,
                          { required }
                        )}
                        placeholder="150"
                        disabled={invalidIndex}
                        width="140px"
                        label={Translater.TableTitles.g[language.slug]}
                      />
                      <Input
                        type="number"
                        $error={
                          errors.variations?.[indexInForm]?.items?.[subIndex]
                            ?.price?.message
                        }
                        {...register(
                          `variations.${indexInForm}.items.${subIndex}.price`,
                          { required }
                        )}
                        placeholder="198"
                        disabled={invalidIndex}
                        width="140px"
                        label={`${
                          Translater.OptionsForProductsPage.error.price[
                            language.slug
                          ]
                        } ₴`}
                      />
                    </SubParamsWrap>
                  )}
                </ParamsWrap>
              </SubVariationWrap>
            );
          })}
        </SubItemsWrap>
      )}
    </VariationItemStyled>
  );
};
