import React, { useState, SyntheticEvent } from "react";

import "./inputWithTags.scss";
import { TInputWithTags } from "./types";

export const InputWithTags = ({
  tags,
  setTags,
  descriptionValue,
  setDescriptionValue,
}: TInputWithTags) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setInputValue(target.value);
  };

  const textareaChangeHandler = (event: SyntheticEvent) => {
    const target = event.target as HTMLTextAreaElement;
    setDescriptionValue(target.value);
  };

  const handleInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === " " && inputValue.trim()) {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    } else if (event.key === "Backspace" && !inputValue) {
      setTags(tags.slice(0, tags.length - 1));
    }
  };

  const handleInputKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter" && inputValue.trim()) {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <div>
      <div className="container">
        <label className="label-meta">Meta keywords</label>
        <div className={`input-with-tags ${isFocused ? "focused" : ""}`}>
          <div className="tags">
            {tags.map((tag, index) => (
              <div className="tag" key={index}>
                {tag}
                <span
                  className="tag-close-icon"
                  onClick={() => {
                    setTags([
                      ...tags.slice(0, index),
                      ...tags.slice(index + 1),
                    ]);
                  }}
                >
                  &#10006;
                </span>
              </div>
            ))}
          </div>
          <input
            type="text"
            placeholder="Enter tags"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            onKeyPress={handleInputKeyPress}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
        </div>
      </div>
      <div className="container">
        <label className="label-meta">Meta description</label>
        <textarea
          value={descriptionValue}
          onChange={textareaChangeHandler}
          placeholder="Enter meta description"
          className="textarea-metaDescription"
        ></textarea>
      </div>
    </div>
  );
};
