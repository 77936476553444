import React from 'react'

import { IModalDataProps } from '../../../types/contexts/modal'
import { DetailBlock } from '../../../pages/Orders/components'

//TODO must be new logic of reloading
const StatusModal: React.FC<IModalDataProps> = ({
  data,
  reloadHandler
}) => {

  return (
    <DetailBlock
      data={(data as any)}
      reloadHandler={reloadHandler}
    />
  )
}

export default StatusModal