import React, { useContext } from "react";

import * as Contexts from "../../../../contexts";
import * as Form from "../../../../components/Form";
import { config, Translater } from "../../../../config";
import { IProductCardProps } from "./types";
import { IProduct } from "../../../../types/items";
import { getStringFromTranslationsArr } from "../../../../helpers";

const ProductCard: React.FC<IProductCardProps> = ({ data }) => {
  const { language } = useContext(Contexts.LanguageContext);

  const additionSum = data?.additions?.reduce(
    (accumulator: any, currentValue: any) => accumulator + currentValue.price,
    0
  );

  return (
    <div className="block-container border">
      <h2 className="block-title">
        {
          (data?.product as IProduct).name
            .map((item) => {
              if (item.lang === language.slug) {
                return item.value;
              }
            })
            .filter((item) => item)[0]
        }
      </h2>

      <Form.LabelField
        label={Translater.TableTitles.photo[language.slug]}
        fontSize="small"
      >
        <img
          alt="Product"
          src={`${config.imageProxy}public/${data?.product?.photo}`}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
        text={
          (data?.product as IProduct).name
            .map((item) => {
              if (item.lang === language.slug) {
                return item.value;
              }
            })
            .filter((item) => item)[0]
        }
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.description[language.slug]}
        text={
          data?.product?.description
            ? (data?.product as IProduct)?.description
                .map((item) => {
                  if (item.lang === language.slug) {
                    return item.value;
                  }
                })
                .filter((item) => item)[0]
            : "-"
        }
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.options[language.slug]}
        text={data.additions
          .map(
            (addition: any) =>
              `${getStringFromTranslationsArr(
                addition?.addition?.name,
                language.slug
              )}: x${addition?.count} пор. `
          )
          .join(", ")}
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.amount[language.slug]}
        text={`${data.count}`}
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.price[language.slug]}
        text={`${data.price} грн`}
      />
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.sum[language.slug]}
        text={`${data?.count * +data.price + additionSum} грн`}
      />
    </div>
  );
};

export default ProductCard;
