import React, { FC, useContext, useEffect, useState } from "react"
import * as Form from "../../components/Form"
import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Buttons from "../../components/Buttons"
import { Translater, config } from "../../config"
import { InputWithTags } from "../../components/InputWithTags"
import { TSeo } from "../../types/items"
import { useHttp } from "../../hooks"
import { Snackbar } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"

export const SeoPage: FC = () => {
  const { language } = useContext(Contexts.LanguageContext)
  const { token } = useContext(Contexts.AuthContext)

  const { request } = useHttp()

  const [metaKeywords, setMetaKeywods] = useState<string[]>([])
  const [metaDescription, setMetaDescription] = useState<string>("")
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: TSeo = await request(
          `${config.API}/variables`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )
        if (response) {
          response.metaDescription &&
            setMetaDescription(response.metaDescription)
          response.metaKeywords && setMetaKeywods(response.metaKeywords)
        }
      } catch (e) {}
    },
    updateSeo: async () => {
      try {
        const data = new FormData()

        data.append("metaKeywords", JSON.stringify(metaKeywords))
        data.append("metaDescription", JSON.stringify(metaDescription))

        toogleIsAlertOpen(true)

        await request(
          `${config.API}/variables`,
          "PUT",
          { metaDescription, metaKeywords },
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        )
      } catch (e) {}
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  return (
    <Page.Wrapper>
      <div style={{ width: "60%" }}>
        <label className="label-meta">
          {Translater.TableTitles.title[language.slug]}
        </label>
        <Form.Input
          name="title"
          value={Translater.SEO.mainTab[language.slug]}
          inputHandler={() => {}}
        />

        <InputWithTags
          tags={metaKeywords}
          setTags={setMetaKeywods}
          descriptionValue={metaDescription}
          setDescriptionValue={setMetaDescription}
        />

        <Buttons.DefaultButton
          title={Translater.Buttons.save[language.slug]}
          buttonHandler={Callbacks.updateSeo}
        />
        <Snackbar
          open={isAlertOpen}
          autoHideDuration={10000}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          onClose={() => toogleIsAlertOpen(false)}
        >
          <Alert severity="success">
            <p>{Translater.SEO.updated[language.slug]}</p>
          </Alert>
        </Snackbar>
      </div>
    </Page.Wrapper>
  )
}
