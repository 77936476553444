import React, { useContext, useEffect, useState } from "react"
import { DatePicker, TimePicker } from "antd"
import moment from "moment"
import "antd/dist/antd.css"

import closeModal from "./../../../../assets/icons/close.svg"

import { PhotoPicker } from "../../../PhotoPicker"
import { generateFormData } from "../../../../helpers/generateFormData"
import { TabLanguage } from "../../../TabLanguage/TabLanguage"
import { Modal } from "../../../NewModal"
import * as Contexts from "../../../../contexts"
import { useHttp } from "../../../../hooks"
import { Translater, config } from "../../../../config"

import { TFormData, TModalLending } from "./type"
import "./modalLending.scss"

export const ModalLending = ({
  typeModal,
  setTypeModal,
  currentConcert,
  setCurrentConcert,
  setResponse,
  deleteConcert,
}: TModalLending) => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { request } = useHttp()

  const [formData, setFormData] = useState<TFormData>({
    descriptionUk: "",
    descriptionEn: "",
    titleUk: "",
    price: 1,
    seats: 1,
    titleEn: "",
    date: "",
    time: "",
  })
  const [errors, setErrors] = useState({
    titleUk: "",
    titleEn: "",
    descriptionUk: "",
    descriptionEn: "",
  })
  const [fileData, setFileData] = useState<File | string>()
  const [valueLang, setValueLang] = useState<string>(language.slug)

  const handleFileChange = (file: File) => {
    setFileData(file)
  }

  const validateFields = () => {
    let isValid = true
    const newErrors = {
      titleUk: "",
      titleEn: "",
      descriptionUk: "",
      descriptionEn: "",
    }

    if (formData.titleUk.trim() === "") {
      newErrors.titleUk = "Це поле є обов’язковим для Ua та En."
      isValid = false
    }

    if (formData.titleEn.trim() === "") {
      newErrors.titleEn = "Це поле є обов’язковим для Ua та En."
      isValid = false
    }

    if (formData.descriptionUk.trim() === "") {
      newErrors.descriptionUk = "Це поле є обов’язковим для Ua та En."
      isValid = false
    }

    if (formData.descriptionEn.trim() === "") {
      newErrors.descriptionEn = "Це поле є обов’язковим для Ua та En."
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  const handleInputChange = (event: any, type?: string) => {
    const { name, value } = event.target
    if ((type === "number" && value === "") || value === "0") {
      // If input type is number and value is empty, set value to 1
      setFormData({ ...formData, [name]: 1 })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const handleDateChange = (date: moment.Moment | null, dateString: string) => {
    setFormData({ ...formData, date: dateString })
  }

  const handleTimeChange = (time: moment.Moment | null, timeString: string) => {
    setFormData({ ...formData, time: timeString })
  }

  const combinedDateTimeString = `${formData.date} ${formData.time}`
  const combinedDateTime = moment(combinedDateTimeString, "YYYY-MM-DD HH:mm")
  const isoDateTime = combinedDateTime.toISOString()

  const dataObject = {
    description: [
      {
        value: formData.descriptionUk,
        lang: "uk",
      },
      {
        value: formData.descriptionEn,
        lang: "en",
      },
    ],
    title: [
      {
        value: formData.titleUk,
        lang: "uk",
      },
      {
        value: formData.titleEn,
        lang: "en",
      },
    ],
    mainImage: fileData,
    seats: +formData.seats,
    price: +formData.price,
    date: new Date(isoDateTime).toISOString(),
  }

  const imageProps = ["mainImage"]

  const createConcert = async () => {
    if (!validateFields()) return
    try {
      const formData = await generateFormData(dataObject, imageProps)

      await request(`${config.API}/concerts`, "POST", formData, {
        Authorization: `Bearer ${token as string}`,
      })
    } catch (e) {
    } finally {
      setTypeModal("")
      setResponse("createConcert")
    }
  }

  const updateConcert = async () => {
    if (!validateFields()) return
    try {
      const formData = await generateFormData(dataObject, imageProps)

      await request(
        `${config.API}/concerts/${currentConcert?._id}`,
        "PUT",
        formData,
        {
          Authorization: `Bearer ${token as string}`,
        }
      )
    } catch (e) {
    } finally {
      setTypeModal("")
      setResponse("editConcert")
    }
  }

  const closeModalHandler = () => {
    setTypeModal("")
    setCurrentConcert(null)
  }

  useEffect(() => {
    if (currentConcert) {
      setFormData({
        titleUk: currentConcert?.title[0]?.value,
        titleEn: currentConcert?.title[1]?.value,
        descriptionUk: currentConcert?.description[0]?.value,
        descriptionEn: currentConcert?.description[1]?.value,
        date: `${moment(currentConcert.date).format("YYYY-MM-DD")}`,
        time: `${moment(currentConcert.date).format("HH:mm")}`,
        price: currentConcert.price,
        seats: currentConcert.seats,
      })
      setFileData(`${config.serverURL}/${currentConcert.mainImage}`)
    }
  }, [currentConcert])

  return (
    <Modal>
      <div className="modal-header">
        <h4 className="title">
          {Translater.Landings.modelLanding.edit[language.slug]}
        </h4>
        <img
          className="close"
          onClick={closeModalHandler}
          src={closeModal}
          alt="closeModal"
        />
      </div>
      <div className="photo-container">
        <PhotoPicker
          headerTitle={Translater.Landings.events.headers.photo[language.slug]}
          file={fileData}
          onChange={(file: File) => handleFileChange(file)}
          isHeader
          width="448px"
          height="253px"
        />
      </div>
      <TabLanguage valueLang={valueLang} setValueLang={setValueLang} />
      <div>
        {valueLang === "uk" && (
          <div className="form-container-modal">
            <label className="styled-label">
              {Translater.Landings.modelLanding.title[language.slug]}
            </label>
            <input
              className="styled-input"
              name="titleUk"
              value={formData.titleUk}
              onChange={handleInputChange}
            />
          </div>
        )}
        {valueLang === "en" && (
          <div className="form-container-modal">
            <label className="styled-label">
              {Translater.Landings.modelLanding.title[language.slug]}
            </label>
            <input
              className="styled-input"
              name="titleEn"
              value={formData.titleEn}
              onChange={handleInputChange}
            />
          </div>
        )}
        {(errors.titleEn || errors.titleUk) && (
          <p className="error-message">{errors.titleEn || errors.titleUk}</p>
        )}
      </div>
      <div className="forms-wrapper">
        <div className="form-container-modal">
          <label className="styled-label">
            {Translater.Landings.modelLanding.price[language.slug]}
          </label>
          <input
            className="styled-input"
            name="price"
            value={formData.price}
            type="number"
            onChange={(e) => handleInputChange(e, "number")}
          />
        </div>
        <div className="form-container-modal">
          <label className="styled-label">
            {Translater.Landings.modelLanding.seats[language.slug]}
          </label>
          <input
            className="styled-input"
            name="seats"
            value={formData.seats}
            type="number"
            onChange={(e) => handleInputChange(e, "number")}
          />
        </div>
        <div className="forms-date">
          <div className="form-container-modal">
            <label className="styled-label">
              {Translater.Landings.modelLanding.date[language.slug]}
            </label>
            <DatePicker
              style={{ width: "216px" }}
              placeholder={Translater.Placeholders.pickDate[language.slug]}
              value={formData.date ? moment(formData.date) : null}
              onChange={handleDateChange}
              disabledDate={(current) => {
                return !!current && current < moment().startOf("day")
              }}
            />
          </div>
          <div className="form-container-modal">
            <label className="styled-label">
              {Translater.Landings.modelLanding.time[language.slug]}
            </label>
            <TimePicker
              style={{ width: "216px" }}
              format="HH:mm"
              size="large"
              value={formData.time ? moment(formData.time, "HH:mm") : undefined}
              onChange={handleTimeChange}
              disabledHours={() => {
                const now = moment()
                const currentHour = now.hour()
                const disabledHours = []
                if (formData.date && moment(formData.date).isSame(now, "day")) {
                  for (let i = 0; i < currentHour; i++) {
                    disabledHours.push(i)
                  }
                }
                return disabledHours
              }}
              disabledMinutes={(selectedHour) => {
                const now = moment()
                const currentHour = now.hour()
                const currentMinute = now.minute()
                const disabledMinutes = []
                if (formData.date && moment(formData.date).isSame(now, "day")) {
                  if (selectedHour === currentHour) {
                    for (let i = 0; i < currentMinute; i++) {
                      disabledMinutes.push(i)
                    }
                  }
                }
                return disabledMinutes
              }}
            />
          </div>
        </div>
        <div>
          {valueLang === "uk" && (
            <div className="form-container-modal">
              <label className="styled-label">
                {Translater.Landings.modelLanding.description[language.slug]}
              </label>
              <textarea
                className="styled-textarea"
                name="descriptionUk"
                value={formData.descriptionUk}
                onChange={handleInputChange}
              ></textarea>
            </div>
          )}
          {valueLang === "en" && (
            <div className="form-container-modal">
              <label className="styled-label">
                {Translater.Landings.modelLanding.description[language.slug]}
              </label>
              <textarea
                className="styled-textarea"
                name="descriptionEn"
                value={formData.descriptionEn}
                onChange={handleInputChange}
              ></textarea>
            </div>
          )}
          {(errors.descriptionEn || errors.descriptionUk) && (
            <p className="error-message">
              {errors.descriptionEn || errors.descriptionUk}
            </p>
          )}
        </div>
      </div>
      <div className="footer-modal">
        {typeModal !== "create" ? (
          <>
            <button
              className="styled-button hover"
              onClick={() => deleteConcert(currentConcert?._id)}
            >
              {Translater.Buttons.delete[language.slug]}
            </button>
            <button className="styled-button" onClick={updateConcert}>
              {Translater.Buttons.save[language.slug]}
            </button>
          </>
        ) : (
          <button className="styled-button" onClick={createConcert}>
            {Translater.Buttons.create[language.slug]}
          </button>
        )}
      </div>
    </Modal>
  )
}
