import React, { useState, useEffect, useContext, ChangeEvent } from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";

import { useHttp } from "../../hooks";

import * as Form from "../../components/Form";
import { config, Translater } from "../../config";
import * as Components from "./components";
import { IPagination, IRestaurant } from "../../types/items";

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { loading, request, totalCount } = useHttp();

  const [rests, setRests] = useState<IRestaurant[]>([]);
  const [restId, setRestId] = useState<string>("641c178edbb1727975cdd34b");
  const [primary, setPrimary] = useState<any>([]);
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: totalCount,
  });

  const Events = {
    reloadHandler: () => {
      Callbacks.Fetch();
    },

    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page });
    },

    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value });
    },

    selectRestHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setRestId(e.target.value);
    },
  };

  const Callbacks = {
    GetRests: async () => {
      const Authorization = `Bearer ${token as string}`;
      const rests: IRestaurant[] = await request(
        `${config.API}/restaurants`,
        "GET",
        null,
        {
          Authorization,
        }
      );
      if (rests?.length) {
        setRestId(rests[0]._id);
        setRests(rests);
      }
    },

    Fetch: async () => {
      try {
        const response: any = await request(
          `${config.serverURL}api/booking/byRestaurant/${restId}?page=${pagination.page}&perPage=${pagination.perPage}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        );

        setPrimary(response);
      } catch (e) {}
    },
  };

  useEffect(() => {
    Callbacks.GetRests();
  }, []);

  useEffect(() => {
    if (restId) {
      Callbacks.Fetch();
    }
  }, [pagination.perPage, pagination.page, restId]);

  useEffect(() => {
    setPagination({ ...pagination, totalCount });
  }, [totalCount]);

  if (loading || !primary) return <Page.Preloader />;

  return (
    <Page.Wrapper title={Translater.BookingPage.title[language.slug]}>
      <Page.Header
        title={Translater.BookingPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        backButtonLink="/booking"
      />

      {rests && (
        <Form.DefaultSelect
          options={rests}
          field="title"
          selectHandler={Events.selectRestHandler}
          name="optionsSelect"
          value={restId}
        />
      )}

      <Components.Table items={primary} reloadHandler={Events.reloadHandler} />

      <Page.Footer>
        <Page.Pagination
          pagination={pagination}
          setPagination={setPagination}
          paginateHandler={Events.paginateHandler}
          linkTo="/booking"
        />
      </Page.Footer>
    </Page.Wrapper>
  );
};

export default IndexPage;
