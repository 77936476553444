import { ITableTitle } from "../../../../types/table"

export const Options: ITableTitle[] = [
  { type: "header", slug: "increment" },
  { type: "header", slug: "photo" },
  { type: "header", slug: "name" },
  { type: "header", slug: "price", sort: true },
  { type: "header", slug: "restaurant" },
  { type: "header", slug: "weight", sort: true },
  { type: "header", slug: "" },
]
