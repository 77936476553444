import React, { useContext } from "react";
import * as Contexts from "../../../../contexts";
import { useHistory } from "react-router-dom";
import { Title, Wrapper } from "./styled";
import { ProductStyledList } from "../ProductList/styled";
import { Translater } from "../../../../config";
import { IProduct } from "../../../../types/items";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableProduct from "./ProductItem";

type TProps = {
  popularProducts: IProduct[];
  setPopularProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
};

const PopularProductList = ({
  popularProducts,
  setPopularProducts,
}: TProps) => {
  const history = useHistory();
  const { language } = useContext(Contexts.LanguageContext);

  const onProductClick = (id?: string) => {
    history.push(`/products/${id}`);
  };

  const formatPrice = (price: number) => {
    return price.toLocaleString("uk-UA", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const onDeleteProducts = (product: IProduct) => {
    setPopularProducts((prev) =>
      prev.filter((item) => item._id !== product._id)
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setPopularProducts((items) => {
        const oldIndex = items.findIndex((item) => item._id === active.id);
        const newIndex = items.findIndex((item) => item._id === over?.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <Wrapper>
      <Title>{Translater.Sidebar.popularProducts[language.slug]}</Title>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={popularProducts.map((product) => ({ id: product._id || "" }))}
          strategy={verticalListSortingStrategy}
        >
          <ProductStyledList>
            {popularProducts.map((product, i) => (
              <SortableProduct
                key={(product._id || "") + i}
                product={product}
                onProductClick={onProductClick}
                formatPrice={formatPrice}
                onDeleteProducts={onDeleteProducts}
              />
            ))}
          </ProductStyledList>
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
};

export { PopularProductList };
