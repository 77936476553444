import React, { Fragment, useContext } from "react";

import * as Contexts from "../../../../contexts";
import * as Form from "../../../../components/Form";
import * as Buttons from "../../../../components/Buttons";

import { Translater } from "../../../../config";

import { IDetailBlockProps } from "./types";

const DetailBlock: React.FC<IDetailBlockProps> = ({ data }) => {
  const { language } = useContext(Contexts.LanguageContext);

  return (
    <Fragment>
      <div className="block-container">
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.orderNumber[language.slug]}
          text={`${data.id}`}
        />

        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.orderStatus[language.slug]}
          text={
            //@ts-ignore
            Translater.TableTitles.statusTypes?.[data.orderStatus]?.[
              language.slug
            ]
          }
        />

        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.comment[language.slug]}
          text={
            data.comment
              ? data.comment
              : Translater.TableTitles.withoutComment[language.slug]
          }
        />

        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.user[language.slug]}
        >
          <Buttons.Link
            hideModal
            title={data?.customer?.name}
            linkTo={`/users/${data?.user?._id}`}
          />
          <p className="label-text">{data?.user?.phone}</p>
        </Form.LabelField>

        <Form.LabelField
          label={Translater.TableTitles.type[language.slug]}
          text={
            data?.delivery?.mode === "courier"
              ? `${Translater.TableTitles.courier[language.slug]} (${
                  data?.delivery?.address
                })`
              : `${Translater.TableTitles.pickup[language.slug]} (${
                  data?.delivery?.address
                })`
          }
        />

        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.deliveryPrice[language.slug]}
          text={`${data.deliveryPrice} грн`}
        />

        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.archived[language.slug]}
          text={new Date(data.archived as string).toLocaleDateString()}
        />

        <Form.DateBlock
          updated={data.updatedAt as string}
          created={data.createdAt as string}
        />
      </div>
    </Fragment>
  );
};

export default DetailBlock;
