import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Components from "./components"
import * as Buttons from "../../components/Buttons"

import { useHttp } from "../../hooks"
import { IStock } from "../../types/items"
import { config, Translater } from "../../config"
import { useParams, useHistory } from "react-router-dom"
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from "../../types/multiLang"
import { useValidation } from "../../hooks"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import { getStringFromTranslationsArr } from "../../helpers"
import { TabLanguage } from "../../components/TabLanguage"

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { multiLang } = useContext(Contexts.ConfigContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const history = useHistory()
  const { loading, request } = useHttp()

  const [stock, setStock] = useState<IStock | null>(null)
  const [valueLang, setValueLang] = useState<string>(language.slug)

  const [form, setForm] = useState<IStock>({
    appPhoto: "",
    sitePhoto: "",
    percent: 0,
    name: [{ value: "", lang: "" }],
    description: [{ value: "", lang: "" }],
    status: false,
    products: [],
    dateStart: 0,
    dateEnd: 0,
    timeEnd: 0,
    productsList: "",
    category: "",
    restaurant: "",
    productsTitle: [],
    infinite: false,
  })

  const [categoriesList, setCategoriesList] = useState<string[]>([])
  const [restaurantsList, setRestaurantsList] = useState<string[]>([])
  const [multiLangValues, setMultiLangValues] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  })

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(form.infinite)

  const schema = useMemo<any>(
    () => ({
      name: {
        condition:
          getStringFromTranslationsArr(form.name, language.slug).length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },
      description: {
        condition:
          getStringFromTranslationsArr(form.description, language.slug)
            .length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.description[language.slug]}`,
      },
      percent: {
        condition: +form.percent > 0 && +form.percent <= 100,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.percent[language.slug]}`,
      },
      restaurant: {
        condition: form.restaurant.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.restaurant[language.slug]}`,
      },
      category: {
        condition: form.category?.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.category[language.slug]}`,
      },
      products: {
        condition: form.productsList?.length >= 1,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.category[language.slug]}`,
      },
      dateStart: {
        condition: form.dateStart.toString().length >= 4,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.dataStart[language.slug]}`,
      },
      dateEnd: {
        condition: form.dateEnd.toString().length >= 4,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.dataEnd[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )
  const { errors, validationSchema, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "name") {
        const isTitleInArr = form.name.find((item) => item.lang == valueLang)
          ? true
          : false

        if (isTitleInArr) {
          const title = form.name
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang }
              }
              return item
            })
            .filter((item) => item.value)

          setForm({
            ...form,
            name: title as { value: string; lang: string }[],
          })

          return
        }

        const title = form.name
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value)

        setForm({
          ...form,
          name: title as { value: string; lang: string }[],
        })

        return
      }

      if (e.target.name === "description") {
        const isTitleInArr = form.description.find(
          (item) => item.lang == valueLang
        )
          ? true
          : false

        if (isTitleInArr) {
          const description = form.description
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang }
              }
              return item
            })
            .filter((item) => item.value)

          setForm({
            ...form,
            description: description as { value: string; lang: string }[],
          })

          return
        }

        const description = form.description
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value)

        setForm({
          ...form,
          description: description as { value: string; lang: string }[],
        })

        return
      }

      if (e.target.name == "percent") {
        setForm({ ...form, percent: +e.target.value * 100 })
      }

      if (e.target.type === "file" && e.target.name == "sitePhoto") {
        //@ts-ignore
        setForm({ ...form, sitePhoto: e.target.files[0] })
        return
      }
      if (e.target.type === "file" && e.target.name == "appPhoto") {
        //@ts-ignore
        setForm({ ...form, appPhoto: e.target.files[0] })
        return
      } else setForm({ ...form, [e.target.name]: e.target.value })
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.name == "categoriesList") {
        setCategoriesList(categoriesList.concat(e.target.value))
      } else if (e.target.name == "restrauntsList") {
        setRestaurantsList(restaurantsList.concat(e.target.value))
      }
      setForm({ ...form, [e.target.name]: e.target.value })
    },
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        toogleIsAlertOpen(true)
        console.log("createHandler => e", e)
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IStock = await request(
          `${config.API}/stocks/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          setStock(response)
        }
      } catch (e) {}
    },
    Save: async () => {
      try {
        const data = new FormData()

        data.append("name", JSON.stringify(form.name))

        data.append("appPhoto", form.appPhoto)
        data.append("sitePhoto", form.sitePhoto)
        data.append("description", JSON.stringify(form.description))
        data.append("percent", +form.percent / 100 + "")
        data.append("dateStart", form.dateStart as string)
        data.append("dateEnd", form.dateEnd as string)

        if (form?.productsList?.length) {
          for (let item of form.productsList) {
            data.append("productsList[]", item)
          }
        }
        for (let item of new Set(restaurantsList)) {
          data.append("restaurantsList[]", item)
        }

        const response = await request(
          `${config.API}/stocks/update/${id}`,
          "PUT",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) history.push("/stocks")
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/stocks/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        })

        history.push("/stocks")
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (stock) {
      setForm({
        ...form,
        name: stock.name,
        appPhoto: stock.appPhoto,
        sitePhoto: stock.sitePhoto,
        percent: +stock.percent * 100,
        description: stock.description,

        dateStart: `${new Date(stock.dateStart).getFullYear().toString()}-${
          new Date(stock.dateStart).getMonth().toString().length === 2
            ? new Date(stock.dateStart).getMonth() + 1
            : "0" + (+new Date(stock.dateStart).getMonth() + 1)
        }-${
          new Date(stock.dateStart).getDate().toString().length === 2
            ? new Date(stock.dateStart).getDate()
            : "0" + new Date(stock.dateStart).getDate()
        }`,

        dateEnd: `${new Date(stock.dateEnd).getFullYear().toString()}-${
          new Date(stock.dateEnd).getMonth().toString().length === 2
            ? new Date(stock.dateEnd).getMonth() + 1
            : "0" + (+new Date(stock.dateEnd).getMonth() + 1)
        }-${
          new Date(stock.dateEnd).getDate().toString().length === 2
            ? new Date(stock.dateEnd).getDate()
            : "0" + new Date(stock.dateEnd).getDate()
        }`,
        productsList: stock.productsList,
        category: stock.category,
        restaurant: stock.restaurant,
      })
    }
  }, [stock])

  if (loading || !stock) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={getStringFromTranslationsArr(stock.name, language.slug)}
    >
      <Page.Header
        backButtonTitle={Translater.StocksDetailPage.title[language.slug]}
        backButtonLink="/stocks"
      />
      <Buttons.Container
        disabled={loading}
        saveHandler={access.stocks?.change ? Events.saveHandler : false}
        deleteHandler={access.stocks?.delete ? Events.deleteHandler : false}
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Components.ConfigBlock
        data={stock}
        form={form}
        from={"edit"}
        //@ts-ignore
        isValid={validationSchema}
        multiLangValues={multiLangValues}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        setForm={setForm}
        valueLang={valueLang}
      />
      <Components.DetailBlock data={stock} />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default DetailPage
