import React, { SyntheticEvent, useContext, useEffect, useMemo } from "react"
import { DatePicker } from "antd"
import moment from "moment"
import "moment/locale/uk"

import editIcon from "./../../../../assets/icons/edit.png"
import deleteIcon from "./../../../../assets/icons/delete.png"

import { Table } from "../../../NewTable"
import { SearchInput } from "../../../SearchInput"
import { Translater, config } from "../../../../config"
import { TConcertsList } from "../../types"
import * as Contexts from "../../../../contexts"

import "./events.scss"
import { RecordType, TEvents } from "./types"

export const Events = ({
  getConcerts,
  concertsList,
  setTypeModal,
  setCurrentConcert,
  deleteConcert,
  totalCount,
  filterData,
  setFilterData,
}: TEvents) => {
  const { language } = useContext(Contexts.LanguageContext)

  const columns = useMemo(
    () => [
      {
        header: Translater.Landings.events.headers.photo[language.slug],
        width: "138px",
        Cell: (record: RecordType) => (
          <>
            {record.mainImage ? (
              <img
                className="concert-image"
                src={`${config.serverURL}${record.mainImage}`}
                alt="concert-photos"
              />
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        header: Translater.Landings.events.headers.title[language.slug],
        width: "439px",
        Cell: (record: RecordType) => (
          <span className="text-table">{record?.title[0]?.value}</span>
        ),
      },
      {
        header: Translater.Landings.events.headers.time[language.slug],
        width: "285px",
        Cell: (record: RecordType) => (
          <span className="text-table">{`${moment(record?.date).format(
            "D MMM, YYYY [о] HH:mm"
          )}`}</span>
        ),
      },
      {
        header: Translater.Landings.events.headers.status[language.slug],
        width: "218px",
        Cell: (record: RecordType) => {
          const concertDate = moment(record?.date)
          const currentDate = moment()
          const diffInHours = concertDate.diff(currentDate, "hours")

          let status = Translater.Landings.events.statuses.soon[language.slug]
          if (diffInHours < -3) {
            status = Translater.Landings.events.statuses.archive[language.slug]
          }
          return <span className="text-table">{status}</span>
        },
      },
      {
        header: "",
        width: "100px",
        Cell: (record: any) => (
          <>
            <div className="icons">
              <img
                className="icon"
                onClick={() => openModalWithData(record)}
                src={editIcon}
                alt="edit-icon"
              />
              <img
                className="icon"
                onClick={() => deleteConcert(record._id)}
                src={deleteIcon}
                alt="delete-icon"
              />
            </div>
          </>
        ),
      },
    ],
    []
  )

  const openModalWithData = (record: TConcertsList) => {
    setCurrentConcert(record)
    setTypeModal("update")
  }

  const handleChangeStatus = (e: SyntheticEvent) => {
    const target = e.target as HTMLSelectElement
    setFilterData((prevState) => ({ ...prevState, dateTo: target.value }))
  }

  useEffect(() => {
    getConcerts(
      filterData.page,
      filterData.byPage,
      filterData.title,
      filterData.dateFrom as Date
    )
  }, [
    filterData.byPage,
    filterData.dateFrom,
    filterData.page,
    filterData.title,
    filterData.dateTo,
  ])

  return (
    <div className="tableWrapper">
      <Table
        page={filterData.page}
        setFilterData={setFilterData}
        data={concertsList}
        byPage={filterData.byPage}
        columns={columns}
        pageCount={totalCount}
      >
        <div className="filtered-container">
          <SearchInput
            name="searchName"
            onChange={(e) =>
              setFilterData((prevState) => ({
                ...prevState,
                title: e?.target?.value,
              }))
            }
            placeholder={Translater.Placeholders.search[language.slug]}
          />
          <DatePicker
            placeholder={Translater.Placeholders.pickDate[language.slug]}
            value={
              filterData.dateFrom
                ? moment(filterData.dateFrom, "YYYY-MM-DD")
                : null
            }
            onChange={(date, dateString) =>
              setFilterData({ ...filterData, dateFrom: dateString })
            }
            format="YYYY/MM/DD"
          />
          <select onChange={handleChangeStatus} className="selector">
            <option value="">
              {Translater.Landings.events.statuses.allActions[language.slug]}
            </option>
            <option value="soon">
              {Translater.Landings.events.statuses.soon[language.slug]}
            </option>
            <option value="archive">
              {Translater.Landings.events.statuses.archive[language.slug]}
            </option>
          </select>
        </div>
      </Table>
    </div>
  )
}
