import { Link } from "react-router-dom";
import styled from "styled-components";
export const Wrapper = styled.div`
  border-radius: 20px;
  box-shadow: 0px 6px 12px -1px rgba(129, 129, 129, 0.14);
  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
`;
export const CreatePageTopWrap = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`;
export const TitleLink = styled(Link)`
  display: flex;
  gap: 12px;
  align-items: center;
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  :hover {
    color: inherit;
  }
  svg {
    color: #03cb83;
  }
`;

export const CreateButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #03cb83;
  color: #ffffff;
  font-size: 16px;
  height: 44px;
  width: 120px;
  border-radius: 12px;
  border: 1px solid #03cb83;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  gap: 12px;
`;

export const DeleteButton = styled(CreateButton)`
  background-color: transparent;
  border: 1px solid #ca1f1f;
  color: #ca1f1f;
`;

export const ChangeLang = styled.div`
  border-radius: 14px 14px 0 0;
  overflow: hidden;
  display: flex;
  width: 250px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
`;

export const ActiveIndicator = styled.div<{ $error?: boolean }>`
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: ${({ $error }) => ($error ? "red" : "#03cb83")};
  transition: left 0.3s ease-out, width 0.3s ease-out;
`;

export const ButtonLang = styled.div<{ $active?: boolean; $error?: boolean }>`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 45px;
  color: ${({ $active, $error }) => {
    if ($active) {
      return "#03CB83";
    } else if ($error) {
      return "red";
    } else return "#9E9E9E";
  }};
  border-bottom: 1px solid #9e9e9e;
`;

export const CreateVariationsWrap = styled.div`
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 6px 12px -1px rgba(129, 129, 129, 0.14);
  padding: 12px;
`;
