import { TForm } from "./types"

export const SInitialForm: any = {
  login: "",
  title: [{ value: "", lang: "" }],
  password: "",
  email: "",
  phone: "",
  facebook: "",
  telegram: "",
  instagram: "",
  headerColor: "#fffs",
}
