import React, { useContext, useEffect, useState } from 'react'
import { IConfigBlockProps } from './types'
import * as Form from '../../../components/Form'
import { Translater } from '../../../config'
import * as Contexts from '../../../contexts'
import { config } from '../../../config'
import { useHttp } from '../../../hooks'
import { IRestaurant, TTranslations } from '../../../types/items'
import * as Page from '../../../components/Page'
import { useParams } from 'react-router-dom'
import { IForm } from '../types'
import { getStringFromTranslationsArr } from '../../../helpers'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  isClicked,
  setForm,
  setRest,
  valueLang,
}) => {
  const { language } = useContext(Contexts.LanguageContext)
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()
  const { id }: any = useParams()

  const [rests, setRests] = useState<IRestaurant[]>([])
  const [addition, setAddition] = useState<IForm>()
  const [restaurant, setRestaurant] = useState<IRestaurant[]>()

  const weightOptions = [
    {
      label: Translater.Select.gramms[language.slug],
      value: Translater.Select.gramms[language.slug],
    },
    {
      label: Translater.Select.ml[language.slug],
      value: Translater.Select.ml[language.slug],
    },
  ]

  const Events = {
    inputHandler: (e: any) => {
      const name = e.target.name
      const value = e.target.value

      if (e.target.name === 'name') {
        const isTitleInArr = form.name.find((item) => item.lang == valueLang)
          ? true
          : false

        if (isTitleInArr) {
          const name = form.name
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang }
              }
              return item
            })
            .filter((item) => item.value)

          setForm({
            ...form,
            name: name as { value: string; lang: string }[],
          })

          return
        }

        const name = form.name
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value)

        setForm({
          ...form,
          name: name as { value: string; lang: string }[],
        })

        return
      }

      //@ts-ignore
      setForm((state: any) => ({ ...state, [name]: value }))
    },

    selectHandler: (e: any) => {
      const name = e.target.name
      const value = e.target.value

      if (
        name == 'measurement' &&
        value == Translater.Select.gramms[language.slug]
      ) {
        setForm({
          ...form,
          [name]: [
            { lang: 'uk', value: Translater.Select.gramms.uk },
            { lang: 'en', value: Translater.Select.gramms.en },
          ],
        })
        return
      }

      if (
        name == 'measurement' &&
        value == Translater.Select.ml[language.slug]
      ) {
        setForm({
          ...form,
          [name]: [
            { lang: 'uk', value: Translater.Select.ml.uk },
            { lang: 'en', value: Translater.Select.ml.en },
          ],
        })
        return
      }

      for (let rest of rests) {
        if (rest.title == e.target.value) {
          //@ts-ignore
          setForm((state: any) => ({ ...state, restaurant: rest._id }))
          setRest(rest)
        }
      }
    },
    photoHandler: (e: any) => {
      if (e.target?.files[0] !== null) {
        setForm({ ...form, photo: e.target?.files[0] })
      }
    },
  }

  console.log(rests, 'rests')
  console.log(form, 'form')

  const Callbacks = {
    GetRests: async () => {
      const rests = await request(`${config.API}/restaurants`, 'GET', null, {
        Authorization: `Bearer ${token as string}`,
      })
      if (rests && form._id) {
        setForm && setForm({ ...form, restaurant: [rests[0]._id] })
      }
      setRests(rests)
      setRestaurant(rests)
    },
    getAddition: async () => {
      try {
        const Authorization = `Bearer ${token as string}`
        if (!id) return
        const addition = await request(
          `${config.API}/addition/${id}`,
          'GET',
          null,
          {
            Authorization,
          }
        )

        setAddition(addition)
      } catch (e) {
        console.log(e)
      }
    },
    getRestaurantById: async (id: string) => {
      try {
        const Authorization = `Bearer ${token as string}`

        if (!id) return
        const data = await request(
          `${config.API}/restaurants/${id}`,
          'GET',
          null,
          {
            Authorization,
          }
        )

        setRestaurant(data)
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.GetRests()
  }, [])

  useEffect(() => {
    Callbacks.getAddition()
  }, [])

  return (
    <div>
      <Form.LabelField
        fontSize='small'
        label={Translater.Placeholders.options.title[language.slug]}
      >
        <Form.Input
          key={JSON.stringify(valueLang)}
          type='text'
          name='name'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.name.length > 2 : true}
          value={getStringFromTranslationsArr(form.name, valueLang)}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.Placeholders.options.price[language.slug]}
      >
        <Form.Input
          name='price'
          type='number'
          placeholder={'0'}
          isValid={
            isClicked ? Number(form.price) > 0 && form.price != '0' : true
          }
          value={form.price}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.Placeholders.options.photo[language.slug]}
      >
        {form.photo &&
          (typeof form.photo === 'string' || form.photo instanceof String) && (
            <img
              alt='Category'
              src={`${config.imageProxy}public/${form.photo}`}
              style={{ objectFit: 'cover', width: 350, height: 250 }}
            />
          )}
        <Form.Input
          type='file'
          name='photo'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.photo != null : true}
          inputHandler={Events.photoHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.weight[language.slug]}
      >
        <Form.Input
          name='weight'
          type='number'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.weight > 0 : true}
          inputHandler={Events.inputHandler}
          value={form.weight}
        />
        <Form.Select
          name='measurement'
          value={getStringFromTranslationsArr(
            form?.measurement as TTranslations,
            language.slug
          )}
          options={weightOptions}
          selectHandler={Events.selectHandler}
        />
      </Form.LabelField>

      {rests && (
        <Form.LabelField
          fontSize='small'
          label={Translater.TableTitles.restaurant[language.slug]}
        >
          <>
            <Page.SearchSelect
              placeholder={Translater.Placeholders.searchByRests[language.slug]}
              searchField='title'
              data={rests.map((item) => ({
                label: getStringFromTranslationsArr(item?.title, language.slug),
                value: item?._id,
              }))}
              setSwitch={(restaurant) => {
                setForm({
                  ...form,
                  restaurant: rests
                    .map((rest) => {
                      if (rest._id == restaurant.value) return rest
                    })
                    .filter((rest) => rest?._id)
                    .map((item) => item?._id) as string[],
                })
              }}
              value={{
                label: getStringFromTranslationsArr(
                  rests.find((item) => item._id == form.restaurant[0])
                    ?.title as TTranslations,
                  language.slug
                ),
                value: rests.find((item) => item._id == form.restaurant[0])
                  ?._id,
              }}
            />
          </>
        </Form.LabelField>
      )}
    </div>
  )
}

export default ConfigBlock
