import React, { useState, useContext, useEffect, useMemo } from "react"
import { IRestaurant } from "../../types/items"

import { useHttp } from "../../hooks/http.hook"
import * as Contexts from "../../contexts"

import { config, Translater } from "../../config"
import "./stocks.scss"
import { getStringFromTranslationsArr } from "../../helpers"

const ListRestaurants: React.FC<{
  restaurant: string
  selectHandler: (e: any) => void
  isClicked: boolean
}> = ({ selectHandler, restaurant, isClicked }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()
  const { language } = useContext(Contexts.LanguageContext)

  const [primary, setPrimary] = useState<IRestaurant[]>([])

  const style = useMemo(() => {
    if (isClicked) {
      return { borderColor: "#d46262" }
    }
    return {}
  }, [isClicked])

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          setPrimary(response)
        }
      } catch (e) {}
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  return (
    <select
      name="restaurant"
      value={restaurant}
      onChange={selectHandler}
      className="input-list"
      style={style}
    >
      <option value="" disabled>
        {Translater.Placeholders.chooseRestaurant[language.slug]}
      </option>
      {primary.map(({ _id, title }) => {
        return (
          <option key={_id} value={_id}>
            {getStringFromTranslationsArr(title, language.slug)}
          </option>
        )
      })}
    </select>
  )
}

export default ListRestaurants
