import React, { useContext } from "react";
import { TVariation } from "../../types";
import { VariationItemWrap } from "./styled";
import { Link } from "react-router-dom";
import * as Contexts from "../../../../contexts";
import moment from "moment";
import { GoPencil } from "react-icons/go";

type TProps = {
  item: TVariation;
};

export const VariationItem = ({ item }: TProps) => {
  const { language } = useContext(Contexts.LanguageContext);
  const title = item.title.find((el) => el.lang === language.slug)?.value;
  const categoryTitle = item.category?.title.find(
    (el) => el.lang === language.slug
  )?.value;
  return (
    <VariationItemWrap>
      <p>{title}</p>
      <p>{categoryTitle}</p>
      <p>{moment(item.createdAt).format("DD/MM/YYYY")}</p>
      <Link to={`/variations/${item._id}`}>
        <GoPencil />
      </Link>
    </VariationItemWrap>
  );
};
