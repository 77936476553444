import styled from "styled-components";
export const Wrapper = styled.div`
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 753px;
  @media (min-width: 1440px) {
    max-width: 410px;
  }
`;

export const InpustWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const KeyWordsWrap = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export const KeyWordsList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 8px;
  span {
    padding: 4px 6px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 20px;
    background-color: #03cb83;
    color: #ffffff;
    font-size: 14px;
    svg {
      cursor: pointer;
      width: 22px;
      height: 22px;
    }
  }
`;
