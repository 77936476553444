import React from "react";
import { IInputProps } from "./types";

const InputDate: React.FC<IInputProps> = ({
  type = "date",
  name,
  label,
  placeholder,
  isValid = true,
  value,
  min,
  max,
  inputHandler,
  pressHandler = () => {},
}) => {
  return (
    <div className="input-wrapper">
      {label ? <h6 className="input-label">{label}</h6> : null}

      <input
        className={`input ${!isValid && "invalid"}`}
        type={"date"}
        name={name}
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        onChange={inputHandler}
        onKeyPress={pressHandler}
      />
    </div>
  );
};

export default InputDate;
