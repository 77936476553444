import React from "react";

import * as Table from "../../../../components/Table";
import * as Components from "..";

import { ITableWrapperProps } from "./types";

import { SHomeTitles } from "./static";

const TableWrapper: React.FC<ITableWrapperProps> = ({
  items,
  sortHandler,
  reloadHandler,
}) => {
  return (
    <Table.Table type="orders" titles={SHomeTitles} sortHandler={sortHandler}>
      {items.map((item) => (
        <Components.Track
          key={item._id}
          data={item}
          reloadHandler={reloadHandler}
        />
      ))}
    </Table.Table>
  );
};

export default TableWrapper;
