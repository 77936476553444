import styled from "styled-components";

import { TStyledLabelProps, TStyledInputProps } from "./types";
// style for Input
export const StyledInput = styled.input<TStyledInputProps>`
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
  outline: none;
  /* border: none; */
  padding: ${({ $padding }) => $padding};
  border: 1px solid ${({ $error }) => (!$error ? "#E1E1E1" : "#CA1F1F")};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  font-size: 16px;
  background-color: ${({ fill }) => fill};
  transition: border-color 0.2s;
  color: #333333;
  &[disabled] {
    border: 1px solid #d3d3d3;
    background-color: #f5f5f5;
    color: #a9a9a9;
  }
  &:focus {
    border: 1px solid ${({ $error }) => (!$error ? "#03CB83" : "#CA1F1F")};
  }
`;
export const ShowPasswordButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  left: 90%;
  bottom: 7px;
  cursor: pointer;
  opacity: 0.5;
  font-size: 12px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
// style for label
export const Label = styled.label<TStyledLabelProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  width: ${({ width }) => width};
  color: #333333;
  span {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
  }
`;
export const ErrorMessage = styled.div<{ $bottomError: string }>`
  position: absolute;
  font-size: 12px;
  bottom: ${({ $bottomError }) => $bottomError};
  color: #ca1f1f;
`;
