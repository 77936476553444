import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  KeyboardEvent,
} from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Components from "./components";
import { useHttp } from "../../hooks";

import { config, Translater } from "../../config";

import {
  IPagination,
  IFilter,
  TSortBy,
  IProduct,
  IRestaurant,
  ICategory,
} from "../../types/items";
import { SearchSelect } from "../../components/Page";
import { useDebounceEffect } from "./components/ImageCropper/useDebounceEffect";

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { loading, totalCount, request } = useHttp();

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "updatedAt",
    sortOrder: "desc",
  });
  const [restaurants, setRestaurants] = useState<IRestaurant[]>([]);
  const [searchRest, setSearchRest] = useState<string>("");
  const [searchCategory, setSearchCategory] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [categories, setCategories] = useState<ICategory[]>([]);

  const [primary, setPrimary] = useState<IProduct[]>([]);

  useEffect(() => {
    setPagination({ ...pagination, totalCount });
  }, [totalCount]);

  const Utils = {
    findCategoryById: () => {
      const elem = categories.find((item) => item._id === searchCategory);
      if (elem) {
        const result = elem?.title;
        return result;
      }

      return "";
    },
    findTitleRestById: () => {
      const elem = restaurants.find((item) => item._id === searchRest);

      if (elem) {
        const result = elem?.title.map((item) => {
          if (item.lang == language.slug) {
            return item.value;
          }
        })[0];
        return result;
      } else {
        return searchRest[0];
      }
    },
  };

  // Saving to Local Storage
  const saveFilters = () => {
    localStorage.setItem(
      "filters",
      JSON.stringify({ category: searchCategory, rest: searchRest })
    );
  };

  // Loading from Local Storage
  const loadFilters = () => {
    const savedFilters = localStorage.getItem("filters");
    return savedFilters ? JSON.parse(savedFilters) : {}; // Assume defaultFilters is predefined
  };

  const Events = {
    searchHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    },
    pressHandler: (e: KeyboardEvent) => {
      if (e.key === "Enter") Callbacks.Fetch();
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value });
    },
    sortHandler: (name: TSortBy) => {
      let sortOrder: "asc" | "desc";
      if (filter.sortOrder === "asc") sortOrder = "desc";
      else sortOrder = "asc";
      setFilter({
        sortBy: name,
        sortOrder,
      });

      setPagination({ ...pagination, page: 1 });
    },
    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page });
    },
    reloadHandler: () => {
      Callbacks.Fetch();
    },
    optionHandler: (option: any) => {
      option.value && setSearchCategory(option.value);
    },
    optionRestaurantHandler: (option: any) => {
      option.value && setSearchRest(option.value);
    },
    clearFilterHandler: () => {
      setSearchCategory("");
      setSearchRest("");
      localStorage.removeItem("filters");
    },
  };

  const Callbacks = {
    FetchCategories: async () => {
      setCategories([]);
      try {
        const response: ICategory[] = searchRest
          ? await request(
              `${config.API}/categories/?perPage=50&restaurant=${searchRest}`,
              "GET",
              null,
              {
                Authorization: `Bearer ${token as string}`,
              }
            )
          : [];

        setCategories(response);
      } catch (e) {
        console.log("E", e);
      }
    },
    Fetch: async () => {
      try {
        if (searchRest.length === 0) {
          const response: IProduct[] = await request(
            `${config.API}/products?full=1&page=${pagination.page}&perPage=${pagination.perPage}
            &name=${searchValue}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            },
            true
          );
          if (response) {
            setPrimary(response);
          }
        } else {
          const response: IProduct[] = await request(
            `${config.API}/products?perPage=${pagination.perPage}&page=${pagination.page}&sortBy=${filter.sortBy}&category_id=${searchCategory}&restaurant=${searchRest}`,
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            },
            true
          );
          console.log("RESPONSE", response);
          if (response) {
            setPrimary(response);
          }
        }
      } catch (e) {}
    },
    FetchRestaurants: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants/`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        if (response) {
          setRestaurants(response);
        }
      } catch (e) {}
    },
  };

  useEffect(() => {
    Callbacks.FetchCategories();
  }, [searchRest]);

  useEffect(() => {
    Callbacks.FetchRestaurants();
  }, []);

  useEffect(() => {
    if (searchCategory || searchRest) {
      saveFilters();
    }
  }, [searchCategory, searchRest]);

  useEffect(() => {
    const { category, rest } = loadFilters();

    rest && setSearchRest(rest);
    category && setSearchCategory(category);
  }, []);

  useDebounceEffect(
    () => {
      Callbacks.Fetch();
    },
    150,
    [
      searchCategory,
      searchRest,
      pagination.page,
      pagination.perPage,
      filter.sortBy,
      filter.sortOrder,
    ]
  );

  if (loading || !primary) return <Page.Preloader />;

  return (
    <Page.Wrapper title={Translater.ProductsPage.title[language.slug]}>
      <Page.Header
        title={Translater.ProductsPage.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.products?.change}
        linkTo="/products/create"
      />

      <div className="filter-container">
        <SearchSelect
          height={47}
          width={290}
          data={restaurants?.map((item) => ({
            label: item?.title
              ?.map((t) => {
                if (t.lang === language.slug) {
                  return t.value;
                }
              })
              .filter((item) => item)[0],
            value: item?._id,
          }))}
          placeholder={Translater.CategoriesPage.pickRestaurant[language.slug]}
          value={searchRest ? searchRest : null}
          searchField="title"
          defaultValue={Utils.findTitleRestById()}
          setSwitch={Events.optionRestaurantHandler}
        />

        {searchRest.length === 0 && (
          <SearchSelect
            disabled={!searchRest}
            height={47}
            width={260}
            data={categories?.map((item) => ({
              label: item?.title
                ?.map((t) => {
                  if (t.lang === language.slug) {
                    return t.value;
                  }
                  return "";
                })
                .filter((item) => item)[0],
              value: item?._id,
            }))}
            placeholder={
              Translater.Placeholders.filterByCategory[language.slug]
            }
            value={searchCategory ? searchCategory : null}
            searchField="title"
            setSwitch={Events.optionHandler}
          />
        )}

        {searchRest.length > 0 && (
          <SearchSelect
            height={47}
            width={260}
            data={
              categories?.map((item) => ({
                label: item?.title
                  ?.map((t) => {
                    if (t.lang === language.slug) {
                      return t.value;
                    }
                    return "";
                  })
                  .filter((item) => item)[0],
                value: item?._id,
              }))
              // .filter((item) => item.value === searchRest)
            }
            placeholder={
              Translater.Placeholders.filterByCategory[language.slug]
            }
            value={searchCategory ? searchCategory : null}
            searchField="title"
            setSwitch={Events.optionHandler}
          />
        )}

        <button
          className="btn btn--default btn--default--green clear-filter"
          onClick={Events.clearFilterHandler}
          style={{ marginBottom: "0px" }}
        >
          {Translater.Items.Clear[language.slug]}
        </button>

        <Page.SortBar
          type="product"
          searchBar
          filter={filter}
          value={searchValue}
          perPage={pagination.perPage}
          pressHandler={Events.pressHandler}
          inputHandler={Events.searchHandler}
          selectHandler={Events.selectHandler}
        />
      </div>

      <Components.Table
        page={pagination.page}
        items={primary}
        sortHandler={Events.sortHandler}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>
        <Page.Pagination
          linkTo="/products"
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          setPagination={setPagination}
        />
      </Page.Footer>
    </Page.Wrapper>
  );
};

export default IndexPage;
