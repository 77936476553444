import React from 'react';

import { PhotoPicker } from '../../../PhotoPicker';

import "../../lending.scss";

import { TPhotoPickerArray } from './types';

export const PhotoPickerArray = ({photos, setPhotos}: TPhotoPickerArray) => {
  const handlePhotoChange = (file: File, index: number) => {
    const updatedPhotos = [...photos];
    if (index < updatedPhotos.length) {
      if (file) {
        updatedPhotos[index] = file;
      } else {
        updatedPhotos.splice(index, 1);
      }
    } else {
      updatedPhotos.push(file);
    }
    setPhotos(updatedPhotos);
  };

  const handleDeletePhoto = (index: number) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };

  return (
    <div className="gallery-wrapper">
      {photos.map((photo, index) => (
        <div key={index} className="gallery-block">
          <PhotoPicker
            onChange={handlePhotoChange}
            onDelete={() => handleDeletePhoto(index)}
            index={index}
            file={photo}
          />
        </div>
      ))}
      <div className="gallery-block">
        <PhotoPicker onChange={handlePhotoChange} />
      </div>
    </div>
  );
};
