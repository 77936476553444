import React, { useContext } from "react";
import { ActiveIndicator, ButtonLang, ChangeLangStyled } from "./styled";
import { Translater } from "config";
import * as Contexts from "contexts";
type TProps = {
  currentLang: "uk" | "en";
  onLangClick: (lang: "uk" | "en") => void;
};

export const ChangeLang = ({ currentLang, onLangClick }: TProps) => {
  const { language } = useContext(Contexts.LanguageContext);

  return (
    <ChangeLangStyled>
      <ButtonLang
        onClick={() => onLangClick("uk")}
        $active={currentLang === "uk"}
      >
        {Translater.Buttons.UKR[language.slug]}
      </ButtonLang>
      <ButtonLang
        onClick={() => onLangClick("en")}
        $active={currentLang === "en"}
      >
        {Translater.Buttons.EN[language.slug]}
      </ButtonLang>
      <ActiveIndicator
        style={{
          left: `${(currentLang === "uk" ? 0 : 1) * 50}%`,
          width: "50%",
        }}
      />
    </ChangeLangStyled>
  );
};
