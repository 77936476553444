import React, { FC, SyntheticEvent } from "react";

import "./footerTable.scss";
import { Pagination } from "../Pagination";

import { TFooterTableProps } from "./types";

export const FooterTable: FC<TFooterTableProps> = ({
  page,
  byPage,
  pageCount,
  setFilterData
}) => {
  const onPageChange = (selectedItem: { selected: number }) => {
    setFilterData((prevState) => ({...prevState, page: +selectedItem.selected}));
  };

  const changeHandler = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setFilterData((prevState) => ({...prevState, byPage: +value}));
  };

  return (
    <div className="container-footer">
      <span className="text-block">Показано {pageCount} з {pageCount} позицій</span>
      <Pagination
        page={page}
        pageCount={Math.ceil(pageCount / byPage)}
        onPageChange={onPageChange}
      />
      <div className="limit-block">
        <span className="text-left">Показувати</span>
        <select onChange={changeHandler} className="select">
          <option>10</option>
          <option>15</option>
          <option>20</option>
        </select> 
        <span className="text-right">позицій</span>
      </div>
    </div>
  );
};
