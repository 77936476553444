import React, { Fragment } from 'react'
import * as Components from '..'
import { IProductsListProps } from './types'


const ProductsList: React.FC<IProductsListProps> = ({
  items
}) => {

  return (
    <Fragment>
      {items.map(item => (
        <Components.ProductCard
          key={item._id}
          data={item}
        />
      ))}
    </Fragment>
  )
}

export default ProductsList