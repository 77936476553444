import React, { useEffect } from "react";

import "./newModal.scss";
import { TModal } from "./typed";

export const Modal = ({
  children,
  background = "white",
  padding = "18px",
}: TModal) => {
  
  useEffect(() => {
    document.body.classList.add("no-scroll");

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div className="modal-container">
      <div
        className="modal-content"
        style={{ padding: padding, background: background }}
      >
        {children}
      </div>
      <div className="blur-container" />
    </div>
  );
};
