import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 14px 14px 0;
  overflow: auto;
  max-height: 90%;
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 16px;
`;
export const ProductItemStyled = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
  &:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid #d3d3d3;
  }
  gap: 12px;
  img {
    width: 90px;
    height: 90px;
    border-radius: 12px;
    object-fit: cover;
    cursor: pointer;
  }
`;
