import React, { useContext } from 'react'

import * as Contexts from '../../contexts'

import { useDocTitle } from '../../hooks'

import { IWrapperProps } from './types'


const Wrapper: React.FC<IWrapperProps> = ({
  title = 'Page',
  footer = false,
  children
}) => {

  const { isActive } = useContext(Contexts.SidebarContext)

  useDocTitle(title)
  return (
    <main className={`
      page-wrapper 
      ${isActive ? 'shorter' : ''}
      ${footer ? 'footer' : ''}
      `}>
      <div className='page-container'>
        {children}
      </div>
    </main>
  )
}

export default Wrapper