import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import lendingsIcon from "./../../assets/icons/lendings.svg";
import ArrowSelect from "./../../assets/icons/arrow-bottom.png";
import productPIcon from "../../assets/icons/products.svg";
import * as Contexts from "../../contexts";
import * as Buttons from "../Buttons";
import { IoOptionsSharp } from "react-icons/io5";

import { SNavLinks } from "./static";
import { Translater } from "../../config";
import { MenuIcon } from "../Buttons/styled";

const Sidebar: React.FC = () => {
  const { isActive, actions: sidebarActions } = useContext(
    Contexts.SidebarContext
  );
  const { language } = useContext(Contexts.LanguageContext);

  const [isHiddenSelect, setIsHiddenSelect] = useState(false);

  return (
    <div>
      <aside className={`sidebar ${isActive && "active"}`}>
        <NavLink className="sidebar-logo" to="/admin">
          <img alt="Logo" src={require("../../assets/images/logo.svg")} />
        </NavLink>
        <nav className="sidebar-nav">
          {SNavLinks.map((link) => {
            if (link.slug === "products") {
              return (
                <>
                  {" "}
                  <Buttons.NavLink
                    key={link.slug}
                    slug={link.slug}
                    linkTo={link.linkTo}
                  />
                  <NavLink className="sidebar-link" to={"/PopularProducts"}>
                    <MenuIcon alt={"PopularProducts"} src={productPIcon} />
                    {Translater.Sidebar.popularProducts[language.slug]}
                  </NavLink>
                </>
              );
            }
            if (link.slug === "categories") {
              return (
                <>
                  <Buttons.NavLink
                    key={link.slug}
                    slug={link.slug}
                    linkTo={link.linkTo}
                  />
                  <NavLink className="sidebar-link" to={"/variations"}>
                    <IoOptionsSharp
                      style={{
                        color: "000000",
                        marginRight: "12px",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                    {Translater.Buttons.variations[language.slug]}
                  </NavLink>
                </>
              );
            }
            return (
              <Buttons.NavLink
                key={link.slug}
                slug={link.slug}
                linkTo={link.linkTo}
              />
            );
          })}
        </nav>
        <nav className="sidebar-lendings-select">
          <div
            className="select-container"
            onClick={() => setIsHiddenSelect(!isHiddenSelect)}
          >
            <div className="select-headline">
              <img
                className="lendings-img"
                src={lendingsIcon}
                alt="icon-lendings-list"
              />
              <p className="select-title">
                {Translater.Landings.sidebar.landings[language.slug]}
              </p>
            </div>
            <img
              className={`arrow-img ${isHiddenSelect ? "active" : ""}`}
              src={ArrowSelect}
              alt="icon-lendings-list"
            />
          </div>
          {isHiddenSelect && (
            <div className="items">
              <NavLink className="item-link" to="/babo-gardens/babo_gardens">
                Babo Gardens
              </NavLink>
              <NavLink className="item-link" to="/mons-pius/monpius">
                Mons Pius
              </NavLink>
              <NavLink
                className="item-link"
                to="/para-djanov-and-porvein/parajanov"
              >
                Пара Джанов і Портвейн
              </NavLink>
              <NavLink className="item-link" to="/libraria/libraria/all">
                Libraria
              </NavLink>
            </div>
          )}
        </nav>
      </aside>
      <Buttons.HideButton
        isActive={isActive}
        buttonHandler={sidebarActions.TOGGLE}
      />
    </div>
  );
};

export default Sidebar;
