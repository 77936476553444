import { Link } from "react-router-dom";
import styled from "styled-components";
export const CreatePageTopWrap = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
`;
export const TitleLink = styled(Link)`
  display: flex;
  gap: 12px;
  align-items: center;
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  :hover {
    color: inherit;
  }
  svg {
    color: #03cb83;
  }
`;

export const MainWrapper = styled.div`
  border-radius: 0 20px 20px 20px;
  width: 100%;
  box-shadow: 0px 6px 12px -1px rgba(129, 129, 129, 0.14);
  display: flex;
  flex-direction: column;
  @media (min-width: 1440px) {
    flex-direction: row;
  }
`;
