import React from "react";

import "./headerTable.scss";
import { THeaderTable } from "./types";

export const HeaderTable = ({ columns }: THeaderTable) => {
  return (
    <div className="table-container-header">
      {columns.map((column: any, index: number) => (
        <div
          key={index}
          className="item-block"
          style={{ width: column.width}}
        >
          <span className="text-header-table">{column.header}</span>
        </div>
      ))}
    </div>
  );
};