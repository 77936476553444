import React from "react";

import { HeaderTable } from "../HeaderTable";
import { MainTable } from "../MainTable";
import { FooterTable } from "../FooterTable";

import "./table.scss";
import { TTable } from "./types";

export const Table = ({
  children,
  byPage,
  data,
  pageCount,
  page,
  columns,
  setFilterData,
}: TTable) => {
  return (
    <div className="table-container">
      {children && <div className="filtered-component">{children}</div>}
 
      <HeaderTable columns={columns} />
      <div className="main-table-wrapper">
        {data?.map((el: any, index: number) => (
          <MainTable columns={columns} el={el} key={index} />
        ))}
      </div>

      <FooterTable
        setFilterData={setFilterData}
        page={page}
        byPage={byPage}
        pageCount={pageCount}
      />
    </div>
  );
};