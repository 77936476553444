import React, { Fragment, useContext, useEffect, useState } from "react";

import * as Contexts from "../../../../contexts";
import * as Table from "../../../../components/Table";

import { useHistory } from "react-router-dom";
import { useHttp } from "../../../../hooks";
import { config, Translater } from "../../../../config";
import { ITrackProps } from "./types";
import { ICategory } from "../../../../types/items";
import * as Components from "../index";
import { getStringFromTranslationsArr } from "../../../../helpers";

const Track: React.FC<ITrackProps> = ({
  data,
  order,
  reloadHandler,
  subTrack,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const [showChildren, toggleChildren] = useState<boolean>(false);
  const { loading, request } = useHttp();
  const history = useHistory();

  const [price, setPrice] = useState<string>(data.price as string);
  const [weight, setWeight] = useState<string>(data.weight as string);

  const Events = {
    childrenHandler: () => {
      if (!loading) toggleChildren(!showChildren);
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },
    editHandler: () => {
      history.push(`/products/${data._id}`);
    },
  };

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/products/${data._id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        reloadHandler();
      } catch (e) {
        console.log(e);
      }
    },
  };

  return (
    <Fragment>
      <Table.Track
        type={"products"}
        clickHandler={Events.childrenHandler}
        tableTrack={!subTrack}
      >
        <Table.Cell type="custom">{order}</Table.Cell>

        <Table.Cell
          type="image"
          imageURL={`${config.imageProxy}public/${data.photo}`}
        />

        <Table.Cell
          text={getStringFromTranslationsArr(data?.name, language.slug)}
        />

        {data.category ? (
          <Table.Cell
            linkText={getStringFromTranslationsArr(
              (data.category as ICategory).title,
              language.slug
            )}
            linkTo={`/categories/${(data.category as ICategory)._id}`}
          />
        ) : (
          <Table.Cell text={Translater.TableTitles.noInfo[language.slug]} />
        )}

        <Table.Cell
          text={
            data.hidden
              ? Translater.TableTitles.hidden.true[language.slug]
              : Translater.TableTitles.hidden.false[language.slug]
          }
        />

        <Table.Cell text={`${price} грн`} />
        <Table.Cell text={`${weight} г`} />

        <Table.Cell
          text={new Date(data.updatedAt as string).toLocaleDateString()}
        />

        <Table.Cell
          text={new Date(data.createdAt as string).toLocaleDateString()}
        />

        <Table.Cell
          type="control"
          editButton={access?.products?.change}
          deleteButton={access?.products?.delete}
          editHandler={Events.editHandler}
          deleteHandler={Events.deleteHandler}
        />
      </Table.Track>
      {data?.children?.[0] && showChildren
        ? data?.children?.map((item: any, index: number) => (
            <Components.Track
              key={item?._id}
              order={index + 1}
              data={item}
              subTrack
              reloadHandler={reloadHandler}
            />
          ))
        : null}
    </Fragment>
  );
};

export default Track;
