import React from "react";
import ReactPaginate from "react-paginate";

import arrowIcon from "./../../assets/icons/arrow-left.png";
import "./pagination.scss";
import { TPagination } from "./types";

export const Pagination = ({
  page,
  pageCount = 1,
  onPageChange,
}: TPagination) => {
  return (
    <div className="pagination-container">
      <ReactPaginate
        previousLabel={
          <img className="arrow-left-icon" src={arrowIcon} alt="Next" />
        }
        nextLabel={
          <img className="arrow-right-icon" src={arrowIcon} alt="Previous" />
        }
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={page}
      />
    </div>
  );
};
