import { useContext } from "react"
import * as Contexts from "../contexts"

export const getStringFromTranslationsArr = (
  translationsArr: { value: string; lang: string }[],
  slug: string
): string => {
  if (translationsArr?.find((item) => item?.lang == slug)?.value.length) {
    return translationsArr?.find((item) => item?.lang == slug)?.value as string
  }

  return ""
}
