import React, { ChangeEvent } from "react";
import { useContext } from "react";
import * as Contexts from "../../../../contexts";
import { config, Translater } from "../../../../config";

import {
  AddButton,
  LoadButton,
  ProductInfo,
  ProductListWrap,
  ProductStyledList,
  SearchProduct,
  Title,
} from "./styled";
import { useHistory } from "react-router-dom";
import { IProduct } from "../../../../types/items";
import { Params } from "../../helpers";

type TProps = {
  products: IProduct[];
  params: Params;
  setParams: React.Dispatch<React.SetStateAction<Params>>;
  setProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
  popularProducts: IProduct[];
  setPopularProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
};

// const MAX_POPULAR_PRODUCTS_LIMIT = 4;

const ProductList = ({
  products,
  params,
  setParams,
  setProducts,
  popularProducts,
  setPopularProducts,
}: TProps) => {
  const history = useHistory();

  const { language } = useContext(Contexts.LanguageContext);

  const isDisabledLoad = params.perPage * params.page > products.length;

  const loadMore = () =>
    setParams((prev) => ({ ...prev, page: prev.page + 1 }));

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setProducts([]);
    setParams({ search: e.target.value, page: 1, perPage: params.perPage });
  };

  const onProductClick = (id?: string) => {
    history.push(`/products/${id}`);
  };

  const formatPrice = (price: number) => {
    return price.toLocaleString("uk-UA", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const onAddButtonClick = (product: IProduct) => {
    setPopularProducts((prev) => [...prev, product]);
  };

  return (
    <ProductListWrap>
      <Title>{Translater.Sidebar.products[language.slug]}</Title>
      <SearchProduct>
        {Translater.Placeholders.searchByname[language.slug]}
        <input value={params.search} onChange={onSearch} />
      </SearchProduct>
      <ProductStyledList>
        {products.map((product, i) => {
          const src = `${config.imageProxy}/public/${product.photo}`;
          const title = product.name?.find(
            (el) => el.lang === language.slug
          )?.value;
          const price = +product.price;
          const discountPrice =
            (1 - (product?.restaurantDiscount?.[0]?.discountPercent || 0)) *
            price;
          const isDisabledButton = popularProducts.some(
            (el) => el._id === product._id
          );
          return (
            <li key={(product._id || "") + i}>
              <img
                alt="photo_product"
                src={src}
                onClick={() => onProductClick(product._id)}
              />
              <ProductInfo>
                <p onClick={() => onProductClick(product._id)}>{title}</p>
                <span>
                  {discountPrice !== price && (
                    <p className="old_price">{formatPrice(price)}</p>
                  )}
                  {formatPrice(discountPrice || price)} ₴
                </span>
              </ProductInfo>
              <AddButton
                disabled={isDisabledButton}
                onClick={() => onAddButtonClick(product)}
              >
                {Translater.Buttons.addToPopular[language.slug]}
              </AddButton>
            </li>
          );
        })}
      </ProductStyledList>
      <LoadButton onClick={loadMore} disabled={isDisabledLoad}>
        {Translater.Placeholders.loadMore[language.slug]}
      </LoadButton>
    </ProductListWrap>
  );
};

export { ProductList };
