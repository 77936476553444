import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useMemo,
} from "react"

import * as Contexts from "../../../../contexts"
import * as Form from "../../../../components/Form"

import { IFieldProps, TPossibleRuleSlugs } from "./types"
import { Translater } from "../../../../config"

const Field: React.FC<IFieldProps> = ({ form, slug, nesting, setForm }) => {
  const { language } = useContext(Contexts.LanguageContext)

  const rule = useMemo(() => {
    if (form.rules[slug].delete) {
      return "delete"
    }
    if (form.rules[slug].change) {
      return "change"
    }
    if (form.rules[slug].view) {
      return "view"
    }
    return "hide"
  }, [slug])

  const [value, setValue] = useState<string>(rule)

  const options = [
    { label: Translater.TableTitles.hide[language.slug], value: "hide" },
    { label: Translater.TableTitles.view[language.slug], value: "view" },
    { label: Translater.TableTitles.change[language.slug], value: "change" },
    { label: Translater.TableTitles.delete[language.slug], value: "delete" },
  ]

  const Events = {
    selectHandler: (e: SyntheticEvent) => {
      const name = (e.target as HTMLInputElement).value

      setValue(name)

      if (name == "view") {
        setForm({
          ...form,
          rules: {
            ...form.rules,
            [slug]: { view: true, change: false, delete: false },
          },
        })
      }

      if (name == "change") {
        setForm({
          ...form,
          rules: {
            ...form.rules,
            [slug]: { view: true, change: true, delete: false },
          },
        })
      }

      if (name == "delete") {
        setForm({
          ...form,
          rules: {
            ...form.rules,
            [slug]: { view: true, change: true, delete: true },
          },
        })
      }

      if (name == "hide") {
        setForm({
          ...form,
          rules: {
            ...form.rules,
            [slug]: { view: false, change: false, delete: false },
          },
        })
      }
    },
  }

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles[slug][language.slug] || ""}
        containerStyle={{
          paddingLeft: nesting,
        }}
      >
        <Form.Select
          name={slug}
          value={value}
          options={options}
          selectHandler={Events.selectHandler}
        />
      </Form.LabelField>
    </Fragment>
  )
}

export default Field
