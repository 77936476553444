import styled from "styled-components";

export const VariationItemWrap = styled.li`
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;

  padding: 0 12px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(237, 237, 237, 1);
  }
  a {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    background-color: #03cb83;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  p {
    display: flex;
    max-width: 100%;
    &:nth-child(1) {
      min-width: 250px;
    }
    &:nth-child(2) {
      min-width: 200px;
    }
    &:nth-child(3) {
      min-width: 200px;
    }
  }
`;
