import styled from "styled-components";
export const TopWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 712px;
  margin-bottom: 30px;
  gap: 20px;
`;

export const InputsWrap = styled.div`
  width: 100%;
  max-width: 712px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const InputItemWrap = styled.div`
  display: flex;
  gap: 20px;
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 12px;
  svg {
    width: 24px;
    height: 24px;
    color: #ca1f1f;
    cursor: pointer;
  }
`;

export const Addbutton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px auto 0;
  background-color: #03cb83;
  border-radius: 12px;
  width: 342px;
  height: 44px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
