import { TLanguages } from "../types/contexts/language";

export const Languages: TLanguages = {
  uk: {
    slug: "uk",
    label: "Українська",
  },
  en: {
    slug: "en",
    label: "English",
  },
};

export const Translater = {
  SEO: {
    mainTab: {
      uk: "Головна сторінка",
      en: "Main page",
    },
    updated: {
      uk: "Успішно оновлено",
      en: "Updated successfuly",
    },
    updatedError: {
      uk: "Помилка при оновленні",
      en: "Update error",
    },
  },
  Landings: {
    sidebar: {
      landings: {
        uk: "Лендінги",
        en: "Landings",
      },
    },
    landing: {
      content: {
        uk: "Контент",
        en: "Content",
      },
      actions: {
        uk: "Події",
        en: "Actions",
      },
      aboutUs: {
        uk: "Про нас",
        en: "About us",
      },
      schedule: {
        uk: "Графік роботи",
        en: "Schedule",
      },
      offlineMenu: {
        uk: "Офлайн меню",
        en: "Offline menu",
      },
    },
    events: {
      headers: {
        photo: {
          uk: "Фото",
          en: "Photo",
        },
        title: {
          uk: "Назва",
          en: "Title",
        },
        time: {
          uk: "Дата та час",
          en: "Date and time",
        },
        createdDate: {
          uk: "Дата створення",
          en: "Date of creation",
        },
        status: {
          uk: "Статус",
          en: "Status",
        },
      },
      statuses: {
        soon: {
          uk: "Скоро",
          en: "Soon",
        },
        archive: {
          uk: "Архів",
          en: "Archive",
        },
        allActions: {
          uk: "Всі події",
          en: "All actions",
        },
      },
    },
    modelLanding: {
      description: {
        uk: "Опис",
        en: "Description",
      },
      time: {
        uk: "Час",
        en: "Time",
      },
      date: {
        uk: "Дата",
        en: "Date",
      },
      seats: {
        uk: "Максимальна кількість місць",
        en: "Max amout of seats",
      },
      price: {
        uk: "Ціна за квиток",
        en: "Price of ticket",
      },
      title: {
        uk: "Назва",
        en: "Title",
      },
      edit: {
        uk: "Редагувати",
        en: "Edit",
      },
    },
    workSchedule: {
      days: {
        uk: "Дні",
        en: "Days",
      },
    },
  },
  ErrorAddress: { uk: "", en: "" },
  ErrorTimeStart: {
    uk: "",
    en: "",
  },
  ErrorTimeEnd: {
    uk: "",
    en: "",
  },
  Stocks: {
    days: {
      uk: "Днів",
      en: "Days",
    },
    hours: {
      uk: "годин",
      en: "hours",
    },
  },
  PaymentType: {
    minimalOrder: {
      uk: "Мінімальна сума замовлення",
      en: "Minimum order amount",
    },
    terminal: {
      uk: "Термінал",
      en: "Terminal",
    },
    online: {
      uk: "Онлайн",
      en: "Online",
    },
    cash: {
      uk: "Готівкою",
      en: "Cash",
    },
  },
  Technique: {
    uk: "Кількість приладів",
    en: "Number of devices",
  },
  Call: {
    uk: "Передзвонити",
    en: "Call back",
  },
  MoneyUser: {
    uk: "Гроші замовника",
    en: "Customer money",
  },
  Rest: {
    uk: "Решта",
    en: "Remains",
  },
  Birthday: {
    uk: "День народження у ",
    en: "Birthday ",
  },
  Phone: {
    uk: "Телефон",
    en: "Phone",
  },
  Success: {
    save: {
      uk: "Ви успiшно змiнили порядок ресторанiв",
      en: "You have successfully reordered restaurants",
    },
  },
  Erros: {
    oops: {
      uk: "Упс...",
      en: "Oops...",
    },
    network: {
      uk: "Відсутнє підключення до інтернету",
      en: "No internet connection",
    },
    forbidden: {
      uk: "Ви не немаєте прав доступу!",
      en: "No access rights!",
    },
  },
  WS: {
    Actions: {
      STATUS_CHANGE: {
        uk: "",
        en: "",
      },
    },
    Types: {
      ORDER: {
        uk: "",
        en: "",
      },
    },
  },
  Sidebar: {
    options: {
      uk: "Опції для продуктів",
      en: "Product Options",
    },
    paramName: {
      uk: "Назва параметру",
      en: "Parameter name",
    },
    online: {
      uk: "Онлайн",
      en: "Online",
    },
    home: {
      uk: "Головна",
      en: "Home",
    },
    feedback: {
      uk: "Вiдгуки",
      en: "Feedbacks",
    },
    orders: {
      uk: "Замовлення",
      en: "Orders",
    },
    categories: {
      uk: "Категорії",
      en: "Categories",
    },
    restaurants: {
      uk: "Ресторани",
      en: "Restaurants",
    },
    booking: {
      uk: "Заявки на бронювання",
      en: "Booking requests",
    },
    bookingDetails: {
      uk: "Бронювання",
      en: "Booking",
    },
    referals: {
      uk: "Реферальні коди",
      en: "Referral codes",
    },
    products: {
      uk: "Продукти",
      en: "Products",
    },
    sales: {
      uk: "Акцiї",
      en: "Stock",
    },
    users: {
      uk: "Користувачi",
      en: "Users",
    },
    birthdays: {
      uk: "Дні народження",
      en: "Birthdays",
    },
    popularProducts: {
      uk: "Популярні продукти",
      en: "Popular products",
    },
    staff: {
      uk: "Персонал",
      en: "Staff",
    },
    roles: {
      uk: "Посади",
      en: "Positions",
    },
    history: {
      uk: "Архів",
      en: "Archive",
    },
    notifications: {
      uk: "Повідомлення",
      en: "Notifications",
    },
    delivery: {
      uk: "Доставка та оплата",
      en: "Shipping and payment",
    },
    stocks: {
      uk: "Акції",
      en: "Stock",
    },
    theFaster: { uk: "Як найшвидше", en: "As soon as possible" },
    Banners: {
      uk: "Баннери",
      en: "Banners",
    },
    seo: {
      uk: "SEO",
      en: "SEO",
    },
    settings: {
      uk: "Налаштування",
      en: "Settings",
    },
    news: {
      uk: "Новини",
      en: "News",
    },
  },
  Buttons: {
    update: {
      uk: "Оновити",
      en: "Update",
    },
    addToPopular: { uk: "Додати", en: "Add" },
    addOption: {
      uk: "Додати опцію",
      en: "Add option",
    },
    controlPoints: {
      uk: "Доставка в iншi райони",
      en: "Delivery to other areas",
    },
    variations: {
      uk: "Варіації",
      en: "Variations",
    },
    createdVariations: { uk: "Створення варіації", en: "Create variations" },
    addVariations: {
      uk: "Додати варіації",
      en: "Add variations",
    },
    login: {
      uk: "Увійти",
      en: "Enter",
    },
    goBack: {
      uk: "Повернутись назад",
      en: "Come back",
    },
    reload: {
      uk: "Перезапустити",
      en: "Restart",
    },
    logout: {
      uk: "Вийти",
      en: "Exit",
    },
    save: {
      uk: "Зберегти",
      en: "Save",
    },
    delete: {
      uk: "Видалити",
      en: "Delete",
    },
    UKR: {
      uk: "Укр",
      en: "Ukr",
    },
    EN: {
      uk: "Анг",
      en: "En",
    },
    create: {
      uk: "Створити",
      en: "Create",
    },
    createVariation: {
      uk: "Створити варіацію",
      en: "Create variation",
    },
    editingVariation: {
      uk: "Редагування варіації",
      en: "Editing a variation",
    },
    addOptions: {
      uk: "Додати опції",
      en: "Add options",
    },
    fillTheDataAllLanguages: {
      uk: "Заповніть дані всіма мовами",
      en: "Fill in the data in all languages",
    },
    errorFormCreated: {
      uk: "Помилка при створенні продукту",
      en: "An error occurred while creating the product",
    },
    successfullyUpdated: {
      uk: "Успішно оновлено",
      en: "Successfully updated",
    },
    successfullyCreated: {
      uk: "Успішно створено",
      en: "Successfully created",
    },
    successfullyDeleted: {
      uk: "Успішно видалено",
      en: "Successfully deleted",
    },
    errorCreating: { uk: "Помилка при створенні", en: "Error creating" },
    deleteOptions: {
      uk: "Видалити опції",
      en: "Delete options",
    },
    add: {
      uk: "Додати параметр",
      en: "Add parametr",
    },
    send: {
      uk: "Надіслати",
      en: "Send",
    },
  },
  Alert: {
    save: {
      uk: "Зберегти?",
      en: "Save?",
    },
    delete: {
      uk: "Видалити?",
      en: "Delete?",
    },
    translationError: {
      uk: "Потрібно додати переклади тексту англійською або українською",
      en: "It is necessary to add translations of the text in English or Ukrainian",
    },
  },
  Select: {
    gramms: {
      uk: "г",
      en: "g",
    },
    ml: {
      uk: "мл",
      en: "ml",
    },
    enterTag: {
      uk: "Введіть тег і натисніть Enter",
      en: "Enter the tag and press Enter",
    },
    unpaid: {
      uk: "Не оплачен",
      en: "Unpaid",
    },
    restaurant: {
      uk: "По ресторану",
      en: "By restaurant",
    },
    orderStatus: {
      uk: "По статусу",
      en: "By status",
    },
    id: {
      uk: "По ID",
      en: "By ID",
    },
    createdAt: {
      uk: "По датi створення",
      en: "By creation date",
    },
    new: {
      uk: "Новий",
      en: "News",
    },
    working: {
      uk: "Готується",
      en: "Getting ready",
    },
    delivering: {
      uk: "Доставляється",
      en: "Delivered",
    },
    rejected: {
      uk: "Відмовлений",
      en: "Canceled",
    },
    done: {
      uk: "Завершений",
      en: "Completed",
    },
    yes: {
      uk: "Так",
      en: "Yes",
    },
    no: {
      uk: "Ні",
      en: "No",
    },
    view: {
      uk: "Перегляд",
      en: "View",
    },
    change: {
      uk: "Редагування",
      en: "Edit",
    },
    hide: {
      uk: "Приховати",
      en: "Hide",
    },
    processing: {
      uk: "Готується",
      en: "Treatment",
    },

    "payment-error": {
      uk: "Помилка при оплаті",
      en: "Payment error",
    },
    "payment-paid": {
      uk: "Сплачено",
      en: "Paid",
    },
    pendingPayment: {
      uk: "Очікування платежу",
      en: "Pending payment",
    },
    completed: {
      uk: "Завершений",
      en: "Completed",
    },
    canceled: {
      uk: "Скасовано",
      en: "Canceled",
    },
  },
  Placeholders: {
    minDeliveryCount: {
      uk: "Число має бути не менше 0",
      en: "The number must be at least 0",
    },
    searchByname: { uk: "Пошук", en: "Search" },
    example: {
      uk: "Наприклад, Пн - Нд",
      en: "Example, Mn - Fr",
    },
    requiredFields: { uk: "Поле обов'язкове", en: "Field is required" },
    loadMore: { uk: "Завантажити ще", en: "Load more" },
    pickDate: {
      uk: "Обрати дату",
      en: "Pick date",
    },
    loadImg: {
      uk: "Завантажити фото",
      en: "download photo",
    },
    changeImg: {
      uk: "Змінити фото",
      en: "Change photo",
    },
    pickFile: {
      uk: "Прикрепити файл",
      en: "Pick file",
    },
    enterNumber: {
      uk: "Введіть число",
      en: "Введите число",
    },
    enterAddress: {
      uk: "Введіть адрес",
      en: "Введите адрес",
    },
    chooseCategory: {
      uk: "Виберіть категорію",
      en: "Select a category",
    },
    restaurantsTwo: {
      uk: "Виберіть не менше двох ресторанів",
      en: "Please select at least two restaurants",
    },
    enterEmail: {
      uk: "Введіть e-mail",
      en: "Enter e-mail",
    },
    enterPhone: {
      uk: "Введіть телефон",
      en: "Enter phone",
    },
    chooseRestaurant: {
      uk: "Виберіть ресторан",
      en: "Choose a restaurant",
    },
    chooseRestaurant2: {
      uk: "Вибір ресторану",
      en: "Restaurant selection",
    },
    chooseProduct: {
      uk: "Виберіть продукт",
      en: "Choose a product",
    },
    startRange: {
      uk: "Початок кiлометражу",
      en: "Start of mileage",
    },
    endRange: {
      uk: "Кiнець кiлометражу",
      en: "End of mileage",
    },
    addition: {
      uk: "Добавки",
      en: "Additions",
    },
    searchAddition: {
      uk: "Пошук по добавкам",
      en: "Search by additives",
    },
    parentProduct: {
      uk: "Пошук по бат. продуктам",
      en: "Search by gender products",
    },
    category: {
      uk: "Пошук по категоріям",
      en: "Search by category",
    },
    filterByCategory: {
      uk: "Фільтр по категоріям",
      en: "Filter by category",
    },
    searchByRests: {
      uk: "Пошук по ресторанам",
      en: "Restaurant search",
    },
    mass: {
      uk: "Введіть число",
      en: "Enter the number",
    },
    massLength: {
      uk: "Не менш двозначного",
      en: "At least double digit",
    },
    g: {
      uk: "Грамми",
      en: "Grams",
    },
    options: {
      title: {
        uk: "Назва опції",
        en: "Option name",
      },
      price: {
        uk: "Ціна опції",
        en: "Option price",
      },
      photo: {
        uk: "Фото",
        en: "Photo",
      },
    },
    colorPicker: {
      uk: "Колiр",
      en: "Color",
    },
    minTwo: {
      uk: "Не менш 2 букв",
      en: "At least 2 letters",
    },
    minThree: {
      uk: "Не менш 3 букв",
      en: "At least 3 letters",
    },
    minFour: {
      uk: "Не менш 4 букв",
      en: "At least 4 letters",
    },
    minSix: {
      uk: "Не менш 6 букв",
      en: "At least 6 letters",
    },
    noOptionsMessage: {
      uk: "Нема збігів :(",
      en: "No match :(",
    },
    loadingMessage: {
      uk: "Завантаження...",
      en: "Loading...",
    },
    searchInput: {
      uk: "Пошук за випадковим збігом",
      en: "Search by match",
    },
    search: {
      uk: "Пошук по замовленням",
      en: "Order search",
    },
    tapsLat: {
      uk: "Введiть широту",
      en: "Enter latitude",
    },
    tapsLng: {
      uk: "Введiть довготу",
      en: "Enter Longitude",
    },
    tapsLinks: {
      uk: "Введiть посилання",
      en: "Enter link",
    },
  },
  TableTitles: {
    hide: {
      uk: "Приховати",
      en: "Hide",
    },
    view: {
      uk: "Переглядати",
      en: "View",
    },
    change: {
      uk: "Редагувати",
      en: "Change",
    },
    delete: {
      uk: "Редагувати та видаляти",
      en: "Delete",
    },
    additions: { uk: "Опції для продуктів", en: "Additions" },
    landings: { uk: "Лендінги", en: "Landings" },
    settings: { uk: "Налаштування", en: "Settings" },
    seo: { uk: "SEO", en: "SEO" },
    ordersHistory: { uk: "Архив", en: "Archive" },
    banner: { uk: "Баннери", en: "Banners" },
    policy: {
      uk: "Політика конфідиціальності",
      en: "Policy",
    },
    defaultVariation: {
      uk: "Базова варіація",
      en: "Default variation",
    },
    paid: {
      uk: "Оплачено",
      en: "Paid",
    },
    endTime: { uk: "До", en: "To" },
    startTime: {
      uk: "З",
      en: "From",
    },
    telegram: {
      uk: "Telegram",
      en: "Telegram",
    },
    workTime: {
      uk: "Час роботи",
      en: "Working time",
    },
    orderAcceptTime: {
      uk: "Час прийому замовлень",
      en: "Time of receiving orders",
    },
    userAgreement: {
      uk: "Угода користувача",
      en: "User agreement",
    },
    orderAccept: {
      uk: "Прийом замовлень",
      en: "Acceptance of orders",
    },
    onlineMenu: {
      uk: "Офлайн меню (pdf)",
      en: "Offline menu (pdf)",
    },
    infiniteStock: {
      uk: "Безсрочна акція",
      en: "Unlimited promotion",
    },
    concert: {
      uk: "Концерти",
      en: "Concerts",
    },
    amountOfProducts: {
      uk: "Кілкість продуктів",
      en: "Number of products",
    },
    paymentBonuses: {
      uk: "З урахуванням бонусів",
      en: "Including bonuses",
    },
    archive: {
      uk: "Архів",
      en: "Archive",
    },
    deliveryTime: {
      uk: "Час доставки",
      en: "Delivery time",
    },
    position: {
      uk: "№",
      en: "№",
    },
    hasVariations: {
      uk: "Є варіації",
      en: "There are variations",
    },
    addition: {
      uk: "Додатки",
      en: "Additives",
    },
    used: {
      uk: "Викоростовано",
      en: "Performed",
    },
    operation: {
      uk: "Операцiя",
      en: "Operation",
    },
    g: {
      uk: "Вага",
      en: "The weight",
    },
    news: {
      uk: "Новини",
      en: "News",
    },
    bonusesToOrders: {
      uk: "Відсоток оплати бонусами при замовленні",
      en: "Percentage of payment with bonuses when ordering",
    },
    usersSearch: {
      uk: "Пошук по користувачам",
      en: "Search by user",
    },

    noInfo: {
      uk: "Немає інформації",
      en: "No information",
    },
    priceToDoor: {
      uk: "Оплата доставки",
      en: "Delivery payment",
    },
    freeDeliverySum: {
      uk: "Сумма до якої безкоштовна доставка",
      en: "Сумма до которой бесплатная доставка",
    },
    priceToHome: {
      uk: "Ціна до дому",
      en: "Price to the house",
    },
    default: {
      uk: "За замовчуванням",
      en: "Default",
      true: {
        uk: "Так",
        en: "Yes",
      },
      false: {
        uk: "Ні",
        en: "No",
      },
    },
    field: {
      uk: "Ім'я",
      en: "Name",
    },
    incRest: {
      uk: "По ID замовлення",
      en: "By ID order",
    },
    id: {
      uk: "По ID",
      en: "By ID",
    },
    lastUpdateDate: {
      uk: "Дата змiни",
      en: "Date of change",
    },
    publicationDate: {
      uk: "Дата публікації/Змiни",
      en: "Publication Date/Changes",
    },
    restoraunt: {
      uk: "Ресторан",
      en: "Restaurant",
    },
    paymentType: {
      uk: "Тип оплаты",
      en: "Payment type",
    },
    promo: {
      uk: "Промокод",
      en: "Promo code",
    },
    dataStart: {
      uk: "Початок акцiї",
      en: "Promotion start",
    },
    dataEnd: {
      uk: "Кiнець акциiї",
      en: "End of promotion",
    },
    freeDelivery: {
      uk: "Безкоштовна доставка",
      en: "Free shipping",
    },
    stockDelivery: {
      uk: "Знижка при самовивозi",
      en: "Pickup Discount",
    },
    label: {
      uk: "Label",
      en: "Label",
    },
    guests: {
      uk: "Кількість гостей",
      en: "Number of guests",
    },
    addressLabel: {
      uk: "Прев'ю адреси",
      en: "Address Preview",
    },
    email: {
      uk: "E-mail",
      en: "E-mail",
    },
    license: {
      uk: "Ліцензія",
      en: "License",
    },
    facebook: {
      uk: "Facebook",
      en: "Facebook",
    },
    instagram: {
      uk: "Instagram",
      en: "Instagram",
    },
    longitude: {
      uk: "Довгота",
      en: "Longitude",
    },
    latitude: {
      uk: "Широта",
      en: "Latitude",
    },
    restaurant: {
      uk: "Ресторан",
      en: "Restaurant",
    },
    bonuses: {
      uk: "Бонуси",
      en: "Bonuses",
    },
    date: {
      uk: "Дата",
      en: "Date",
    },
    number: {
      uk: "№",
      en: "№",
    },
    slug: {
      uk: "Заголовок",
      en: "Header",
    },
    bonusesPercents: {
      uk: "Сума оплати бонусами в %",
      en: "Bonus payment amount in %",
    },
    timeEnd: {
      uk: "Залишилось часу",
      en: "Time left",
    },
    rate: {
      uk: "Оцiнка",
      en: "Grade",
    },
    product: {
      uk: "Продукт",
      en: "Product",
    },
    stocks: {
      uk: "Акції",
      en: "Stock",
    },
    stocks_main_view: {
      uk: "Перегляд",
      en: "View",
    },
    stocks_main_change: {
      uk: "Редагування",
      en: "Edit",
    },
    feedback: {
      uk: "Вiдгуки",
      en: "Edit",
    },
    referals: {
      uk: "Реферальні коди",
      en: "Referral codes",
    },
    booking: {
      uk: "Заявки на бронювання",
      en: "Booking requests",
    },
    null: {
      uk: "Немає",
      en: "No",
    },
    city: {
      uk: "Місто",
      en: "City",
    },
    percent: {
      uk: "%",
      en: "%",
    },
    endStock: {
      uk: "Акція закінчилась",
      en: "Promotion ended",
    },
    expiresDate: {
      uk: "Закiнчено",
      en: "Completed",
    },
    admin: {
      uk: "Адміністратори",
      en: "Administrators",
    },
    message: {
      uk: "Повідомлення",
      en: "Message",
    },
    processing: {
      uk: "Готується",
      en: "Treatment",
    },
    staff: {
      uk: "Персонал",
      en: "Staff",
    },
    roles: {
      uk: "Посади",
      en: "Positions",
    },
    users: {
      uk: "Користувачі",
      en: "Users",
    },
    dateOfBirth: {
      uk: "Дата народження",
      en: "Date of Birth",
    },
    orders: {
      uk: "Замовлення",
      en: "Orders",
    },
    history: {
      uk: "Архів замовлень",
      en: "Order archive",
    },
    deleting: {
      uk: "Bидалення",
      en: "Removal",
    },
    categories: {
      uk: "Категорiї",
      en: "Categories",
    },
    similar: {
      uk: "Cхоже",
      en: "Similar",
    },
    password: {
      uk: "Пароль",
      en: "Password",
    },
    deliveringPrice: {
      uk: "Ціна доставки",
      en: "Shipping cost",
    },
    archived: {
      uk: "Доданий в архів",
      en: "Added to the archive",
    },
    favourites: {
      uk: "Вибране",
      en: "Favorites",
    },
    addToSimilar: {
      uk: "Додати до схожого",
      en: "Add to Similar",
    },
    averageStars: {
      uk: "Середня оцінка",
      en: "Average rating",
    },
    novelty: {
      uk: "Новинка",
      en: "New",
      true: {
        uk: "Так",
        en: "Yes",
      },
      false: {
        uk: "Ні",
        en: "No",
      },
    },
    promotionalOffer: {
      uk: "Акція 2+1",
      en: "Promotion 2+1",
      true: {
        uk: "Так",
        en: "Yes",
      },
      false: {
        uk: "Ні",
        en: "No",
      },
    },
    novetly: {
      uk: "Новинка",
      en: "New",
      true: {
        uk: "Так",
        en: "Yes",
      },
      false: {
        uk: "Ні",
        en: "No",
      },
    },
    starsCount: {
      uk: "Kількість відгуків",
      en: "Number of reviews",
    },
    stock: {
      uk: "В наявності",
      en: "In stock",
    },
    category: {
      uk: "Kатегорія",
      en: "Category",
    },
    price: {
      uk: "Ціна",
      en: "Price",
    },
    recomendedSize: {
      uk: "Рекомендований розмір 800х600 (4:3)",
      en: "Recommended size 800x600 (4:3)",
    },
    products: {
      uk: "Продукти",
      en: "Products",
    },
    canNotDeleteDefaultValue: {
      uk: "Неможливо видалити значення яке є за замовчуванням",
      en: "It is not possible to delete the default value",
    },
    added: { uk: "Додано", en: "Added" },
    add: { uk: "Додати", en: "Add" },
    restaurants: {
      uk: "Ресторани",
      en: "Restaurants",
    },
    restaurantsList: {
      uk: "Список ресторанів",
      en: "List of restaurants",
    },

    productsCount: {
      uk: "Кількість продуктів",
      en: "Number of products",
    },
    subCategories: {
      uk: "Підкатегорії",
      en: "Subcategories",
    },
    subCategoriesCount: {
      uk: "Кількість підкатегорій",
      en: "Number of subcategories",
    },
    parentCategory: {
      uk: "Батьківська категорія",
      en: "Parent category",
    },
    parentProduct: {
      uk: "Батьківський продукт",
      en: "Parent product",
    },
    increment: {
      uk: "№",
      en: "№",
    },
    fullname: {
      uk: "ПІБ",
      en: "Full name",
    },
    nutritionalValue: {
      uk: "Харчова цінність",
      en: "The nutritional value",
    },
    hidden: {
      uk: "Прихована",
      en: "Hidden",
      true: {
        uk: "Так",
        en: "Yes",
      },
      false: {
        uk: "Ні",
        en: "No",
      },
    },
    login: {
      uk: "Логін",
      en: "Login",
    },
    role: {
      uk: "Посада",
      en: "Job title",
    },
    sum: {
      uk: "Сума",
      en: "Sum",
    },
    code: {
      uk: "Реферальний код",
      en: "Referral code",
    },
    itemsTotalPrice: {
      uk: "Сума",
      en: "Sum",
    },
    user: {
      uk: "Користувач",
      en: "User",
    },
    orderStatus: {
      uk: "Статус",
      en: "Status",
    },
    comment: {
      uk: "Коментар",
      en: "Comment",
    },
    delivery: {
      uk: "Доставка",
      en: "Delivery",
    },
    deliveryPrice: {
      uk: "Ціна за доставку",
      en: "Delivery price",
    },
    recipient: {
      uk: "Отримувач",
      en: "Recipient",
    },
    call: {
      uk: "Подзвонити",
      en: "Call",
    },
    phone: {
      uk: "Телефон",
      en: "Phone",
    },
    photo: {
      uk: "Фото",
      en: "Photo",
    },
    sitePhoto: {
      uk: "Фото для сайту",
      en: "Photo for site",
    },
    appPhoto: {
      uk: "Фото для додатку",
      en: "Photo for app",
    },
    headerPhoto: {
      uk: "Фото в шапку додатка",
      en: "Photo in the application header",
    },
    dateStart: {
      uk: "Початок акції",
      en: "Promotion start",
    },
    dateEnd: {
      uk: "Кінець акції",
      en: "End of promotion",
    },
    productsList: {
      uk: "Список товарів",
      en: "List product",
    },
    categoriesList: {
      uk: "Список категорій",
      en: "List categories",
    },

    name: {
      uk: "Назва",
      en: "Name",
    },
    orderNumber: {
      uk: "Номер замовлення",
      en: "Number order",
    },
    pickup: {
      uk: "Самовивіз",
      en: "Pickup",
    },
    subcategory: {
      uk: "Підкатегорія",
      en: "Subcategory",
    },
    oneCategory: {
      uk: "Категорія",
      en: "Category",
    },

    options: {
      uk: "Опції",
      en: "Options",
    },
    options_addition: {
      uk: "Додатково(Вага/шт)",
      en: "Optional(Weight/Piece)",
    },
    options_name: {
      uk: "Назва опції",
      en: "Name options",
    },
    options_price: {
      uk: "Ціна опції",
      en: "Price option",
    },
    title: {
      uk: "Назва",
      en: "Name",
    },
    calories: {
      uk: "Калорійність",
      en: "Calories",
    },
    fats: {
      uk: "Жири",
      en: "Fats",
    },
    carbohydrates: {
      uk: "Вуглеводи",
      en: "Carbohydrates",
    },
    proteins: {
      uk: "Білки",
      en: "Squirrels",
    },
    weight: {
      uk: "Вага",
      en: "The weight",
    },
    description: {
      uk: "Опис",
      en: "Description",
    },
    notLongText: {
      uk: "Недостатня довжина слова або таке слово вже додане",
      en: "The word is not long enough or such a word has already been added",
    },
    enterAllFieldInVariations: {
      uk: "Заповніть всі значення ваги і ціни в обраних варіаціях",
      en: "Fill in all the weight and price values ​​in the selected variations",
    },
    amount: {
      uk: "Кількість",
      en: "Amount",
    },
    courier: {
      uk: "Кур'єр",
      en: "Courier",
    },
    withoutComment: {
      uk: "[Без комментарів]",
      en: "[No comment]",
    },
    type: {
      uk: "Тип доставки",
      en: "Delivery type",
    },
    address: {
      uk: "Адрес",
      en: "Address",
    },
    statusTypes: {
      unpaid: {
        uk: "Не оплачено",
        en: "Unpaid",
      },
      canceled: {
        uk: "Скасовано",
        en: "Canceled",
      },
      pendingPayment: {
        uk: "Очікування оплати",
        en: "Pending payment",
      },
      completed: {
        uk: "Завершенний",
        en: "Completed",
      },
      "payment-paidByCash": {
        uk: "Оплата готiвкою",
        en: "Cash payment",
      },
      new: {
        uk: "Новий",
        en: "New",
      },
      working: {
        uk: "Готується",
        en: "getting ready",
      },
      delivering: {
        uk: "Доставляється",
        en: "Delivered",
      },
      rejected: {
        uk: "Відмовленно",
        en: "Canceled",
      },
      done: {
        uk: "Завершений",
        en: "Completed",
      },
      processing: {
        uk: "Готується",
        en: "In processing",
      },
      "payment-processing": {
        uk: "В обробці",
        en: "In processing",
      },
      failure: {
        uk: "Помилка при оплаті",
        en: "Payment error",
      },
      "payment-paid": {
        uk: "Сплачено",
        en: "Paid",
      },
      paid: {
        uk: "Сплачено",
        en: "Paid",
      },
      newByCash: {
        uk: "новий(готівка)",
        en: "new(cash)",
      },
      newByOnline: {
        uk: "новий(онлайн)",
        en: "new(online)",
      },
      "payment-paidByOnline": {
        uk: "Сплата онлайн",
        en: "Online payment",
      },
    },
    createdAt: {
      uk: "Створений",
      en: "Created",
    },
    updatedAt: {
      uk: "Оновлений",
      en: "Updated",
    },
    itemsPrice: {
      uk: "Сума",
      en: "Total cost",
    },
    deliveryTerms: { uk: "", en: "" },
    commonPrice: {
      uk: "Загальна вартість",
      en: "Common price",
    },
    changedOrderStatus: {
      uk: "",
      en: "",
    },
  },
  AuthPage: {
    title: {
      uk: "Авторизація",
      en: "Authorization",
    },
    loginPlaceholder: {
      uk: "Логін",
      en: "Login",
    },
    passwordPlaceholder: {
      uk: "Пароль",
      en: "Password",
    },
  },
  HomePage: {
    title: {
      uk: "Головна",
      en: "Home",
    },
  },
  OrdersPage: {
    title: {
      uk: "Замовлення",
      en: "Orders",
    },
  },
  OrdersDetailPage: {
    title: {
      uk: "Замовлення",
      en: "Order",
    },
  },
  CategoriesPage: {
    title: {
      uk: "Категорії",
      en: "Categories",
    },
    pickRestaurant: {
      uk: "Виберіть ресторан",
      en: "Choose a restaurant",
    },
  },
  TotalCompletedByStatus: {
    uk: "Усього завершених за статусами:",
    en: "Total completed by status:",
  },
  TotalCompletedByReferals: {
    uk: "Використано реферальних кодів:",
    en: "Total completed by referral codes:",
  },
  TotalCompleted: {
    uk: "Усього заказів:",
    en: "Total orders:",
  },
  TotalReferals: {
    uk: "Усього реферальних кодів:",
    en: "Total referral codes:",
  },
  TotalUsers: {
    uk: "Усього користувачів:",
    en: "Total Users: ",
  },
  UsedReferals: {
    uk: "Використані реферальні коди:",
    en: "Referral codes used:",
  },
  WorkingOrders: {
    uk: "Робочі замовлення:",
    en: "Work Orders:",
  },
  DeliveringOrders: {
    uk: "Замовлення що доставляються:",
    en: "Delivery orders:",
  },
  DoneOrders: {
    uk: "Виконано замовлень:",
    en: "Completed orders:",
  },
  DeliveryPage: {
    title: {
      uk: "Доставка та оплата",
      en: "Shipping and payment",
    },
  },
  NewByCashOrders: {
    uk: "Нових за касовими ордерами:",
    en: "New by cash orders:",
  },
  NewUsers: {
    uk: "Нових користувачів:",
    en: "New Users:",
  },
  measurement: { uk: "Вимірювання", en: "Measurement" },
  PaymentErrorOrders: {
    uk: "Замовлення з помилкою оплати:",
    en: "Orders with payment error: ",
  },
  RejectedOrders: {
    uk: "Відхилені замовлення:",
    en: "Rejected orders:",
  },
  PaymentPaidOrders: {
    uk: "Сплачені замовлення:",
    en: "Paid Orders:",
  },
  CategoriesCreatePage: {
    title: {
      uk: "Створити категорію",
      en: "Create category",
    },
  },
  NotificationsPage: {
    title: {
      uk: "Сторінка повідомлень",
      en: "Notification Page",
    },
    new_message: {
      uk: "Нове замовлення",
      en: "New order",
    },
    save_message: {
      uk: "Сохранено",
      en: "Saved",
    },
  },
  BookingPage: {
    title: {
      uk: "Бронювання столиків",
      en: "Table reservation",
    },
  },
  ReferalsPage: {
    title: {
      uk: "Сторінка реферальних кодів",
      en: "Referral codes page",
    },
  },
  StocksCreatePage: {
    title: {
      uk: "Створити акцію",
      en: "Create promotion",
    },
  },
  StocksDetailPage: {
    title: {
      uk: "Картка акції",
      en: "Action card",
    },
  },
  BannersCreatePage: {
    title: {
      uk: "Створити банер",
      en: "Create banner",
    },
  },
  BannersDetailPage: {
    title: {
      uk: "Картка банеру",
      en: "Banner card",
    },
  },
  CategoriesDetailPage: {
    title: {
      uk: "Картка категорії",
      en: "Category card",
    },
  },
  BookingDetailPage: {
    title: {
      uk: "Редагувати столик",
      en: "Edit table",
    },
  },
  ReferalsCreatePage: {
    title: {
      uk: "Cтворення реферального коду",
      en: "Generating a referral code",
    },
  },
  ReferalsDetailPage: {
    title: {
      uk: "Редагування",
      en: "Editing",
    },
  },
  RestaurantsPage: {
    title: {
      uk: "Ресторани",
      en: "Restaurants",
    },
  },
  ProductsCreatePage: {
    title: {
      uk: "Створити продукт",
      en: "Create product",
    },
  },
  ProductsDetailPage: {
    title: {
      uk: "Продукт",
      en: "Product",
    },
  },
  RestaurantsCreatePage: {
    title: {
      uk: "Створити ресторан",
      en: "Create a restaurant",
    },
  },
  BookingCreatePage: {
    title: {
      uk: "Створити бронь столика",
      en: "Create a table reservation",
    },
  },
  RestaurantsDetailPage: {
    title: {
      uk: "Ресторан",
      en: "Restaurant",
    },
  },
  ProductsPage: {
    title: {
      uk: "Продукти",
      en: "Products",
    },
    basicInformation: { uk: "Основна інформація", en: "Basic information" },
  },
  StocksPage: {
    title: {
      uk: "Акції",
      en: "Stock",
    },
  },
  BannersPage: {
    title: {
      uk: "Баннери",
      en: "Banners",
    },
  },
  SalesPage: {
    title: {
      uk: "Акцiї",
      en: "Stock",
    },
  },
  SalesCreatePage: {
    title: {
      uk: "Створити акцiю",
      en: "Create a stock",
    },
  },
  SalesDetailPage: {
    title: {
      uk: "Акцiя",
      en: "Stock",
    },
  },
  UsersPage: {
    title: {
      uk: "Користувачі",
      en: "Users",
    },
  },
  UsersPageBirthday: {
    title: {
      uk: "День народження",
      en: "Birthday",
    },
  },
  UsersPageBirthdayChange: {
    title: {
      uk: "Змiнити дату народження",
      en: "Change date of birth",
    },
  },
  UsersDetailPage: {
    title: {
      uk: "Користувач",
      en: "User",
    },
  },
  StaffPage: {
    title: {
      uk: "Персонал",
      en: "Staff",
    },
  },
  StaffCreatePage: {
    title: {
      uk: "Створити співробітника",
      en: "Create employee",
    },
  },
  StaffDetailPage: {
    title: {
      uk: "Співробітник",
      en: "Employee",
    },
  },
  RolesPage: {
    title: {
      uk: "Посади",
      en: "Positions",
    },
  },
  RoleCreatePage: {
    title: {
      uk: "Створити посаду",
      en: "Create a position",
    },
  },
  RoleDetailPage: {
    title: {
      uk: "Посада",
      en: "Job title",
    },
  },
  ArchivePage: {
    title: {
      uk: "Архів замовлень",
      en: "Order archive",
    },
  },
  FeedbackPage: {
    title: {
      uk: "Вiдгуки",
      en: "Feedback",
    },
  },
  SettingsPage: {
    priceChange: {
      uk: "Зміна ціни, %",
      en: "Price change, %",
    },
    priceChangePlaceholder: {
      uk: "Введіть значення від -99 до 99",
      en: "Input value between -99 and 99",
    },
    title: {
      uk: "Налаштування",
      en: "Setting",
    },
    langSwitchLabel: {
      uk: "Змінити мову :",
      en: "Change language :",
    },
  },
  ErrorPriceChange: {
    uk: "Значення зміни ціни має бути в межах від -99 до 99",
    en: "Price change value must be between -99 and 99",
  },
  ErrorLanguage: {
    uk: "Введіть корректну назву англійською або українською",
    en: "Enter the correct name in English or Ukrainian",
  },
  ErrorLength: {
    uk: "Введіть коректну довжину поля",
    en: "Please enter a valid field length",
  },
  ErrorProcent: {
    uk: "Не менше 0 і не більше 100",
    en: "Не меньше 0 и не больше 100",
  },
  ErrorRestaurants: {
    uk: "Виберіть ресторан",
    en: "Выбирете ресторан",
  },
  chooseValue: {
    uk: "Виберіть значення",
    en: "Choose a value",
  },
  ErrorRestoran: {
    uk: "Виберіть ресторан",
    en: "Выбирете ресторан",
  },
  ErrorLinks: {
    uk: "Введіть коректне посилання в полi",
    en: "Please enter a valid link",
  },
  ErrorLabelRestaurants: {
    uk: "Введіть коректне значення",
    en: "Please enter a valid value",
  },
  ErrorTypeNumber: {
    uk: "Введiть числове значення",
    en: "Enter a numeric value",
  },
  MinMax: {
    uk: "Довжина повина бути вiд 3 до 300 символiв",
    en: "Length must be between 3 and 300 characters",
  },
  ErrorPhoto: {
    uk: "Необхідно додати фото",
    en: "Need to add a photo",
  },
  ErrorField: {
    uk: "Оберiть значення поля",
    en: "Select field value",
  },
  ErrorVariations: {
    uk: "Потрібно додати хоча б одну варіацію з перекладами назв англійською або українською",
    en: "It is necessary to add at least one variation with translations of names in English or Ukrainian",
  },

  ErrorSize: {
    uk: "Значення повино бути от 0 до 100",
    en: "Value must be between 0 and 100",
  },
  SaveButton: {
    uk: "Зберегти",
    en: "Save",
  },
  ChangePosition: {
    uk: "Змiнити послiдовнiсть ресторанiв",
    en: "Change the sequence of restaurants",
  },
  NewsPage: {
    title: {
      uk: "Новини",
      en: "News",
    },
  },
  OptionsForProductsPage: {
    title: {
      uk: "Опції для продуктів",
      en: "Product options",
    },
    no: {
      uk: "Немає опцій",
      en: "No options",
    },
    CreatePage: {
      uk: "Створити опцію",
      en: "Create option",
    },
    DetailPage: {
      uk: "Детальна сторінка",
      en: "Detail page",
    },
    error: {
      length: {
        uk: "Введіть корректну довжину поля: ",
        en: "Choose the correct field length:",
      },
      name: {
        uk: "Назва опції",
        en: "Option name",
      },
      photo: {
        uk: "Необхідно додати фото",
        en: "Need to add a photo",
      },
      price: {
        uk: "Ціна ",
        en: "Price",
      },
    },
  },
  NewsCreatePage: {
    title: {
      uk: "Створити новину",
      en: "Create news",
    },
  },
  NewsDetailPage: {
    title: {
      uk: "Картка новини",
      en: "News card",
    },
  },
  RestarauntNotDefined: {
    uk: "Ресторан не вiдомий",
    en: "Restaurant not known",
  },
  RadioButtons: {
    user: {
      uk: "Користувач",
      en: "User",
    },
    restoraunt: {
      uk: "Ресторан",
      en: "Restaurant",
    },
    paymentType: {
      uk: "Тип оплати",
      en: "Payment type",
    },
  },
  Items: {
    filterByDate: {
      uk: "Часовий проміжок для експорта в Excel:",
      en: "Hourly interval for export to Excel:",
    },
    NoLength: {
      uk: "Немає замовлень",
      en: "No orders",
    },
    Clear: {
      uk: "Очистити фiльтри",
      en: "Clear filters",
    },
  },
  Variants: {
    property: {
      uk: "Параметр",
      en: "Parameter",
    },
    optionName: {
      uk: "Опція",
      en: "Option",
    },
  },
  ArrayErrorLength: {
    uk: "Немає нi одного елемента в",
    en: "There are no elements in",
  },
  currency: {
    uk: "грн",
    en: "uah",
  },
};
