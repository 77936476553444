import styled from "styled-components";

export const VariationItemStyled = styled.div<{ $open: boolean }>`
  max-height: ${({ $open }) => ($open ? "1000px" : "80px")};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  background-color: #f9f9f9;
`;
export const SingleBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 16px;
    font-weight: 600;
  }
`;

export const ButtonAddBlock = styled.div<{ $inForm?: boolean; $open: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  align-items: color-interpolation-filters;
  svg {
    transition: rotate 0.2s;
    rotate: ${({ $open }) => ($open ? "180deg" : "0deg")};
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
  button {
    padding: 8px 20px;
    border-radius: 12px;
    background-color: ${({ $inForm }) => ($inForm ? "#E5F7F4" : "#03cb83")};
    height: 45px;
    outline: none;
    border: none;
    color: ${({ $inForm }) => ($inForm ? "#03CB83" : "#ffffff")};
    cursor: pointer;
  }
`;
export const SubItemsWrap = styled.div`
  margin-top: 10px;
  border-top: 2px dashed #e1e1e1;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SubVariationWrap = styled.div<{ $inForm?: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${({ $inForm }) => ($inForm ? "95px" : "50px")};
  transition: height 0.3s;
  justify-content: flex-end;
  gap: 12px;
`;
export const ParamsWrap = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
export const SubTitle = styled.p`
  font-weight: 600;
  max-width: 400px;
  /* margin-bottom: 10px; */
  font-size: 16px;
`;

export const SubParamsWrap = styled.div`
  display: flex;
  gap: 20px;
`;

export const BottomWrap = styled.div`
  display: flex;
  gap: 12px;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
