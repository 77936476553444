import React, { useState, useEffect, useRef } from "react";

import photoEdit from "./../../assets/icons/refresh.svg";
import photoEditBlack from "./../../assets/icons/edit-photo.svg";
import deletePhoto from "./../../assets/icons/delete.svg";

import "./photoPicker.scss";
import { TPhotoPicker } from "./types";

export const PhotoPicker = ({
  name,
  file,
  onChange,
  index,
  onDelete,
  isHeader = false,
  headerTitle,
  width = "219px",
  height = `124px`,
}: TPhotoPicker) => {
  const [photo, setPhoto] = useState<string>(
    file ? (typeof file === "string" ? file : URL.createObjectURL(file)) : ""
  );
  const [hasPhoto, setHasPhoto] = useState<boolean>(Boolean(file));
  const inputRef = useRef<HTMLInputElement>(null);

  const Events = {
    buttonClickHandler: () => {
      const input = inputRef.current as HTMLInputElement;

      input.onchange = (e) => {
        const input = e.target as HTMLInputElement;

        const newFile = input.files ? input.files[0] : null;

        if (newFile) {
          onChange && onChange(newFile, index as number, name as string);
        } else {
          onDelete && onDelete();
        }
        (e.target as HTMLInputElement).value = "";
      };

      input.click();
    },
  };

  useEffect(() => {
    if (file) {
      if (typeof file === "string") {
        setPhoto(file);
      } else {
        setPhoto(URL.createObjectURL(file));
      }
      setHasPhoto(true);
    } else {
      setPhoto("");
      setHasPhoto(false);
    }
  }, [file]);

  return (
    <div className="container">
      <div className="container-header">
        {isHeader && (
          <>
            <h6 className="title-h6">{headerTitle}</h6>
            <img
              className="edit-photo"
              src={photoEditBlack}
              alt="edit"
              onClick={Events.buttonClickHandler}
            />
          </>
        )}
      </div>
      <div className="container-photo-picker">
        <div className={`photo-picker-block ${hasPhoto ? "has-photo" : ""}`}>
          {!isHeader && (
            <>
              <img
                className={`edit-photo-inside ${index === 0 && 'active'}`}
                src={photoEdit}
                alt="edit"
                onClick={Events.buttonClickHandler}
              />
              {index !== 0 && (
                <img
                  className="delete-photo-inside"
                  src={deletePhoto}
                  onClick={onDelete}
                  alt="delete"
                />
              )}
            </>
          )}
          <button
            className="image-button"
            onClick={!file ? Events.buttonClickHandler : () => {}}
            style={{
              backgroundImage: `url("${photo.replace(/"/g, '\\"')}")`,
              width: `${width}`,
              height: `${height}`,
            }}
          >
            <div className={`block ${photo ? "hidden" : ""}`}>
              {!isHeader && <img className="plus-icon" alt="add-file" />}
              <span className="text">Завантажте або перетягніть файл(и)</span>
            </div>
            <input
              className="photo-input"
              name={name + "input"}
              ref={inputRef}
              type="file"
            />
          </button>
        </div>
      </div>
    </div>
  );
};
