import React from "react"
import { IInputProps } from "./types"

const Input: React.FC<IInputProps> = ({
  type = "text",
  name,
  autocomplete,
  label,
  value,
  multiple,
  placeholder,
  isValid = true,
  inputHandler,
  pressHandler = () => {},
  onFocus,
  addClassName,
  pattern,
}) => {
  return (
    <div className="input-wrapper">
      {label ? <h6 className="input-label">{label}</h6> : null}

      <input
        type={type}
        pattern={pattern}
        name={name}
        value={value}
        multiple={multiple}
        placeholder={placeholder}
        className={`input  ${addClassName ? addClassName : ""}`}
        autoComplete={autocomplete}
        onChange={inputHandler}
        onKeyPress={pressHandler}
        onFocus={onFocus}
        accept={
          type === "file" ? (name === "pdf" ? "pdf" : "image/*") : undefined
        }
        style={{
          border: isValid ? "1px solid #E6E6E6" : `1px solid #FB0C0C`,
        }}
      />
    </div>
  )
}

export default Input
