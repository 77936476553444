import styled from "styled-components";
export const MainInfoWrap = styled.div`
  border-right: 1px solid #ededed;
  width: 100%;
  max-width: 753px;
  padding: 12px;
  /* @media (min-width: 1440px) {
    max-width: 60%;
  } */
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  margin-bottom: 20px;
`;

export const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

export const InputsLine = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const DescriptionWrap = styled.div<{ $error: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 20px 0 20px;
  p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }
  textarea {
    font-size: 14px;
    width: 100%;
    padding: 10px;
    border: 1px solid ${({ $error }) => (!$error ? "#e1e1e1" : "#ca1f1f")};
    border-radius: 8px;
    min-height: 80px;
    outline: none;
    transition: border-color 0.3s;
    &:focus {
      border: 1px solid ${({ $error }) => (!$error ? "#03CB83" : "#ca1f1f")};
    }
  }
  span {
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    color: #ca1f1f;
  }
`;
