import React, { ChangeEvent, useContext, useEffect, useRef } from "react";
import { BottomWrap, Wrapper } from "./styled";
import { useFormContext } from "react-hook-form";
import { TCreateFormTypes } from "pages/Products/types";
import { config, Translater } from "config";
import * as Contexts from "contexts";
import { HiPhoto } from "react-icons/hi2";

export const PhotoContainer = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { language } = useContext(Contexts.LanguageContext);
  const {
    register,
    watch,
    setValue,
    trigger,

    formState: { errors },
  } = useFormContext<TCreateFormTypes>();

  const onDownloadNewPhotoClick = () => {
    inputRef.current?.click();
  };

  const onPhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files?.length) {
      setValue("oldPhoto", null);
      setValue("newPhoto", files);
      trigger("newPhoto");
    }
  };

  const isVisiblePhoto = !!watch("oldPhoto") || !!watch("newPhoto");

  const generateSrc = () => {
    const oldPhoto = watch("oldPhoto");
    const newPhoto = watch("newPhoto");

    if (oldPhoto) {
      return `${config.imageProxy}public/${oldPhoto}`;
    } else if (newPhoto && newPhoto.length > 0) {
      return URL.createObjectURL(newPhoto[0]);
    }
    return "";
  };
  const buttonTitle =
    !watch("oldPhoto") && !watch("newPhoto")
      ? Translater.Placeholders.loadImg[language.slug]
      : Translater.Placeholders.changeImg[language.slug];

  useEffect(() => {
    if (!watch("oldPhoto") && !watch("newPhoto")) {
      register("newPhoto", { required: "true" });
    }
  }, [register, watch]);

  return (
    <Wrapper $error={!!errors.newPhoto?.message}>
      <label className="visually-hidden">
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={onPhotoChange}
        />
      </label>
      {isVisiblePhoto ? (
        <img alt="photo_product" src={generateSrc()} />
      ) : (
        <HiPhoto />
      )}
      <BottomWrap>
        <p>{Translater.TableTitles.recomendedSize[language.slug]}</p>
        <button onClick={onDownloadNewPhotoClick} type="button">
          {buttonTitle}
        </button>
      </BottomWrap>
    </Wrapper>
  );
};
