import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Components from "./components"
import * as Buttons from "../../components/Buttons"

import { useHttp } from "../../hooks"
import { TBanner } from "../../types/items"
import { config, Translater } from "../../config"
import { useParams, useHistory } from "react-router-dom"
import { useValidation } from "../../hooks"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import { getStringFromTranslationsArr } from "../../helpers"
import { TabLanguage } from "../../components/TabLanguage"

export const BannersDetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const history = useHistory()
  const { loading, request } = useHttp()

  const [banner, setBanner] = useState<TBanner | null>(null)

  const [form, setForm] = useState<TBanner>({
    name: [{ value: "", lang: language.slug }],
    description: [{ value: "", lang: language.slug }],
    appPhoto: "",
    sitePhoto: "",
    restaurant: "",
  })
  const [valueLang, setValueLang] = useState<string>(language.slug)
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
  const [appPhoto, setAppPhoto] = useState<File | null>()
  const [sitePhoto, setSitePhoto] = useState<File | null>()

  const schema = useMemo<any>(
    () => ({
      name: {
        condition: form.name.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },
    }),
    [form, Translater, language]
  )
  const { errors, validation } = useValidation(schema)

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "title") {
        const isTitleInArr = form.name.find((item) => item.lang == valueLang)
          ? true
          : false

        if (isTitleInArr) {
          const title = form.name
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang }
              }
              return item
            })
            .filter((item) => item.value)

          setForm({
            ...form,
            name: title as { value: string; lang: string }[],
          })

          return
        }

        const title = form.name
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value)

        setForm({
          ...form,
          name: title as { value: string; lang: string }[],
        })

        return
      }

      if (e.target.name === "description") {
        const isTitleInArr = form.description.find(
          (item) => item.lang == valueLang
        )
          ? true
          : false

        if (isTitleInArr) {
          const description = form.description
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang }
              }
              return item
            })
            .filter((item) => item.value)

          setForm({
            ...form,
            description: description as { value: string; lang: string }[],
          })

          return
        }

        const description = form.description
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value)

        setForm({
          ...form,
          description: description as { value: string; lang: string }[],
        })

        return
      }

      if (e.target.type === "file" && e.target.name == "appPhoto") {
        //@ts-ignore
        setAppPhoto(e.target.files[0])
      }
      if (e.target.type === "file" && e.target.name == "sitePhoto") {
        //@ts-ignore
        setSitePhoto(e.target.files[0])
      }
    },
    saveHandler: async () => {
      try {
        await validation()

        Callbacks.Save()
      } catch (e) {
        console.log("createHandler => e", e)
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.name == "restraunt") {
        setForm({ ...form, restaurant: e.target.value })
        return
      }
      setForm({ ...form, [e.target.name]: e.target.value })
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: TBanner = await request(
          `${config.API}/stocks/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          setBanner(response)
        }
      } catch (e) {}
    },
    Save: async () => {
      try {
        const data = new FormData()

        data.append("title", JSON.stringify(form.name))

        appPhoto && data.append("appPhoto", appPhoto as Blob)
        sitePhoto && data.append("sitePhoto", sitePhoto as Blob)
        data.append("description", JSON.stringify(form.description))
        form?.restaurant &&
          data.append("restaurant", form?.restaurant as string)

        const response = await request(
          `${config.API}/stocks/update/${id}`,
          "PUT",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) history.push("/banners")
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/stocks/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        })

        history.push("/banners")
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [id])

  useEffect(() => {
    if (banner) {
      setForm({
        ...banner,
        name: banner.name,
        appPhoto: banner.appPhoto,
        sitePhoto: banner.sitePhoto,
        description: banner.description,
      })
    }
  }, [banner])

  if (loading || !banner) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={getStringFromTranslationsArr(banner?.name as any, language.slug)}
    >
      <Page.Header
        backButtonTitle={Translater.StocksDetailPage.title[language.slug]}
        backButtonLink="/banners"
      />
      <Buttons.Container
        disabled={loading}
        saveHandler={access.banner.change ? Events.saveHandler : false}
        deleteHandler={access.banner.delete ? Events.deleteHandler : false}
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Components.ConfigBlock
        form={form}
        from={"edit"}
        inputHandler={Events.inputHandler}
        setForm={setForm}
        isClicked={false}
        valueLang={valueLang}
        selectHandler={Events.selectHandler}
        sitePhoto={sitePhoto as File}
        appPhoto={appPhoto as File}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}
