import styled from "styled-components";
export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`;
export const AdditionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const AdditionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  background-color: #f9f9f9;
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  img {
    object-fit: cover;
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
  p {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const ButtonAddBlock = styled.div<{ $inForm?: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  align-items: color-interpolation-filters;

  button {
    padding: 8px 20px;
    border-radius: 12px;
    background-color: ${({ $inForm }) => ($inForm ? "#E5F7F4" : "#03cb83")};
    height: 45px;
    outline: none;
    border: none;
    color: ${({ $inForm }) => ($inForm ? "#03CB83" : "#ffffff")};
    cursor: pointer;
  }
`;
export const CharBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 12px;
  span {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      font-weight: 400;
    }
  }
`;
