import React, { useContext, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { config, Translater } from "config";
import * as Contexts from "contexts";
import { TCreateFormTypes } from "pages/Products/types";
import { useGetAdditions } from "pages/Products/helpers";
import { IAdditions } from "types/items";
import {
  AdditionItem,
  AdditionsList,
  ButtonAddBlock,
  CharBlock,
  Title,
  TitleBlock,
  Wrapper,
} from "./styled";
import { Input } from "components/Input";

export const AdditionsBlock = () => {
  const [search, setSearch] = useState("");
  const { language } = useContext(Contexts.LanguageContext);
  const { control, watch } = useFormContext<TCreateFormTypes>();
  const { append, remove } = useFieldArray({ control, name: "additions" });

  const { additions } = useGetAdditions({
    title: search || undefined,
    restId: watch("restaurant")?.value,
  });

  const onAddClick = (item: IAdditions) => {
    append({ id: item._id, price: item.price, name: item.name });
  };

  const onDeleteClick = (index: number) => {
    remove(index);
  };

  return (
    <Wrapper>
      <Title>{Translater.Placeholders.addition[language.slug]}:</Title>
      <Input
        placeholder={Translater.Placeholders.searchAddition[language.slug]}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <AdditionsList>
        {additions?.map((item) => {
          const indexInForm = watch("additions")?.findIndex(
            (el) => el.id === item._id
          );

          const isElementInForm = indexInForm !== -1;

          const title = item.name?.find(
            (el) => el.lang === language.slug
          )?.value;

          const buttonTitle = isElementInForm
            ? Translater.Buttons.delete[language.slug]
            : Translater.Buttons.addToPopular[language.slug];
          return (
            <AdditionItem key={item._id}>
              <TitleBlock>
                <img
                  alt="addition_photo"
                  src={`${config.imageProxy}public/${item.photo}`}
                />
                <p>{title}</p>
                <CharBlock>
                  <span>
                    <p>{Translater.TableTitles.price[language.slug]}:</p>
                    {`${item.price} ₴`}
                  </span>
                  <span>
                    <p>{Translater.TableTitles.weight[language.slug]}:</p>
                    {`${item.weight} ${
                      item.measurement?.find((el) => el.lang === language.slug)
                        ?.value || ""
                    }`}
                  </span>
                </CharBlock>
              </TitleBlock>
              <ButtonAddBlock $inForm={isElementInForm}>
                <button
                  onClick={() =>
                    isElementInForm
                      ? onDeleteClick(indexInForm)
                      : onAddClick(item)
                  }
                  type="button"
                >
                  {buttonTitle}
                </button>
              </ButtonAddBlock>
            </AdditionItem>
          );
        })}
      </AdditionsList>
    </Wrapper>
  );
};
