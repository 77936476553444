import React from "react"

import * as Table from "../../../../components/Table"
import * as Components from ".."

import { ITableWrapperProps } from "./types"

import { STitles } from "./static"

const TableWrapper: React.FC<ITableWrapperProps> = ({
  items,
  sortHandler,
  reloadHandler,
  page,
}) => {
  return (
    <Table.Table type="products" titles={STitles} sortHandler={sortHandler}>
      {items?.map((item, index) => (
        <Components.Track
          key={item._id}
          order={index + 1 + (page == 1 ? 0 : (page - 1) * 10)}
          data={item}
          reloadHandler={reloadHandler}
        />
      ))}
    </Table.Table>
  )
}

export default TableWrapper
