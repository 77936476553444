import React, { createContext, useState, useEffect } from "react"

import * as Config from "../config"

import {
  ELanguage,
  ILanguage,
  ILangugageContext,
  ILanguageContextActions,
  TElementaryContextProvider,
} from "../types/contexts/language"

const LanguageContext = createContext<ILangugageContext>({
  language: Config.Languages.uk,
  actions: {
    GET_LANGUAGE: () => {},
    SET_LANGUAGE: () => {},
    SYNC_LANGUAGE: () => {},
  },
})

export default LanguageContext

export const LanguageContextProvider: TElementaryContextProvider = ({
  children,
}) => {
  const [language, setLanguage] = useState<ILanguage>(Config.Languages.uk)

  const actions: ILanguageContextActions = {
    GET_LANGUAGE: () => language,
    SET_LANGUAGE: (language) => {
      setLanguage(language)
      localStorage.setItem(ELanguage.LANGUAGE_STORAGE, JSON.stringify(language))
    },
    SYNC_LANGUAGE: () => {
      const response: string | null = localStorage.getItem(
        ELanguage.LANGUAGE_STORAGE
      )

      if (response) {
        const language: ILanguage = JSON.parse(response)
        if (language.slug && language.label) setLanguage(language)
        else setLanguage(Config.Languages.uk)
      }
    },
  }

  useEffect(() => {
    actions.SYNC_LANGUAGE()
  }, [])

  return (
    <LanguageContext.Provider
      value={{
        language,
        actions,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
