import React, { useContext } from "react";

import * as Contexts from "../../../../contexts";
import * as Table from "../../../../components/Table";

import { useHistory } from "react-router-dom";
import { useHttp } from "../../../../hooks";
import { config, Translater } from "../../../../config";
import { ITrackProps } from "./types";
import { getStringFromTranslationsArr } from "../../../../helpers";

const Track: React.FC<ITrackProps> = ({ data, order, reloadHandler }) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request } = useHttp();
  const history = useHistory();

  const Events = {
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },
    editHandler: () => {
      history.push(`/stocks/${data._id}`);
    },
  };

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/stocks/${data._id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        reloadHandler();
      } catch (e) {
        console.log(e);
      }
    },
  };

  return (
    <Table.Track type="stocks">
      <Table.Cell text={`${order}`} />
      <Table.Cell
        type="image"
        imageURL={`${config.imageProxy}public/${data.sitePhoto}`}
      />
      <Table.Cell
        type="image"
        imageURL={`${config.imageProxy}public/${data.appPhoto}`}
      />

      <Table.Cell
        text={getStringFromTranslationsArr(data.name, language.slug)}
      />

      <Table.Cell
        //@ts-ignore
        text={data.percent * 100}
      />

      <Table.Cell
        text={
          //@ts-ignore
          `${Translater.Stocks.days[language.slug]}: ${
            Math.trunc(
              (new Date(data.dateEnd).getTime() - new Date().getTime()) /
                86400000
              //@ts-ignore
            ) > 0
              ? Math.trunc(
                  (new Date(data.dateEnd).getTime() - new Date().getTime()) /
                    86400000
                  //@ts-ignore
                )
              : "0"
          } ${Translater.Stocks.hours[language.slug]}: ${
            Math.trunc(
              //@ts-ignore
              ((new Date(data.dateEnd).getTime() - new Date().getTime()) %
                86400000) /
                3600000
            ) > 0
              ? Math.trunc(
                  ((new Date(data.dateEnd).getTime() - new Date().getTime()) %
                    86400000) /
                    3600000
                )
              : "0"
          }`
        }
      />

      <Table.Cell
        type="control"
        editButton={access?.stocks?.change}
        deleteButton={access?.stocks?.delete}
        editHandler={Events.editHandler}
        deleteHandler={Events.deleteHandler}
      />
    </Table.Track>
  );
};

export default Track;
