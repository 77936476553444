import { TCreateFormTypes } from "pages/Products/types";
import React, { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  DescriptionWrap,
  InputsLine,
  InputsWrap,
  MainInfoWrap,
  Title,
} from "./styled";
import { Translater } from "config";
import * as Contexts from "contexts";
import { useGetCategories, useGetRestaurants } from "pages/Variations/helpers";
import { CustomSelect } from "components/CustomSelect";
import { Input } from "components/Input";
import { VariationsBlock } from "../VariationsBlock/VariationsBlock";
import { AdditionsBlock } from "../AdditionsBlock/AdditionsBlock";

export const MainInfo = () => {
  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<TCreateFormTypes>();

  const { language } = useContext(Contexts.LanguageContext);
  const { restaurant } = useGetRestaurants();
  const { categories } = useGetCategories(watch("restaurant")?.value || "");
  const required = Translater.Placeholders.requiredFields[language.slug];

  const retaurantOptions = restaurant?.map((item) => ({
    value: item._id,
    label: item.title?.find((el) => el.lang === language.slug)?.value || "",
  }));

  const categoriesOptions = categories?.map((item) => ({
    value: item._id,
    label: item.title?.find((el) => el.lang === language.slug)?.value || "",
  }));

  useEffect(() => {
    !watch("restaurant")?.value &&
      setValue("restaurant", {
        value: restaurant?.[0]._id || "",
        label:
          restaurant?.[0]?.title?.find((el) => el.lang === language.slug)
            ?.value || "",
      });
  }, [language.slug, restaurant, setValue, watch]);

  const currentLang = watch("currentlang");

  const optionsHiden = [
    { label: Translater.TableTitles.hidden.true[language.slug], value: "yes" },
    { label: Translater.TableTitles.hidden.false[language.slug], value: "no" },
  ];

  return (
    <MainInfoWrap>
      <Title>{Translater.ProductsPage.basicInformation[language.slug]}</Title>
      <InputsWrap>
        <InputsLine>
          <Controller
            control={control}
            name="restaurant"
            rules={{ required }}
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                width="318px"
                label={Translater.RestaurantsDetailPage.title[language.slug]}
                options={retaurantOptions}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="category"
            rules={{ required }}
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                width="318px"
                error={errors.category?.message}
                placeholder={
                  Translater.Placeholders.chooseCategory[language.slug]
                }
                label={Translater.TableTitles.oneCategory[language.slug]}
                options={categoriesOptions}
                onChange={(value) => {
                  onChange(value);
                  setValue("variations", []);
                }}
                value={value}
              />
            )}
          />
        </InputsLine>
        <InputsLine>
          <Input
            width="318px"
            placeholder="Холодець"
            key={currentLang}
            label={`${
              Translater.Landings.modelLanding.title[language.slug]
            } ${currentLang}`}
            {...register(`name${currentLang === "uk" ? "UK" : "EN"}`, {
              required,
            })}
            $error={
              errors[`name${currentLang === "uk" ? "UK" : "EN"}`]?.message
            }
          />
          <Controller
            control={control}
            name="hidden"
            rules={{ required }}
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                width="318px"
                error={errors.hidden?.message}
                placeholder={Translater.chooseValue[language.slug]}
                label={Translater.TableTitles.hidden[language.slug]}
                options={optionsHiden}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </InputsLine>

        <InputsLine>
          <Controller
            control={control}
            name="novelty"
            rules={{ required }}
            render={({ field: { value, onChange } }) => (
              <CustomSelect
                width="318px"
                error={errors.novelty?.message}
                placeholder={Translater.chooseValue[language.slug]}
                label={Translater.TableTitles.novelty[language.slug]}
                options={optionsHiden}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </InputsLine>
      </InputsWrap>
      <DescriptionWrap
        $error={
          !!errors[`description${currentLang === "uk" ? "UK" : "EN"}`]?.message
        }
      >
        <p>
          {Translater.Landings.modelLanding.description[language.slug]}{" "}
          {currentLang}
        </p>
        <textarea
          placeholder={Translater.TableTitles.description[language.slug]}
          key={"description" + currentLang}
          {...register(`description${currentLang === "uk" ? "UK" : "EN"}`)}
        ></textarea>
        {!!errors[`description${currentLang === "uk" ? "UK" : "EN"}`]
          ?.message && (
          <span>
            {
              errors[`description${currentLang === "uk" ? "UK" : "EN"}`]
                ?.message
            }
          </span>
        )}
      </DescriptionWrap>
      <VariationsBlock />
      <AdditionsBlock />
    </MainInfoWrap>
  );
};
