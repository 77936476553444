import axios from "axios";
import { config } from "../../config";
import {
  TCategory,
  TGetvariationsParams,
  TNewVariation,
  TUpdateVariations,
  TVariation,
  TVariationForm,
} from "./types";
import useSWR from "swr";
import { useContext } from "react";
import * as Contexts from "../../contexts";
import { IRestaurant } from "../../types/items";
import { UseFormReset } from "react-hook-form";

export const getVariationList = async (
  params: TGetvariationsParams,
  token: string | null
) => {
  const { data } = await axios.get<TVariation[]>(`${config.API}/variations`, {
    params,
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

export const useGetVariations = (params: TGetvariationsParams) => {
  const { token } = useContext(Contexts.AuthContext);
  const {
    data: variations,
    isLoading,
    error,
    mutate,
  } = useSWR(
    ["getVariations", params],
    ([, par]) => getVariationList(par, token),
    { keepPreviousData: true }
  );
  return { variations, isLoading, error, mutate };
};

const getRestaurant = async (token: string) => {
  const { data } = await axios.get<IRestaurant[]>(`${config.API}/restaurants`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

export const useGetRestaurants = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { data: restaurant } = useSWR(
    token ? ["getRestaurant", token] : null,
    ([, token]) => getRestaurant(token),
    { keepPreviousData: true }
  );
  return { restaurant };
};

export const getCategories = async ({
  restId,
  token,
}: {
  restId: string;
  token: string;
}) => {
  const { data } = await axios.get<TCategory[]>(
    `${config.API}/categories/${restId}/byRestaurant`,

    {
      params: { page: 1, perPage: 30 },
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const useGetCategories = (restId: string) => {
  const { token } = useContext(Contexts.AuthContext);
  const params = { token: token || "", restId };
  const { data: categories } = useSWR(
    params.restId ? ["getcatefories", params] : null,
    ([, par]) => getCategories(par),
    { keepPreviousData: true }
  );
  return { categories };
};

export const createVariations = async (data: TNewVariation, token: string) => {
  await axios.post(`${config.API}/variations`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteVariation = async (id: string, token: string) => {
  await axios.delete(`${config.API}/variations/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getCurrentVariation = async (id: string, token: string) => {
  const { data } = await axios.get<TVariation>(
    `${config.API}/variations/${id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const useGetvariation = (id?: string) => {
  const { token } = useContext(Contexts.AuthContext);
  const { data: variation, mutate } = useSWR(
    id ? ["gutCurrentVariation", id] : null,
    ([, id]) => getCurrentVariation(id, token || "")
  );
  return { variation, mutate };
};

export const updateVariation = async (value: TUpdateVariations) => {
  const { id, token, data } = value;
  await axios.put(`${config.API}/variations/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const setVariationData = (
  variation: TVariation,
  reset: UseFormReset<TVariationForm>,
  lang: "en" | "uk"
) => {
  reset({
    currentlang: lang,
    nameEN: variation.title.find((el) => el.lang === "en")?.value || "",
    nameUK: variation.title.find((el) => el.lang === "uk")?.value || "",
    category: {
      label: variation.category.title?.find((el) => el.lang === lang)?.value,
      value: variation.category._id,
    },
    parameters: variation.items.map((item) => ({
      nameParamsEN: item.name?.find((el) => el.lang === "en")?.value,
      nameParamsUK: item.name?.find((el) => el.lang === "uk")?.value,
    })),
  });
};

export const generateNewData = (data: TVariationForm) => {
  return {
    title: [
      { lang: "en", value: data.nameEN },
      { lang: "uk", value: data.nameUK },
    ],
    items: data.parameters.map((item) => ({
      name: [
        { lang: "en", value: item.nameParamsEN },
        { lang: "uk", value: item.nameParamsUK },
      ],
    })),
    category: data.category.value,
  };
};
