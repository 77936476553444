import React, { useContext } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { CustomSelect } from "../../../../components/CustomSelect";
import { Controller } from "react-hook-form";
import { useGetCategories, useGetRestaurants } from "../../helpers";
import { Translater } from "../../../../config";
import * as Contexts from "../../../../contexts";
import { Input } from "components/Input";
import { TVariationForm } from "pages/Variations/types";
import { Addbutton, InputItemWrap, InputsWrap, TopWrap } from "./styled";
import { RiDeleteBin5Line } from "react-icons/ri";

export const CreateForm = () => {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext<TVariationForm>();
  const currentLang = watch("currentlang");
  const { language } = useContext(Contexts.LanguageContext);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "parameters",
  });
  const { restaurant } = useGetRestaurants();
  const { categories } = useGetCategories(restaurant?.[0]._id || "");

  const required = Translater.Placeholders.requiredFields[language.slug];

  const onAddClick = () => {
    append({ nameParamsEN: "", nameParamsUK: "" });
  };

  return (
    <>
      <TopWrap>
        <Input
          width="100%"
          placeholder="Порція"
          key={currentLang}
          label={`${
            Translater.Landings.modelLanding.title[language.slug]
          } ${currentLang}`}
          {...register(`name${currentLang === "uk" ? "UK" : "EN"}`, {
            required,
          })}
          $error={errors[`name${currentLang === "uk" ? "UK" : "EN"}`]?.message}
        />
        <Controller
          rules={{ required }}
          control={control}
          name="category"
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              width="250px"
              label="Категорія"
              error={errors.category?.message}
              placeholder={
                Translater.Placeholders.chooseCategory[language.slug]
              }
              isClearable
              onChange={onChange}
              value={value}
              options={categories?.map((item) => ({
                label:
                  item.title.find((el) => el?.lang === language.slug)?.value ||
                  "",
                value: item._id || "",
              }))}
            />
          )}
        />
      </TopWrap>
      <InputsWrap>
        {fields?.map((item, index) => (
          <InputItemWrap key={item.id + currentLang}>
            <Input
              placeholder={Translater.Sidebar.paramName[language.slug]}
              $error={
                errors?.parameters?.[index]?.[
                  `nameParams${currentLang === "uk" ? "UK" : "EN"}`
                ]?.message
              }
              label={`${Translater.Sidebar.paramName[language.slug]} ${
                index + 1
              } ${currentLang}`}
              width="100%"
              {...register(
                `parameters.${index}.nameParams${
                  currentLang === "uk" ? "UK" : "EN"
                }`,
                { required }
              )}
            />
            {index !== 0 && <RiDeleteBin5Line onClick={() => remove(index)} />}
          </InputItemWrap>
        ))}
        <Addbutton type="button" onClick={onAddClick}>
          + {Translater.Buttons.add[language.slug]}
        </Addbutton>
      </InputsWrap>
    </>
  );
};
