import axios from "axios";
import { config, Translater } from "config";
import { TCreateFormTypes, TGetAdditionParams, TProduct } from "./types";
import useSWR from "swr";
import { useContext } from "react";
import * as Contexts from "contexts";
import { getVariationList } from "pages/Variations/helpers";
import { TGetvariationsParams } from "./components/types";
import { IAdditions } from "types/items";
import { UseFormReset } from "react-hook-form";

export const useGetVariations = (params: TGetvariationsParams) => {
  const { token } = useContext(Contexts.AuthContext);
  const {
    data: variations,
    isLoading,
    error,
    mutate,
  } = useSWR(
    params.category_id ? ["getVariations", params] : null,
    ([, par]) => getVariationList(par, token),
    { keepPreviousData: true }
  );
  return { variations, isLoading, error, mutate };
};

export const getCurrentProduct = async (id: string, token: string) => {
  const { data } = await axios.get<TProduct>(`${config.API}/products/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

export const useGetCurrentProduct = (id?: string) => {
  const { token } = useContext(Contexts.AuthContext);
  const { data: product } = useSWR(
    id ? ["getCurrentProduct", id] : null,
    ([, id]) => getCurrentProduct(id, token || ""),
    { revalidateOnFocus: false }
  );
  return { product };
};

export const updateProduct = async ({
  id,
  token,
  data,
}: {
  id: string;
  token: string;
  data: FormData;
}) => {
  await axios.post(`${config.API}/products/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteProduct = async (id: string, token: string) => {
  await axios.delete(`${config.API}/products/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const createProduct = async (formData: FormData, token: string) => {
  await axios.post(`${config.API}/products`, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getAdditions = async (params: TGetAdditionParams) => {
  const { restId, token, ...otherParams } = params;
  const { data } = await axios.get<IAdditions[]>(
    `${config.serverURL}/api/addition/byRestaurant/${restId}/search`,
    {
      params: { ...otherParams },
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const useGetAdditions = (params: TGetAdditionParams) => {
  const { token } = useContext(Contexts.AuthContext);
  const {
    data: additions,
    mutate,
    isLoading,
  } = useSWR(
    token && params.restId ? ["getAdditions", { token, ...params }] : null,
    ([, par]) => getAdditions(par),
    { keepPreviousData: true }
  );
  return { additions, mutate, isLoading };
};

export const createProductData = (data: TCreateFormTypes) => {
  const formData = new FormData();

  formData.append(
    "name",
    JSON.stringify([
      { lang: "en", value: data.nameEN },
      { lang: "uk", value: data.nameUK },
    ])
  );

  formData.append(
    "description",
    JSON.stringify([
      { lang: "en", value: data.descriptionEN },
      { lang: "uk", value: data.descriptionUK },
    ])
  );

  if (data.newPhoto?.length) {
    formData.append("photo", data.newPhoto[0]);
  }

  if (data.metaKeywords?.length) {
    data.metaKeywords.forEach((el) => formData.append("metaKeywords[]", el));
  }

  if (data.metaDescription) {
    formData.append("metaDescription", data.metaDescription);
  }

  formData.append(
    "measurement",
    JSON.stringify([
      { lang: "en", value: data.measurement.value },
      { lang: "uk", value: data.measurement.value === "g" ? "г" : "мл" },
    ])
  );

  formData.append("hidden", data.hidden.value === "yes" ? "true" : "false");
  formData.append("category", data.category.value);
  formData.append("weight", data.weight);
  formData.append("novelty", data.novelty.value === "yes" ? "true" : "false");
  formData.append("restaurant[]", data.restaurant.value);
  formData.append("price", data.price);
  formData.append("isVariated", JSON.stringify(!!data.variations?.length));

  if (data.additions?.length) {
    data.additions.forEach((add) => formData.append("additions[]", add.id));
  }

  if (data.variations?.length) {
    data.variations.forEach((variation, index) => {
      formData.append(
        `variations[]`,
        JSON.stringify({
          _id: variation.id,
          items: variation.items.map((item) => ({
            primary: item.default,
            guid: item.guid,
            price: item.price,
            weight: item.weight,
            item: variation.id,
          })),
        })
      );
    });
  }

  return { formData };
};

export const setDefaultValue = (
  product: TProduct,
  reset: UseFormReset<TCreateFormTypes>,
  lang: "uk" | "en"
) => {
  reset({
    currentlang: "uk",
    category: {
      label: product?.category?.title.find((el) => el.lang === lang)?.value,
      value: product?.category?._id,
    },
    nameEN: product?.name?.find((el) => el.lang === "en")?.value,
    nameUK: product?.name?.find((el) => el.lang === "uk")?.value,
    hidden: {
      label: product.hidden
        ? Translater.TableTitles.hidden.true[lang]
        : Translater.TableTitles.hidden.false[lang],
      value: product.hidden ? "yes" : "no",
    },
    novelty: {
      value: product?.novelty ? "yes" : "no",
      label: product?.novelty
        ? Translater.TableTitles.hidden.true[lang]
        : Translater.TableTitles.hidden.false[lang],
    },
    restaurant: {
      label: product?.restaurant?.title?.find((el) => el.lang === lang)?.value,
      value: product?.restaurant?._id,
    },
    descriptionEN: product?.description?.find((el) => el.lang === "en")?.value,
    descriptionUK: product?.description?.find((el) => el.lang === "uk")?.value,
    price: product?.price?.toString(),
    weight: product?.weight?.toString(),
    metaKeywords: product?.metaKeywords || [],
    metaDescription: product?.metaDescription || "",
    measurement: {
      value: product.measurement?.find((el) => el?.lang === "en")?.value,
      label: product.measurement?.find((el) => el?.lang === lang)?.value,
    },
    newPhoto: null,
    oldPhoto: product.photo,
    additions: product.additions?.map((el) => ({
      id: el._id,
      price: el.price,
      name: el.name,
    })),
    variations: product.variations?.map((variation) => ({
      id: variation._id,
      title: [],
      items: variation.items?.map((item) => ({
        default: item.primary,
        id: "",
        guid: item.guid,
        price: item.price.toString(),
        weight: item.weight.toString(),
        name: [],
      })),
    })),
  });
};
