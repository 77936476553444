import React, { useState, useEffect, useContext, ChangeEvent } from "react"

import * as Contexts from "../../contexts"
import * as Page from "../../components/Page"
import * as Form from "../../components/Form"
import * as Components from "./components"
import * as Buttons from "../../components/Buttons"

import { useHttp } from "../../hooks"
import { config, Translater } from "../../config"
import { useParams, useHistory } from "react-router-dom"
import { IRole } from "../../types/items"
import { TMutliLangTitles } from "../../types/multiLang"

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)

  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()
  //@ts-ignore
  const { id } = useParams()
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IRole | null>(null)
  const [isValid] = useState({
    title: false,
  })

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name && primary) {
        setPrimary({ ...primary, [e.target.name]: e.target.value })
      }
    },
    saveHandler: () => {
      Callbacks.Save()
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IRole = await request(
          `${config.API}/roles/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        setPrimary(response)
      } catch (e) {}
    },
    Save: async () => {
      try {
        const response = await request(
          `${config.API}/roles/${id}`,
          "POST",
          { rules: { ...primary?.rules }, title: primary?.title },
          {
            Authorization: `Bearer ${token as string}`,
          }
        )

        if (response) {
          history.goBack()
        }
      } catch (e) {
        console.log(e)
      }
    },
    Delete: async () => {
      try {
        await request(`${config.API}/roles/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        })

        history.push("/roles")
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  if (loading || !primary) return <Page.Preloader />

  console.log("primary", primary)

  return (
    <Page.Wrapper title={primary.title}>
      <Page.Header
        backButtonTitle={Translater.RoleDetailPage.title[language.slug]}
        backButtonLink="/roles"
      />

      <Buttons.Container
        disabled={loading}
        saveHandler={access.roles.change ? Events.saveHandler : false}
        deleteHandler={access.roles.delete ? Events.deleteHandler : false}
      />

      <Components.ConfigBlock
        form={primary}
        isValid={isValid}
        // @ts-ignore
        setForm={setPrimary}
        inputHandler={Events.inputHandler}
      />

      <Form.DateBlock
        updated={primary.updatedAt as string}
        created={primary.createdAt as string}
      />
    </Page.Wrapper>
  )
}

export default DetailPage
