import React, { Fragment, useContext, useEffect, useState } from "react";

import * as Contexts from "../../../contexts";
import * as Form from "../../../components/Form";
import * as Page from "../../../components/Page";

import { config, Translater } from "../../../config";
import { useHttp } from "../../../hooks";

import { IStock, IOption, IRestaurant, IProduct } from "../../../types/items";
import { IConfigBlockProps } from "./types";
import ListRestaurants from "../ListRestaurants";
import ListCategories from "../ListCategories";
import Select from "react-select";
import { Checkbox } from "@material-ui/core";
import { getStringFromTranslationsArr } from "../../../helpers";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  from,
  multiLangValues,
  setParentID,
  inputHandler,
  setProducts,
  selectHandler,
  setForm,
  isClicked,
  valueLang,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { multiLang } = useContext(Contexts.ConfigContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request } = useHttp();

  const [products, setProductsOption] = useState<IProduct[]>([]);
  const [val, setVal] = useState<{ lable: string; value: string }[]>();
  const [productsTitle, setProductsTitle] = useState<IProduct[]>([]);

  const Events = {
    loadProductsOptions: async () => {
      try {
        const response: IProduct[] = await request(
          `${config.API}/products/${form.category}/byCategory?perPage=100`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        console.log("response", response);

        if (response && form._id) {
          setForm({
            ...form,
            products: [response[0]._id],
          });
        }
        setProductsOption(response);
      } catch (e) {}
    },
    FetchRestaurants: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) {
          setForm({
            ...form,
            restaurantsList: response.map((element) => element._id)[0],
          });
        }
      } catch (e) {}
    },
    FetchProduct: async (productId: string) => {
      try {
        const response: IProduct = await request(
          `${config.API}/products/${productId}/`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        return response;
      } catch (e) {}
    },
    loadOptions: async () => {
      try {
        const response: IStock[] = await request(
          `${config.API}/stocks/all`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        const formatted: IOption[] = [];
        if (response.length) {
          for (const item of response) {
            const option = {
              value: item._id as string,
              label: getStringFromTranslationsArr(
                item.name,
                language.slug
              ) as string,
            };
            formatted.push(option);
          }
        }
        return formatted;
      } catch (e) {}
    },
    optionHandler: (option: any) => {
      setParentID(option.value);
    },
    optionProductsHandler: (option: any) => {
      setVal(option);
      setProducts && setProducts(option);
      setForm({
        ...form,
        productsList: val?.map((item) => item.value),
      });
    },
  };

  useEffect(() => {
    from === "create" && Events.FetchRestaurants();
  }, []);

  useEffect(() => {
    Events.loadProductsOptions();
  }, [form?.category]);

  useEffect(() => {
    setForm({
      ...form,
      productsList: val?.map((item) => item.value),
    });
  }, [val]);

  useEffect(() => {
    const promises = [];
    for (let id in form.productsList as unknown as string[]) {
      promises.push(Events.FetchProduct(form.productsList[id]));
    }
    Promise.all(promises).then((res) => setProductsTitle(res as IProduct[]));
  }, [form.productsList]);

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          name="name"
          key={JSON.stringify(valueLang)}
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.name.length >= 2 : true}
          value={getStringFromTranslationsArr(form.name, valueLang)}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.description[language.slug]}
      >
        <Form.Input
          key={JSON.stringify(valueLang)}
          name="description"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.description.length >= 2 : true}
          value={getStringFromTranslationsArr(form.description, valueLang)}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.percent[language.slug]}
      >
        <Form.Input
          type="number"
          name="percent"
          value={+form.percent}
          inputHandler={inputHandler}
          isValid={isClicked ? +form.percent > 0 : true}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.appPhoto[language.slug]}
      >
        <Form.Input
          type="file"
          name="appPhoto"
          isValid={isClicked ? !!form.appPhoto : true}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      {from !== "create" && (
        <img
          alt="Stock"
          src={`${config.imageProxy}public/${form.appPhoto}`}
          style={{ maxWidth: "350px", maxHeight: "350px" }}
        />
      )}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.sitePhoto[language.slug]}
      >
        <Form.Input
          type="file"
          name="sitePhoto"
          isValid={isClicked ? !!form.sitePhoto : true}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      {from !== "create" && (
        <img
          alt="Stock"
          src={`${config.imageProxy}public/${form.sitePhoto}`}
          style={{ maxWidth: "350px", maxHeight: "350px" }}
        />
      )}

      {!form.infinite ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.dateStart[language.slug]}
        >
          <Form.InputDate
            value={form.dateStart}
            max={form.dateEnd as string}
            type="number"
            name="dateStart"
            inputHandler={inputHandler}
            isValid={isClicked ? form.dateStart.toString().length > 4 : true}
          />
        </Form.LabelField>
      ) : null}

      {!form.infinite ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.dateEnd[language.slug]}
        >
          <Form.InputDate
            value={form.dateEnd}
            min={form.dateStart as string}
            type="number"
            name="dateEnd"
            inputHandler={inputHandler}
            isValid={isClicked ? form.dateEnd.toString().length > 4 : true}
          />
        </Form.LabelField>
      ) : null}

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.restaurantsList[language.slug]}
      >
        <ListRestaurants
          selectHandler={selectHandler}
          restaurantsList={form.restaurant}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.categoriesList[language.slug]}
      >
        <ListCategories
          restaurant={form.restaurant}
          selectHandler={selectHandler}
          categoriesList={form.category as string}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.productsList[language.slug]}
      >
        <Fragment>
          <Select
            isMulti={true}
            isSearchable
            defaultValue={
              products.map((item) => ({
                name: getStringFromTranslationsArr(item.name, language.slug),
                value: item._id,
              }))[0]
            }
            styles={{
              control: () => ({
                display: "flex",
                border: "1px solid #e6e6e6",
                borderRadius: 5,
                outline: 0,
                cursor: "pointer",
              }),
              input: () => ({
                fontSize: 16,
              }),
              valueContainer: () => ({
                overflowY: "auto",
                width: "320px",
              }),
            }}
            options={
              products.map((item) => ({
                label: getStringFromTranslationsArr(item?.name, language.slug),
                value: item?._id,
              })) as any[]
            }
            closeMenuOnSelect={false}
            placeholder={Translater.Placeholders.chooseProduct[language.slug]}
            noOptionsMessage={() =>
              Translater.Placeholders.noOptionsMessage[language.slug]
            }
            value={
              val ||
              productsTitle.map((item) => ({
                label: item?.name
                  ?.map((item) => {
                    if (item.lang == language.slug) {
                      return item.value;
                    }
                  })
                  .filter((item) => item)[0] as string,
                value: item?._id as string,
              }))
            }
            onChange={Events.optionProductsHandler}
            className={"invalid"}
          />
        </Fragment>
      </Form.LabelField>
    </Fragment>
  );
};

export default ConfigBlock;
