import React from "react"

import { TTabLanguage } from "./types"
import "./tabLanguage.scss"

export const TabLanguage = ({ valueLang, setValueLang }: TTabLanguage) => {
  return (
    <div className="tabLanguage-container">
      <div
        onClick={() => setValueLang("uk")}
        className={`language-block ${valueLang === "uk" && "active"}`}
      >
        UA
      </div>
      <div
        onClick={() => setValueLang("en")}
        className={`language-block ${valueLang === "en" && "active"}`}
      >
        EN
      </div>
    </div>
  )
}
