import React from "react";
import { IPreloaderProps } from "./types";

const Preloader: React.FC<IPreloaderProps> = ({ relative = false }) => {
  return (
    <div className={`page-preloader${relative ? " relative" : ""}`}>
      <img alt="Logo" src={require("../../assets/images/logo.svg")} />
    </div>
  );
};

export default Preloader;
