import React, { Fragment, useState, useEffect, useContext } from "react"

import * as Components from "."
import { IRootProps, TPossibleRuleSlugs } from "./types"

const Root: React.FC<IRootProps> = ({ form, setForm }) => {
  const [configuration, setConfig] = useState<any>({})
  const fieldsOrder = [
    "orders",
    "categories",
    "products",
    "additions",
    "booking",
    "restaurants",
    "users",
    "staff",
    "roles",
    "ordersHistory",
    "stocks",
    "banner",
    "seo",
    "settings",
    "landings",
    "delivery",
  ]

  return (
    <Fragment>
      {Object.keys(form.rules).map((item) => (
        <>
          {fieldsOrder.includes(item) && (
            <Components.Field
              key={item}
              nesting={0}
              form={form}
              parent={null}
              //@ts-ignore
              slug={item}
              setForm={setForm}
            />
          )}
        </>
      ))}
    </Fragment>
  )
}

export default Root
