import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
} from "react";
import { Editor } from "@tinymce/tinymce-react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Form from "../../components/Form";
import * as Buttons from "../../components/Buttons";

import { Translater, config } from "../../config";
import { useHttp, useValidation } from "../../hooks";
import { TabLanguage } from "../../components/TabLanguage/TabLanguage";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { TCreateValidationSchema, TFormsCategory } from "./types";

const IndexPage: React.FC = () => {
  const { actions: authActions } = useContext(Contexts.AuthContext);
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { request, loading } = useHttp();

  const [userAgreementUa, setUserAgreementUa] = useState("");
  const [userAgreementEn, setUserAgreementEn] = useState("");
  const [userPolicyUk, setUserPolicyUk] = useState("");
  const [userPolicyEn, setUserPolicyEn] = useState("");
  const [valueLang, setValueLang] = useState<string>("uk");
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const [form, setForm] = useState<TFormsCategory>({
    priceChange: 0,
  });

  const [isValid, toggleValid] = useState({
    priceChange: false,
  });

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    },
  };

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      priceChange: {
        condition: form.priceChange >= -99 && form.priceChange <= 99,
        error: Translater.ErrorPriceChange[language.slug],
      },
    }),
    [form, Translater, language]
  );

  const { errors, validationSchema, validation } = useValidation(schema);

  useEffect(() => {
    if (form.priceChange >= -99 && form.priceChange <= 99) {
      toggleValid({ ...isValid, priceChange: true });
    } else {
      toggleValid({ ...isValid, priceChange: false });
    }
  }, [form.priceChange]);

  const successUpdate = useMemo(() => {
    return (
      (userAgreementEn.length &&
        userAgreementUa.length &&
        userPolicyEn.length &&
        userPolicyUk.length) > 0
    );
  }, [userAgreementEn, userAgreementUa, userPolicyUk, userPolicyEn]);

  const handleEditorChange = (
    content: any,
    editor: any,
    setUserAgreement: (el: string) => void
  ) => {
    setUserAgreement(content);
  };

  const getUserAgreement = async () => {
    try {
      const response = await request(`${config.API}/variables`, "GET", null, {
        Authorization: `Bearer ${token as string}`,
      });
      setUserAgreementUa(response?.userAgreement[0]?.value);
      setUserAgreementEn(response?.userAgreement[1]?.value);
      setUserPolicyUk(response?.privacyPolicy[0]?.value);
      setUserPolicyEn(response?.privacyPolicy[1]?.value);
    } catch (e) {}
  };

  const updateUserAgreement = async () => {
    await validation();
    if (errors.length > 0) {
      toogleIsAlertOpen(true);
      return;
    }
    try {
      await request(
        `${config.API}/products/updateProductsPriceByPercent`,
        "POST",
        { percent: Number(form.priceChange) },
        {
          Authorization: `Bearer ${token as string}`,
        }
      );
      const data = {
        userAgreement: [
          {
            value: userAgreementUa,
            lang: "uk",
          },
          {
            value: userAgreementEn,
            lang: "en",
          },
        ],
        privacyPolicy: [
          {
            value: userPolicyUk,
            lang: "uk",
          },
          {
            value: userPolicyEn,
            lang: "en",
          },
        ],
      };

      if (
        !(
          userAgreementEn.length &&
          userAgreementUa.length &&
          userPolicyUk.length &&
          userPolicyEn.length
        )
      ) {
        toogleIsAlertOpen(true);
        return;
      }
      await request(`${config.API}/variables`, "PUT", data, {
        Authorization: `Bearer ${token as string}`,
      });
    } catch (e) {}
    getUserAgreement();
  };

  useEffect(() => {
    getUserAgreement();
  }, []);

  const editorOptionsUa = useMemo(() => {
    return {
      width: "800px",
      height: "400px",
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | " +
        "bold italic backcolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "removeformat | help",
      setup: function (editor: any) {
        editor.on("change", function () {
          handleEditorChange(editor.getContent(), editor, setUserAgreementUa);
        });
      },
    };
  }, [userAgreementUa]);

  const editorOptionsEn = useMemo(() => {
    return {
      width: "800px",
      height: "400px",
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | " +
        "bold italic backcolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "removeformat | help",
      setup: function (editor: any) {
        editor.on("change", function () {
          handleEditorChange(editor.getContent(), editor, setUserAgreementEn);
        });
      },
    };
  }, [userAgreementEn]);

  const editorOptionsPrivacyEn = useMemo(() => {
    return {
      width: "800px",
      height: "400px",
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | " +
        "bold italic backcolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "removeformat | help",
      setup: function (editor: any) {
        editor.on("change", function () {
          handleEditorChange(editor.getContent(), editor, setUserPolicyEn);
        });
      },
    };
  }, [userPolicyEn]);

  const editorOptionsPrivacyUk = useMemo(() => {
    return {
      width: "800px",
      height: "400px",
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | " +
        "bold italic backcolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "removeformat | help",
      setup: function (editor: any) {
        editor.on("change", function () {
          handleEditorChange(editor.getContent(), editor, setUserPolicyUk);
        });
      },
    };
  }, [userPolicyUk]);

  if (loading) return <Page.Preloader />;

  return (
    <Page.Wrapper title={Translater.SettingsPage.title[language.slug]}>
      <Page.Header title={Translater.SettingsPage.title[language.slug]} />

      <Form.LabelField
        label={Translater.SettingsPage.langSwitchLabel[language.slug]}
        fontSize="small"
        fontWeight="bold"
      >
        <Form.LanguageToggler />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.SettingsPage.priceChange[language.slug]}
      >
        <Form.Input
          name="priceChange"
          placeholder={
            Translater.SettingsPage.priceChangePlaceholder[language.slug]
          }
          type="number"
          isValid={isValid.priceChange}
          value={form.priceChange}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      {valueLang === "uk" && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.userAgreement[language.slug]}
        >
          <Editor
            apiKey={config.editorKey}
            init={editorOptionsUa}
            initialValue={userAgreementUa}
          />
        </Form.LabelField>
      )}
      {valueLang === "en" && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.userAgreement[language.slug]}
        >
          <Editor
            apiKey={config.editorKey}
            init={editorOptionsEn}
            initialValue={userAgreementEn}
          />
        </Form.LabelField>
      )}

      {valueLang == "en" && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.policy[language.slug]}
        >
          <Editor
            apiKey={config.editorKey}
            init={editorOptionsPrivacyEn}
            initialValue={userPolicyEn}
          />
        </Form.LabelField>
      )}
      {valueLang == "uk" && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.policy[language.slug]}
        >
          <Editor
            apiKey={config.editorKey}
            init={editorOptionsPrivacyUk}
            initialValue={userPolicyUk}
          />
        </Form.LabelField>
      )}

      <Buttons.Container
        saveHandler={updateUserAgreement}
        logoutHandler={authActions.LOGOUT}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <>
          <Alert severity="error">
            {errors.length > 0 && (
              <p>{Translater.ErrorPriceChange[language.slug]}</p>
            )}
          </Alert>
          <Alert severity={successUpdate ? "success" : "error"}>
            {!successUpdate && (
              <p>{Translater.Alert.translationError[language.slug]}</p>
            )}
            {successUpdate && <p>{Translater.SEO.updated[language.slug]}</p>}
          </Alert>
        </>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default IndexPage;
