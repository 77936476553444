import React, { useContext, useEffect } from "react";
import { FilterWrap } from "./styled";
import { useGetCategories, useGetRestaurants } from "../../helpers";

import * as Contexts from "../../../../contexts";
import { TParams } from "../../types";
import { Translater } from "../../../../config";
import { CustomSelect } from "../../../../components/CustomSelect";

type TProps = {
  params: TParams;
  setParams: React.Dispatch<React.SetStateAction<TParams>>;
};

export const FilterBar = ({ params, setParams }: TProps) => {
  const { language } = useContext(Contexts.LanguageContext);

  const { restaurant } = useGetRestaurants();
  useEffect(() => {
    setParams((prev) => ({
      ...prev,
      restaurant: {
        label:
          restaurant?.[0]?.title.find((el) => el.lang === language.slug)
            ?.value || "",
        value: restaurant?.[0]?._id || "",
      },
    }));
  }, [language.slug, restaurant, setParams]);

  const { categories } = useGetCategories(params.restaurant?.value || "");

  const onCategoryChange = (v: unknown) => {
    const newValue = v as { label: string; value: string };
    setParams((prev) => ({ ...prev, category: newValue || undefined }));
  };

  const onRestaurantChange = (v: unknown) => {
    const newValue = v as { label: string; value: string };
    setParams((prev) => ({ ...prev, restaurant: newValue || undefined }));
  };

  return (
    <FilterWrap>
      <CustomSelect
        placeholder={Translater.Sidebar.restaurants[language.slug]}
        onChange={onRestaurantChange}
        value={params.restaurant}
        options={restaurant?.map((item) => ({
          label:
            item.title.find((el) => el.lang === language.slug)?.value || "",
          value: item._id || "",
        }))}
      />
      <CustomSelect
        placeholder={Translater.Placeholders.filterByCategory[language.slug]}
        isClearable
        onChange={onCategoryChange}
        value={params.category}
        options={categories?.map((item) => ({
          label:
            item.title.find((el) => el?.lang === language.slug)?.value || "",
          value: item._id || "",
        }))}
      />
    </FilterWrap>
  );
};
