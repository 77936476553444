import React, { useContext } from "react"

import * as Contexts from "../../../../contexts"
import * as Table from "../../../../components/Table"

import { useHistory } from "react-router-dom"
import { ITrackProps } from "./types"

const Track: React.FC<ITrackProps> = ({ order, data }) => {
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  const history = useHistory()

  const Events = {
    editHandler: () => {
      history.push(`/users/${data._id}`)
    },
  }
  return (
    <Table.Track type="users">
      <Table.Cell text={`${order}`} />

      <Table.Cell text={data.name} />

      <Table.Cell text={data.phone} />

      <Table.Cell
        text={new Date(data.updatedAt as string).toLocaleDateString()}
      />

      <Table.Cell
        text={new Date(data.createdAt as string).toLocaleDateString()}
      />

      <Table.Cell
        text={
          data.dateOfBirth &&
          new Date(data.dateOfBirth as string).toLocaleDateString()
        }
      />

      <Table.Cell
        type="control"
        editButton={access.users?.change}
        editHandler={Events.editHandler}
      />
    </Table.Track>
  )
}

export default Track
