import React, { useContext } from "react";
import { TitleWrap } from "./styled";
import { Translater } from "../../../../config";
import * as Contexts from "../../../../contexts";

export const TitleBar = () => {
  const { language } = useContext(Contexts.LanguageContext);
  return (
    <TitleWrap>
      <p>{Translater.Landings.modelLanding.title[language.slug]}</p>
      <p>{Translater.TableTitles.category[language.slug]}</p>
      <p>{Translater.Landings.events.headers.createdDate[language.slug]}</p>
      <p></p>
    </TitleWrap>
  );
};
