import axios from "axios";
import { useContext } from "react";
import useSWR from "swr";
import { IProduct } from "types/items";
import * as Contexts from "contexts";
import { config } from "config";

const getProductsByCategory = async (id: string, token: string) => {
  const { data } = await axios<IProduct[]>(
    `${config.API}/products/${id}/byCategory`,
    {
      params: { perPage: "100", sortBy: "order", sortOrder: "asc" },
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const useGetProductByCategory = (id?: string) => {
  const { token } = useContext(Contexts.AuthContext);
  const { data: products } = useSWR(
    id ? ["getProductsByCategory", id] : null,
    ([, id]) => getProductsByCategory(id, token || "")
  );
  return { products };
};
