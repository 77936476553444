import React, { useContext, useEffect } from "react";
import * as Page from "../../components/Page";
import { Translater } from "../../config";
import * as Contexts from "../../contexts";
import {
  ActiveIndicator,
  ButtonLang,
  ButtonsWrap,
  ChangeLang,
  CreateButton,
  CreatePageTopWrap,
  CreateVariationsWrap,
  DeleteButton,
  TitleLink,
} from "./styled";
import { IoIosArrowBack } from "react-icons/io";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { TVariationForm } from "./types";
import { CreateForm } from "./components/CreateForm/CrateForm";
import { notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import {
  createVariations,
  deleteVariation,
  generateNewData,
  setVariationData,
  updateVariation,
  useGetvariation,
} from "./helpers";
type Tlang = "en" | "uk";

const CreateVariation = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const { variation } = useGetvariation(id);

  const methods = useForm<TVariationForm>({
    mode: "all",
    defaultValues: {
      currentlang: "uk",
      parameters: [{ nameParamsEN: "", nameParamsUK: "" }],
    },
  });

  const currentLang = methods.watch("currentlang");

  useEffect(() => {
    if (variation) {
      setVariationData(variation, methods.reset, language.slug);
    }
  }, [language.slug, methods.reset, variation]);

  // create/update fuctions
  const onSubmit: SubmitHandler<TVariationForm> = async (data) => {
    if (
      !data.nameEN ||
      !data.nameUK ||
      !data.parameters.every((item) => item.nameParamsEN && item.nameParamsUK)
    ) {
      notification.error({
        message: Translater.Buttons.fillTheDataAllLanguages[language.slug],
      });
    } else {
      try {
        const newData = generateNewData(data);
        if (id) {
          await updateVariation({ data: newData, id, token: token || "" });
          notification.success({
            message: Translater.Buttons.successfullyUpdated[language.slug],
          });
        } else {
          await createVariations(newData, token || "");
          notification.success({
            message: Translater.Buttons.successfullyCreated[language.slug],
          });
        }

        history.push("/variations");
      } catch (error) {
        notification.error({
          message: Translater.Buttons.errorCreating[language.slug],
        });
      }
    }
  };

  const onlangChange = (lang: Tlang) => {
    methods.setValue("currentlang", lang);
  };

  const onDeleteClick = async () => {
    if (id) {
      try {
        await deleteVariation(id, token || "");
        history.push("/variations");
        notification.success({
          message: Translater.Buttons.successfullyDeleted[language.slug],
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Page.Wrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CreatePageTopWrap>
            <TitleLink to="/variations">
              <IoIosArrowBack />
              {!id
                ? Translater.Buttons.createdVariations[language.slug]
                : Translater.Buttons.editingVariation[language.slug]}
            </TitleLink>
            <ButtonsWrap>
              {id && (
                <DeleteButton type="button" onClick={onDeleteClick}>
                  {Translater.Buttons.delete[language.slug]}
                </DeleteButton>
              )}
              <CreateButton type="submit">
                {!id
                  ? Translater.Buttons.create[language.slug]
                  : Translater.Buttons.save[language.slug]}
              </CreateButton>
            </ButtonsWrap>
          </CreatePageTopWrap>
          <ChangeLang>
            <ButtonLang
              onClick={() => onlangChange("uk")}
              $active={currentLang === "uk"}
            >
              {Translater.Buttons.UKR[language.slug]}
            </ButtonLang>
            <ButtonLang
              onClick={() => onlangChange("en")}
              $active={currentLang === "en"}
            >
              {Translater.Buttons.EN[language.slug]}
            </ButtonLang>
            <ActiveIndicator
              style={{
                left: `${(currentLang === "uk" ? 0 : 1) * 50}%`,
                width: "50%",
              }}
            />
          </ChangeLang>
          <CreateVariationsWrap>
            <CreateForm />
          </CreateVariationsWrap>
        </form>
      </FormProvider>
    </Page.Wrapper>
  );
};
export default CreateVariation;
