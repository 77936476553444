import React from 'react'
import { IHideButtonProps } from './types'


const HideButton: React.FC<IHideButtonProps> = ({
  isActive,
  buttonHandler
}) => {

  return (
    <button
      className={`btn btn--hide ${isActive && 'active'}`}
      onClick={buttonHandler}
    >
      <img
        alt='Arrow'
        src={require('../../assets/icons/arrow.png')}
      />
    </button>
  )
}

export default HideButton