import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Editor } from "@tinymce/tinymce-react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import { useHttp } from "../../hooks";
import { config, Translater } from "../../config";
import * as Form from "../../components/Form";
import { useValidation } from "../../hooks";
import { IRestaurant } from "../../types/items";
import ListRestaurants from "./ListRestaurants";

import { IDelivery } from "./types";
import { TabLanguage } from "../../components/TabLanguage/TabLanguage";

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { loading, request } = useHttp();

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);
  const [deliveryConditionUa, setDeliveryConditionsUa] = useState("");
  const [deliveryConditionEn, setDeliveryConditionsEn] = useState("");
  const [valueLang, setValueLang] = useState<string>(language.slug);

  const [deliveryConditions, setDeliveryConditions] = useState<
    { value: string; lang: string }[]
  >([
    {
      value: "",
      lang: "uk",
    },
    { value: "", lang: "en" },
  ]);

  const [restaurantData, setRestaurantData] = useState<IRestaurant | null>(
    null
  );

  const DefaultIForm = (): IDelivery => {
    return {
      restId: "641c178edbb1727975cdd34b",
      courier: 0,
    };
  };
  const [form, setForm] = useState<IDelivery>(DefaultIForm());

  const schema = useMemo<any>(
    () => ({
      restId: {
        condition: !!form.restId,
        error: `${Translater.ErrorRestoran[language.slug]}`,
      },
    }),
    [form]
  );

  const { errors, validation } = useValidation(schema);

  const Events = {
    inputHandler: (
      e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      const name = e.target.name;
      const value = e.target.value;

      setForm({ ...form, [name]: value });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    buttonHandler: () => {
      Callbacks.SaveData();
    },
  };

  const GetRests = async (restId: string) => {
    try {
      const response = await request(
        `${config.API}/restaurants/${restId}`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token as string}`,
        }
      );
      setRestaurantData(response);
      setDeliveryConditionsEn(response?.deliveryConditions[1]?.value);
      setDeliveryConditionsUa(response?.deliveryConditions[0]?.value);
    } catch (e) {}
  };

  const updateRestaurant = async () => {
    const data: FormData = new FormData();
    data.append("deliveryConditions", JSON.stringify(deliveryConditions));

    await request(
      `${config.API}/restaurants/${restaurantData?._id}`,
      "POST",
      data,
      {
        Authorization: `Bearer ${token as string}`,
      }
    );
  };

  const Callbacks = {
    Fetch: async (restId: string) => {
      try {
        const response: any = await request(
          `${config.API}/delivery/${restId}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        );
        setForm({
          ...form,
          courier: response.courier,
        });
      } catch (e) {}
    },

    SaveData: async () => {
      try {
        await validation();

        const { courier } = form;

        const data = {
          courier: +courier,
        };

        await request(
          `${config.API}/delivery/${restaurantData?._id}`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
      } catch (e) {
        toogleIsAlertOpen(errors.length > 0);
      }
      await updateRestaurant();
    },
  };

  useEffect(() => {
    Callbacks.Fetch(form.restId);
  }, [form.restId]);

  useEffect(() => {
    GetRests(form.restId);
  }, [form.restId]);

  const handleEditorChange = (
    content: any,
    editor: any,
    setValue: (el: any) => void
  ) => {
    setValue(content);
  };

  useEffect(() => {
    setDeliveryConditions([
      {
        value: deliveryConditionUa,
        lang: "uk",
      },
      {
        value: deliveryConditionEn,
        lang: "en",
      },
    ]);
  }, [deliveryConditionEn, deliveryConditionUa]);

  const editorOptionsUa = {
    width: "800px",
    height: "400px",
    menubar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | formatselect | " +
      "bold italic backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
    setup: function (editor: any) {
      editor.on("change", function () {
        handleEditorChange(
          editor.getContent(),
          editor,
          setDeliveryConditionsUa
        );
      });
    },
  };

  const editorOptionsEn = {
    width: "800px",
    height: "400px",
    menubar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | formatselect | " +
      "bold italic backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
    setup: function (editor: any) {
      editor.on("change", function () {
        handleEditorChange(
          editor.getContent(),
          editor,
          setDeliveryConditionsEn
        );
      });
    },
  };

  if (loading) return <Page.Preloader />;
  return (
    <Page.Wrapper title={Translater.DeliveryPage.title[language.slug]}>
      <Page.Header
        title={Translater.DeliveryPage.title[language.slug]}
        btnTitle={Translater.Buttons.save[language.slug]}
        buttonHandler={Events.buttonHandler}
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.restaurantsList[language.slug]}
      >
        <ListRestaurants
          setRests={() => {}}
          selectHandler={Events.selectHandler}
          restaurantsList={form.restId}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.courier[language.slug]}
      >
        <Form.Input
          name="courier"
          value={form.courier}
          inputHandler={Events.inputHandler}
          type="number"
        />
      </Form.LabelField>

      {valueLang === "uk" && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.deliveryTerms[language.slug]}
        >
          <Editor
            apiKey={config.editorKey}
            init={editorOptionsUa}
            value={deliveryConditionUa}
          />
        </Form.LabelField>
      )}

      {valueLang === "en" && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.deliveryTerms[language.slug]}
        >
          <Editor
            apiKey={config.editorKey}
            init={editorOptionsEn}
            value={deliveryConditionEn}
          />
        </Form.LabelField>
      )}

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default IndexPage;
