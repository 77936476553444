import { TCreateFormTypes } from "pages/Products/types";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { Title, VariationList, Wrapper } from "./styled";
import { Translater } from "config";
import * as Contexts from "contexts";
import { useGetVariations } from "pages/Products/helpers";
import { VariationItem } from "./components/VariationItem/VariationItem";

export const VariationsBlock = () => {
  const { language } = useContext(Contexts.LanguageContext);
  const { watch } = useFormContext<TCreateFormTypes>();
  const { variations } = useGetVariations({
    category_id: watch("category")?.value,
    page: 1,
    perPage: 30,
  });

  return variations?.length ? (
    <Wrapper>
      <Title>{Translater.Buttons.variations[language.slug]}:</Title>
      <VariationList>
        {variations?.map((item) => (
          <VariationItem key={item._id} variation={item} />
        ))}
      </VariationList>
    </Wrapper>
  ) : null;
};
