import axios from "axios";
import { useEffect, useState } from "react";
import { config } from "../../config";
import { IProduct } from "../../types/items";

type TGetProductsParams = {
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortOrder?: "asc" | "desc";
};

export type TGetproductsParams = {
  full: number;
  page?: number;
  perPage: number;
  name?: string;
  sortOrder?: "asc" | "desc";
  sortBy?: string;
};

export type Params = {
  search: string;
  page: number;
  perPage: number;
  sortOrder?: "asc" | "desc";
  sortBy?: string;
};

export type TUpdateOrders = {
  positions: { product: string; popularOrder: number }[];
};

const getPopularProducts = async (params: TGetProductsParams) => {
  const { data } = await axios<IProduct[]>(
    `${config.serverURL}/api/products/popular`,
    {
      params,
    }
  );
  return data;
};

const usePopularProducts = (initialParams: TGetProductsParams) => {
  const [popularProducts, setPopularProducts] = useState<IProduct[]>([]);
  const [oldPopularList, setOldPopularList] = useState<IProduct[]>([]);
  const [paramsPopularProducts, setParamsPopularProducts] =
    useState<TGetProductsParams>(initialParams);

  useEffect(() => {
    let isMounted = true;

    getPopularProducts({
      perPage: paramsPopularProducts.perPage,
      sortBy: paramsPopularProducts.sortBy || undefined,
      sortOrder: paramsPopularProducts.sortOrder || undefined,
      page: paramsPopularProducts.page || undefined,
    }).then((res) => {
      if (isMounted) {
        setPopularProducts(
          res.sort((a, b) => (a?.popularOrder || 0) - (b?.popularOrder || 0))
        );
        setOldPopularList(res);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [
    paramsPopularProducts.page,
    paramsPopularProducts.perPage,
    paramsPopularProducts.sortBy,
    paramsPopularProducts.sortOrder,
  ]);

  return {
    setOldPopularList,
    oldPopularList,
    popularProducts,
    paramsPopularProducts,
    setParamsPopularProducts,
    setPopularProducts,
  };
};

const getProducts = async (params: TGetproductsParams, token: string) => {
  const { data } = await axios<IProduct[]>(`${config.API}/products`, {
    params,
    headers: { Authorization: `Bearer ${token}` },
  });
  return data;
};

export { getProducts };

const useProducts = (token: string | null, initialParams: Params) => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [params, setParams] = useState<Params>(initialParams);

  useEffect(() => {
    let isMounted = true;

    if (token) {
      getProducts(
        {
          perPage: params.perPage,
          full: 1,
          page: params.page || undefined,
          name: params.search || undefined,
          sortBy: params.sortBy || undefined,
          sortOrder: params.sortOrder || undefined,
        },
        token
      ).then((res) => {
        if (isMounted) {
          setProducts((prev) => [...prev, ...(res.length ? res : [])]);
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [
    params.page,
    params.perPage,
    params.search,
    params.sortBy,
    params.sortOrder,
    token,
  ]);

  return {
    products,
    params,
    setParams,
    setProducts,
  };
};

export { useProducts, usePopularProducts };

export const updatePopularOrders = async (
  data: TUpdateOrders,
  token: string
) => {
  await axios.post(`${config.API}/products/updatePopularOrder`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
