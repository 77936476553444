import React, { useContext } from 'react'
import * as Table from '../../../../components/Table'
import { TrackProps } from './types'
import * as Contexts from '../../../../contexts'
import { config, Translater } from '../../../../config'

const Track: React.FC<TrackProps> = ({ data, Events, order }) => {
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)

  return (
    <Table.Track type='options'>
      <Table.Cell text={order + ''} />

      <Table.Cell
        type='image'
        imageURL={`${config.imageProxy}public/${data.photo}`}
      />

      <Table.Cell
        text={
          data?.name
            .map((item: any) => {
              if (item.lang === language.slug) {
                return item.value
              }
            })
            .filter((item: any) => item)[0]
        }
      />

      <Table.Cell text={data?.price + ' грн'} />

      <Table.Cell
        text={
          data.restaurant
            ?.map((item: any) => {
              if (item.lang === language.slug) {
                return item.value
              }
            })
            .filter((item: any) => item)[0]
        }
      />

      <Table.Cell
        text={
          data.weight
            ? data.weight + ' г '
            : Translater.TableTitles.noInfo[language.slug]
        }
      />

      <Table.Cell
        type='control'
        editButton={access.additions.change}
        deleteButton={access.additions.delete}
        editHandler={() => Events.editHandler(data._id)}
        deleteHandler={() => Events.deleteHandler(data._id)}
      />
    </Table.Track>
  )
}

export default Track
