import styled from "styled-components";

export const Wrapper = styled.div<{ $error?: boolean }>`
  border: 2px dashed ${({ $error }) => ($error ? "#CA1F1F" : "#81818124")};
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
  max-width: 370px;
  height: 390px;
  .visually-hidden {
    position: absolute;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px;
  }
  img {
    width: 354px;
    height: 296px;
    object-fit: cover;
    border-radius: 12px;
    margin: 0 auto;
  }
  svg {
    margin: 40% auto 0;

    width: 48px;
    height: 38px;
  }
`;
export const BottomWrap = styled.div`
  margin: auto auto 5px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    color: #6c6c6c;
    font-size: 12px;
  }
  button {
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 8px;
    background-color: #03cb83;
    color: #ffffff;
    font-size: 14px;
    height: 28px;
    width: 180px;
    margin: 0 auto;
  }
`;
