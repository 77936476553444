import React from 'react'
import { ILabelFieldProps } from './types'


const LabelField: React.FC<ILabelFieldProps> = ({
  text,
  label,
  children,
  fontSize = 'medium',
  fontWeight = 'normal',
  containerStyle,
}) => {

  return (
    <div
      className='label-wrapper'
      style={containerStyle}
    >
      <h4 className={`label-title ${fontSize} ${fontWeight}`}>
        {label}
      </h4>

      <div>
        {text ? (
          <p className='label-text'>
            {text}
          </p>
        ) : null}

        {children}
      </div>
    </div>
  )
}

export default LabelField