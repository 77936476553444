import React from "react";
import { IWaitWrapperProps } from "./types";
import * as Page from ".";

const WaitWrapper: React.FC<IWaitWrapperProps> = ({
    data,
    loader,
    children,
    no,
    withoutNoLength,
}) => {
    return (
        <>
            {loader ? 
                <Page.Preloader relative />
                : data ? 
                children : withoutNoLength ? children : <Page.NoLength no={no} />}
        </>
    )
}

export default WaitWrapper; 