import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";

import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Buttons from "../../components/Buttons";
import * as Components from "./components";

import { useHttp } from "../../hooks/http.hook";
import { config, Translater } from "../../config";

import { IStock } from "../../types/items";
import {
  TMutliLangTitles,
  TPossibleMultiLangTitle,
} from "../../types/multiLang";

import { useHistory } from "react-router-dom";
import { useValidation } from "../../hooks";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { TabLanguage } from "../../components/TabLanguage";
import { getStringFromTranslationsArr } from "../../helpers";

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { multiLang } = useContext(Contexts.ConfigContext);

  const { loading, request } = useHttp();
  const history = useHistory();

  const [isValid, toggleValid] = useState({
    name: false,
    photo: false,
    percent: 0,
    description: false,
    restaurantsList: true,
  });

  const [isClicked, setClick] = useState(false);

  const [form, setForm] = useState<IStock>({
    name: [{ value: "", lang: "" }],
    sitePhoto: "",
    appPhoto: "",
    percent: 0,
    description: [{ value: "", lang: "" }],
    status: false,
    dateStart: 0,
    dateEnd: 0,
    timeEnd: 0,
    productsList: "",
    products: [],
    category: "",
    restaurant: "",
    infinite: false,
  });
  const [categoriesList, setCategoriesList] = useState<string[]>([]);
  const [restaurantsList, setRestaurantsList] = useState<string[]>([]);
  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  });
  const [valueLang, setValueLang] = useState<string>(language.slug);

  useEffect(() => {
    console.log("form", form);
  }, [form]);

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const schema = useMemo<any>(
    () => ({
      name: {
        condition:
          getStringFromTranslationsArr(form.name, language.slug).length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.name[language.slug]}`,
      },
      description: {
        condition:
          getStringFromTranslationsArr(form.description, language.slug)
            .length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.description[language.slug]}`,
      },
      percent: {
        condition: +form.percent > 0 && +form.percent <= 100,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.percent[language.slug]}`,
      },
      restaurant: {
        condition: form.restaurant.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.restaurant[language.slug]}`,
      },
      category: {
        condition: form.category.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.category[language.slug]}`,
      },
      products: {
        condition: form.productsList?.length >= 1,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.products[language.slug]}`,
      },
      dateStart: {
        condition: form.dateStart.toString().length >= 4,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.dataStart[language.slug]}`,
      },
      dateEnd: {
        condition: form.dateEnd.toString().length >= 4,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.dataEnd[language.slug]}`,
      },
      photo: {
        condition: !!form.appPhoto && !!form.sitePhoto,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.photo[language.slug]}`,
      },
    }),
    [form, Translater, language]
  );

  const { errors, validationSchema, validation } = useValidation(schema);

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "name") {
        const isTitleInArr = form.name.find((item) => item.lang == valueLang)
          ? true
          : false;

        if (isTitleInArr) {
          const title = form.name
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            name: title as { value: string; lang: string }[],
          });

          return;
        }

        const title = form.name
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          name: title as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.name === "description") {
        const isTitleInArr = form.description.find(
          (item) => item.lang == valueLang
        )
          ? true
          : false;

        if (isTitleInArr) {
          const description = form.description
            .map((item) => {
              if (item.lang == valueLang) {
                return { value: e.target.value, lang: valueLang };
              }
              return item;
            })
            .filter((item) => item.value);

          setForm({
            ...form,
            description: description as { value: string; lang: string }[],
          });

          return;
        }

        const description = form.description
          .concat({
            value: e.target.value,
            lang: valueLang,
          })
          .filter((item) => item.value);

        setForm({
          ...form,
          description: description as { value: string; lang: string }[],
        });

        return;
      }

      if (e.target.type === "file" && e.target.name == "sitePhoto") {
        //@ts-ignore
        setForm({ ...form, sitePhoto: e.target.files[0] });
        return;
      }
      if (e.target.type === "file" && e.target.name == "appPhoto") {
        //@ts-ignore
        setForm({ ...form, appPhoto: e.target.files[0] });
        return;
      } else setForm({ ...form, [e.target.name]: e.target.value });
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      if (e.target.name == "category") {
        setForm({ ...form, category: e.target.value });
      }
      if (e.target.name == "restaurant") {
        setForm({ ...form, restaurant: e.target.value });
      }
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    createHandler: async () => {
      try {
        setClick(true);
        await validation();

        Callbacks.Create();
      } catch (e) {
        toogleIsAlertOpen(true);
      }
    },
    setProducts: (products: string[]) => {
      setForm({ ...form, products });
    },
  };

  const Callbacks = {
    Create: async () => {
      try {
        const data = new FormData();
        data.append("sitePhoto", form.sitePhoto);
        data.append("appPhoto", form.appPhoto);
        data.append("name", JSON.stringify(form.name));
        data.append("description", JSON.stringify(form.description));
        data.append("percent", (+form.percent / 100).toFixed(2) as string);
        data.append("dateStart", form.dateStart as string);
        data.append("dateEnd", form.dateEnd as string);
        data.append("category", form.category as string);
        data.append("restaurant", form.restaurant as string);
        for (let item of form.productsList) {
          data.append("productsList[]", item);
        }

        const response = await request(`${config.API}/stocks`, "POST", data, {
          Authorization: `Bearer ${token as string}`,
        });

        if (response) {
          history.goBack();
          setForm({
            ...form,
            name: [{ value: "", lang: "" }],
            description: [{ value: "", lang: "" }],
            appPhoto: "",
            sitePhoto: "",
            percent: 0,
            status: false,
            dateStart: 0,
            dateEnd: 0,
            timeEnd: 0,
            productsList: "",
            category: "",
            restaurant: "",
          });
        }
      } catch (e) {}
    },
  };

  useEffect(() => {
    if (form.sitePhoto && form.appPhoto)
      toggleValid({ ...isValid, photo: true });
  }, [form.sitePhoto, form.appPhoto]);

  return (
    <Page.Wrapper title={Translater.StocksCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.StocksCreatePage.title[language.slug]}
        backButtonLink="/stocks"
      />

      <Buttons.Container
        disabled={loading}
        createHandler={Events.createHandler}
      />

      <TabLanguage setValueLang={setValueLang} valueLang={valueLang} />

      <Components.ConfigBlock
        form={form}
        from={"create"}
        //@ts-ignore
        isValid={validationSchema}
        multiLangValues={multiLangTitles}
        inputHandler={Events.inputHandler}
        selectHandler={Events.selectHandler}
        setForm={setForm}
        setProducts={Events.setProducts}
        isClicked={isClicked}
        valueLang={valueLang}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default CreatePage;
