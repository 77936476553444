import styled from "styled-components";

export const FilterWrap = styled.div`
  width: 100%;
  display: flex;
  height: 68px;
  align-items: center;
  gap: 24px;
  padding: 12px;
`;
