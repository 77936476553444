import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
`;
export const VariationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
