import React from "react"

import * as Table from "../../../../components/Table"
import * as Components from ".."

import { ITableWrapperProps } from "./types"

import { STitles } from "./static"

const TableWrapper: React.FC<ITableWrapperProps> = ({
  items,
  reloadHandler,
}) => {
  return (
    <Table.Table type="banners" titles={STitles}>
      {items
        .filter((item) => !item.percent)
        .map((item, index) => (
          <Components.Track
            key={item._id}
            order={index + 1}
            data={item}
            reloadHandler={reloadHandler}
          />
        ))}
    </Table.Table>
  )
}

export default TableWrapper
