import React from "react";

import searchIcon from "./../../assets/icons/search.svg";

import "./searchInput.scss";
import { TSearchInput } from "./types";

export const SearchInput = ({ name, placeholder, onChange }: TSearchInput) => {
  return (
    <div className="s-input-control">
      <div className="inputSearch-wrapper">
        <img alt="search" src={searchIcon} className="search-image" />
        <input
          onChange={onChange}
          className="my-input"
          name={name}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
