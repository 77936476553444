export const generateFormData = async (
  data: Object,
  imageProps?: Array<string>
): Promise<FormData> => {
  const formData = new FormData();

  const processValue = async (prop: string, value: any) => {
    if (typeof value === "string" && value.startsWith("http")) {
      const response = await fetch(value);
      const blob = await response.blob();
      const file = new File([blob], "image.jpg", { type: blob.type });
      formData.append(prop, file, file.name);
    } else {
      formData.append(prop, value, value.name);
    }
  };

  for (const [prop, value] of Object.entries(data)) {
    if (imageProps?.includes(prop)) {
      if (Array.isArray(value)) {
        for (const img of value) {
          await processValue(prop, img);
        }
      } else {
        await processValue(prop, value);
      }
      continue;
    }

    if (typeof value === "object") {
      formData.append(prop, JSON.stringify(value));
      continue;
    }
    formData.append(prop, value);
  }

  return formData;
};