import React, { useContext, useState, useEffect } from "react";

import * as Contexts from "../../contexts";

import { NavLink } from "react-router-dom";
import { Translater } from "../../config";
import { INavLink } from "../Page/types";
import { MenuIcon } from "./styled";

const CustomNavLink: React.FC<INavLink> = ({ slug, linkTo, clickHandler }) => {
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const [localAccess, setLocalAccess] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [image, setImage] = useState<any>(
    require("../../assets/icons/home.svg")
  );

  useEffect(() => {
    switch (slug) {
      case "home":
        if (access?.settings && access?.settings.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/home.svg"));
          setTitle(Translater.Sidebar.home[language.slug]);
        }
        break;
      case "orders":
        if (access?.orders && access?.orders.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/order.svg"));
          setTitle(Translater.Sidebar.orders[language.slug]);
        }
        break;
      case "categories":
        if (access?.categories && access?.categories?.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/categories.svg"));
          setTitle(Translater.Sidebar.categories[language.slug]);
        }
        break;
      case "restaurants":
        if (access?.restaurants && access?.restaurants?.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/restaurant.svg"));
          setTitle(Translater.Sidebar.restaurants[language.slug]);
        }
        break;
      case "products":
        if (access?.products && access?.products?.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/products.svg"));
          setTitle(Translater.Sidebar.products[language.slug]);
        }
        break;
      case "options":
        if (access?.products && access?.products?.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/options.svg"));
          setTitle(Translater.Sidebar.options[language.slug]);
        }
        break;
      case "news":
        if (access?.settings && access?.settings.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/news.svg"));
          setTitle(Translater.Sidebar.news[language.slug]);
        }
        break;
      case "stocks":
        if (access?.stocks && access?.stocks?.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/sale.svg"));
          setTitle(Translater.Sidebar.stocks[language.slug]);
        }
        break;
      case "banners":
        if (access?.banner && access?.banner.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/banners.svg"));
          setTitle(Translater.Sidebar.Banners[language.slug]);
        }
        break;
      case "seo":
        if (access?.seo && access?.seo.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/seo_icon.svg"));
          setTitle(Translater.Sidebar.seo[language.slug]);
        }
        break;
      case "users":
        if (access?.users && access?.users?.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/consumer.svg"));
          setTitle(Translater.Sidebar.users[language.slug]);
        }
        break;
      case "usersBirthdays":
        if (access?.users && access?.users?.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/birthday.svg"));
          setTitle(Translater.Sidebar.birthdays[language.slug]);
        }
        break;
      case "staff":
        if (access?.staff && access?.staff.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/staff.svg"));
          setTitle(Translater.Sidebar.staff[language.slug]);
        }
        break;
      case "roles":
        if (access?.roles && access?.roles.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/role.svg"));
          setTitle(Translater.Sidebar.roles[language.slug]);
        }
        break;
      case "history":
        if (access?.ordersHistory && access?.ordersHistory.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/archive.svg"));
          setTitle(Translater.Sidebar.history[language.slug]);
        }
        break;
      case "notifications":
        setLocalAccess(true);
        setImage(require("../../assets/icons/notifications.svg"));
        setTitle(Translater.Sidebar.notifications[language.slug]);
        break;
      case "booking":
        if (access?.booking && access?.booking.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/bookingList.svg"));
          setTitle(Translater.Sidebar.booking[language.slug]);
        }
        break;
      case "bookingDetails":
        if (access?.booking && access?.booking.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/booking.svg"));
          setTitle(Translater.Sidebar.bookingDetails[language.slug]);
        }
        break;
      case "referals":
        setLocalAccess(true);
        setImage(require("../../assets/icons/referal.svg"));
        setTitle(Translater.Sidebar.referals[language.slug]);
        break;
      case "delivery":
        if (access?.delivery && access?.delivery.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/delivery.svg"));
          setTitle(Translater.Sidebar.delivery[language.slug]);
        }
        break;
      case "settings":
        if (access?.settings && access?.settings.view) {
          setLocalAccess(true);
          setImage(require("../../assets/icons/settings.svg"));
          setTitle(Translater.Sidebar.settings[language.slug]);
        }
        break;
      default:
        setLocalAccess(false);
    }
  }, [language]);

  if (!localAccess) return null;

  if (title == Translater.Sidebar.Banners[language.slug])
    return (
      <NavLink className="sidebar-link" to={linkTo} onClick={clickHandler}>
        <MenuIcon alt={slug} src={image} />
        {title}
      </NavLink>
    );
  if (title == Translater.Sidebar.seo[language.slug])
    return (
      <NavLink className="sidebar-link" to={linkTo} onClick={clickHandler}>
        <MenuIcon alt={slug} src={image} />
        {title}
      </NavLink>
    );

  return (
    <NavLink className="sidebar-link" to={linkTo} onClick={clickHandler}>
      <img alt={slug} src={image} />
      {title}
    </NavLink>
  );
};

export default CustomNavLink;
