import styled from "styled-components";

export const ChangeLangStyled = styled.div`
  border-radius: 14px 14px 0 0;
  overflow: hidden;
  display: flex;
  width: 250px;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
`;
export const ActiveIndicator = styled.div<{ $error?: boolean }>`
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: ${({ $error }) => ($error ? "red" : "#03cb83")};
  transition: left 0.3s ease-out, width 0.3s ease-out;
`;

export const ButtonLang = styled.div<{ $active?: boolean; $error?: boolean }>`
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 45px;
  color: ${({ $active, $error }) => {
    if ($active) {
      return "#03CB83";
    } else if ($error) {
      return "red";
    } else return "#9E9E9E";
  }};
  border-bottom: 1px solid #9e9e9e;
`;
